import React, { useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Col, Alert } from "reactstrap";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { Link } from 'react-router-dom';
import icnNotaire from '../img/icn-notaire.svg';
import { ButtonPrimarySmall, ButtonPrimary } from "../style/Button";
import ContentChoisirNotaireDone from "./ContentChoisirNotaireDone";
import { CheckIcon, MapPinIcon, InformationCircleIcon } from "@heroicons/react/24/solid";



function CardNotaireApp(args) {
    const [canvas, setCanvas] = useState(false);
    const [notaireChoisiDone, setNotaireChoisiDone] = useState(false);
    const toggleChoisir = () => setCanvas(!canvas);
    const [successModal, setSuccessModal] = useState(false); // État pour afficher la modal de succès
    //console.log("args notaires", args)

    const ChoisirNotaire = (type, idNotaire, idAffaire) => {
        console.log(`type ${type}, Notaire sélectionné : ${idNotaire}, Affaire : ${idAffaire}`);

        const UpdateStatut = async () => {
            let tableName = ""; // Initialisation du nom de la table

            // Sélectionner la bonne table selon la variable `type`
            switch (type) {
                case "immo":
                    tableName = "transaction";
                    break;
                case "pma":
                    tableName = "pma";
                    break;
                case "mariage":
                    tableName = "mariage";
                    break;
                case "divorce":
                    tableName = "divorce";
                    break;
                default:
                    console.error("Type inconnu:", args.type);
                    return; // Arrêter l'exécution si le type n'est pas valide
            }

            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/${tableName}/${idAffaire}`;
            //console.log("🔗 URL API :", URL);

            const body = {
                "fields": {
                    "statut_marketplace_notaire": "vérification avant attribution",
                    "notaire": Array.isArray(idNotaire) ? [idNotaire[0]] : [idNotaire],
                }
            };
            //console.log("📦 Body envoyé :", JSON.stringify(body, null, 2));

            try {
                const response = await fetch(URL, {
                    method: "PATCH",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(body)
                });

                const responseData = await response.json();
                console.log("✅ Réponse API :", responseData);

                if (response.status === 200) {
                    setNotaireChoisiDone(true);
                    setSuccessModal(true);
                } else {
                    console.error("❌ Erreur HTTP:", response.status, responseData);
                }

            } catch (error) {
                console.error("❌ Erreur fetch:", error);
            }
        };

        UpdateStatut();

        // Ici, tu peux ajouter une requête API, un setState, ou une redirection
    };

    return (
        <div>
            <div className="lg:col-start-3 lg:row-end-1">
                <div className="rounded-lg bg-gray-50 ring-1 shadow-xs ring-gray-900/5">
                    <dl className="flex flex-wrap items-center">
                        <div className="flex-none self-end px-6 pt-4">
                            {args.reco.photo_profil ? <img className="rounded-full size-24" src={args.reco.photo_profil[0].url} alt={args.reco.prenom[0] + " " + args.reco.nom[0]} /> : <img src={icnNotaire} alt="notaire" />}
                        </div>
                        <div className="flex-auto pt-6 pl-6">
                            <dt className="text-xl font-semibold text-gray-900">Maitre {args.reco.prenom[0]} {args.reco.nom[0]}</dt>
                            <span className="bg-gradient-to-r from-teal-200 to-teal-500 inline-flex items-center rounded-md px-2 py-1 text-sm font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
                                Notaire recommandé
                            </span>
                        </div>

                        <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                            <dt className="flex-none">
                                <span className="sr-only">Lieu</span>
                                <MapPinIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                            </dt>
                            <dd className="text-sm font-medium text-gray-900">
                                {args.reco.adresse[0]} {" "} {args.reco.code_postal[0]} {" "} {args.reco.ville[0]}
                            </dd>
                        </div>

                        <div className="flex flex-wrap gap-2 w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                            {args.reco.domaine_competences.map((col, i) => (
                                <>
                                    <span className="inline-flex items-center rounded-md bg-slate-100 p-2 text-sm font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
                                        {args.reco.domaine_competences[i]}
                                    </span>
                                </>
                            ))}
                        </div>
                        {args.reco.commentaire && <>
                            <div className="flex flex-wrap gap-2 w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                <div className="rounded-md bg-blue-50 p-2">
                                    <div className="flex">
                                        <div className="shrink-0">
                                            <InformationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
                                        </div>
                                        <div className="ml-3 flex-1 md:flex md:justify-between">
                                            <p className="text-sm text-blue-700 m-0">
                                                {args.reco.commentaire[0]}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}

                    </dl>
                    <div className="mt-6 border-t border-gray-900/5 px-6 py-6 mx-auto text-center flex flex-row items-center justify-between">
                            <button onClick={() => ChoisirNotaire(args.type, args.idNotaire, args.idAffaire)} className="btn-main text-center w-full mx-auto mb-2">Choisir ce notaire</button>
                        

                        <a href={`${args.reco.lien_fiche[0]}`} target="blank" className="no-underline text-sm/6 font-semibold text-gray-900">
                            Plus d'informations <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </div>
            </div>


            <Offcanvas
                isOpen={canvas}
                toggle={toggleChoisir}
                {...args}
                direction="end"
                scrollable
            >
                {notaireChoisiDone === false ?
                    <>
                        <OffcanvasHeader toggle={toggleChoisir} className="m-0">
                            Vous êtes sur le point de choisir ce notaire
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <Col md='12' align="center" className="mt-2">
                                <Alert color="light">
                                    Ce notaire va recevoir votre dossier et gérer votre dossier.
                                </Alert>
                                <ButtonPrimary color="primary" onClick={ChoisirNotaire} >Choisir ce notaire</ButtonPrimary>
                                <p><br />
                                    <small>En choisissant de confier votre affaire à ce notaire,<br /> vous acceptez <a href="https://clotere.fr/info/cgu" target="blank">les conditions générales d’utilisation</a> de Clotere. </small>
                                </p>
                            </Col>

                        </OffcanvasBody>
                    </>
                    :
                    <>
                        <OffcanvasHeader className="m-0">
                            <span role="img">🥳</span> Félicitations vous avez choisi votre notaire !
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <Col md='12' align="center" className="mt-2">
                                <ContentChoisirNotaireDone />
                            </Col>
                            <Col md='12' align="center" className="mt-2">
                                <Link to="/app/dashboard"><ButtonPrimarySmall color="primary">Ok, j'ai compris</ButtonPrimarySmall></Link>
                            </Col>

                        </OffcanvasBody>
                    </>
                }
            </Offcanvas>

            {/* Modal de succès */}
            {successModal && (
                <Dialog open={successModal} onClose={setSuccessModal} className="relative z-10">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500/75 transition-opacity data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in"
                    />

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all data-closed:translate-y-4 data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-closed:sm:translate-y-0 data-closed:sm:scale-95"
                            >
                                <div>
                                    <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon aria-hidden="true" className="size-6 text-green-600" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                                            Votre notaire a bien été choisi
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Votre notaire va prendre contact avec vous et fixer vos rendez-vous de signature.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <Link to='/app/notaire'
                                        type="button"
                                        className="no-underline inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Ok, j'ai compris
                                    </Link>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>

            )}
        </div>


    );
}

export default CardNotaireApp;