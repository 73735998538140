import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/solid";


function FaqPma() {
  return (
    <section class=" mt-4 flex flex-row flex-wrap justify-center items-center bg-slate-100">
      <div className="py-14 sm:py-20 ">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto text-center">
            <h2 className="text-2xl font-bold tracking-tight leading-10 text-gray-900 sm:text-2xl">
              <span className="font-bold text-indigo-800">Consentement à la Procréation médicalement assistée (PMA) & Reconnaissance conjointe d'enfant (PMA)</span>
              : 5 informations importantes</h2>

          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0 sm:max-w-[60%] mx-auto">
            <dl className="space-y-10">

              <div className="bg-white my-4 rounded-lg p-4">
                <dt className="text-lg font-semibold leading-7 ">
                  Les deux actes notariés sont-ils obligatoires ?
                </dt>
                <dd className="mt-2 text-md leading-7 text-gray-600">
                  Dans certains cas seul un acte notarié est obligatoire
                  <br />
                  <ul>
                    <li>• <span className="text-indigo-800 font-bold">La conclusion d’un acte authentique de consentement à la Procréation Médicalement Assistée (PMA) est obligatoire lorsque la procréation est réalisée avec l’intervention d’un tiers donneur.</span> </li>
                    <li>• <span className="text-indigo-800 font-bold">La conclusion d’un acte authentique de reconnaissance conjointe est nécessaire pour les couples de femmes ayant recours à la Procréation Médicalement Assistée (PMA) </span>: pour permettre à la femme qui n’a pas accouché de faire valoir son lien de filiation avec l’enfant issu d’une PMA. Cette reconnaissance doit être effectuée avant la réalisation de la PMA.</li>
                  </ul>
                </dd>
              </div>

              <div className="bg-white my-4 rounded-lg p-4">
                <dt className="text-lg font-semibold leading-7 ">
                  Combien ça coûte ?
                </dt>
                <dd className="mt-2 text-md leading-7 text-gray-600">
                  Les frais liés à la réception des actes de consentement à la PMA et de reconnaissance conjointe anticipée, sont réglementés et ne peuvent être modifiés par votre notaire. <br />Ils incluent un émolument (rémunération du notaire) fixe, des frais de formalités et d'accompagnement.
                  <br />
                  <ul>
                    <li>• <span className="text-indigo-800 font-bold">Acte authentique de consentement à la Procréation Médicalement Assistée (PMA) : 189€ TTC</span> </li>
                    <li>• <span className="text-indigo-800 font-bold">Acte authentique de reconnaissance conjointe anticipée : 189€ TTC</span> </li>
                  </ul>
                </dd>
              </div>

              <div className="bg-white my-4 rounded-lg p-4">
                <dt className="text-lg font-semibold leading-7 ">
                  Quels sont les documents nécessaires chez le notaire pour une PMA ?
                </dt>
                <dd className="mt-2 text-md leading-7 text-gray-600">
                  Après avoir remplis vos informations, Clotere vous fournit la liste exacte des documents à fournir au notaire pour la réalisation de vos actes notariés.
                  <br />
                  <ul>
                    <li>• <span className="text-indigo-800 font-bold">Pièces d'identité des futurs parents</span></li>
                    <li>• <span className="text-indigo-800 font-bold">Acte de naissance de moins de 3 mois</span></li>
                    <li>• <span className="text-indigo-800 font-bold">Le cas échéant, Acte de mariage de moins de 3 mois </span></li>
                    <li>• <span className="text-indigo-800 font-bold">Justificatif de domicile de moins de 2 mois </span></li>
                  </ul>
                </dd>
              </div>


              <div className="bg-white my-4 rounded-lg p-4">
                <dt className="text-lg font-semibold leading-7 ">
                  Quelles sont les points d'attention pendant la démarche ?
                </dt>
                <dd className="mt-2 text-md leading-7 text-gray-600">
                  Le processus de PMA est récent en France, Clotere.fr vous accompagne dans cette démarche et vous permet d'obtenir vos documents conformes et nécessaires pour la filiation de votre enfant.
                  <br />
                  <ul>
                    <li>• <span className="text-indigo-800 font-bold">Deux actes distincts</span> : La reconnaissance conjointe anticipée et le consentement à la PMA doivent être rédigés sur des documents séparés. Ils ne doivent pas figurer sur le même papier.</li>
                    <li>• <span className="text-indigo-800 font-bold">Aucun détail superflu</span> : Les documents ne doivent pas mentionner des informations comme la mère porteuse, le pays de la PMA ou la méthode utilisée. En effet, si ces détails sont inscrits et que l’un de ces paramètres change, votre RCA pourrait devenir invalide.</li>
                    <li>• <span className="text-indigo-800 font-bold">Numéro CRPCEN </span> :  Le numéro CRPCEN de l’étude notariale figure bien sur les documents.</li>
                  </ul>
                </dd>
              </div>

              <div className="bg-white my-4 rounded-lg p-4">
                <dt className="text-lg font-semibold leading-7 ">
                  Doit-on être pacsé ou marié pour une PMA ?
                </dt>
                <dd className="mt-2 text-md leading-7 text-gray-600">
                  Contrairement à certaines idées reçues, <span className="text-indigo-800 font-bold">il n’est pas nécessaire d’être mariées ou pacsées pour établir ces documents</span>. La RCA et le consentement à la PMA sont accessibles à tous les couples, quelle que soit leur situation matrimoniale.
                </dd>
              </div>



              {/**<div className="bg-white my-4 rounded-lg p-4">
                                    <dt className="text-lg font-semibold leading-7 ">
                                        Quels sont les avantages juridiques d'une RCA ?
                                    </dt>
                                    <dd className="mt-2 text-md leading-7 text-gray-600">
                                        La reconnaissance conjointe d’enfant chez un notaire garantit une <span className="text-indigo-800 font-bold">filiation claire et incontestable</span>, assurant aux deux parents une reconnaissance immédiate et une protection juridique contre d’éventuelles contestations.
                                        <br /><br />
                                        Elle permet également <span className="text-indigo-800 font-bold">d’établir des droits parentaux égaux</span>, notamment en matière d’autorité parentale et de succession, ce qui est particulièrement important pour les couples non mariés.
                                        <br /><br />
                                        En cas de séparation, cet acte protège les droits de chacun et sert de base pour organiser la garde et la résidence de l’enfant, prévenant ainsi d’éventuels conflits.
                                        <br /><br />
                                        Cette reconnaissance est essentielle pour les enfants nés hors mariage, évitant tout risque de non-reconnaissance par un parent et facilitant les démarches administratives, comme l’accès aux droits sociaux, les voyages ou les changements d’état civil. 🚀
                                    </dd>
                                </div>**/}
            </dl>
          </div>


        </div>
        <div className="mx-auto text-center container">
          <p className="text-lg"> Débutez aujourd'hui votre dossier chez le notaire</p>
          <Link to="/onboard/pma"
            type="button"
            className="btn-indigo p-6  no-underline inline-flex items-center w-auto"
          >
            Débuter mon dossier
            <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
          </Link>
        </div>

      </div>

    </section>
  );
}

export default FaqPma;