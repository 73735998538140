const BookingButton = () => {
    const googleCalendarLink = "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2N3sMiWCWTD3GWAp9oN-FoJEdzm42kuKNeR0ZTFdXc1V4UG5DSezuPQXlFiGHvzrwOZKPcJbjL";
  
    return (
      <a href={googleCalendarLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
        <button className="btn-main"
        >
          📅 Prendre rendez-vous avec votre conseiller
        </button>
      </a>
    );
  };
  
  export default BookingButton;