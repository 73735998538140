import React from "react";
import { DocumentTextIcon } from "@heroicons/react/24/solid";


function DescriptionDocument(doc) {
    console.log("name doc", doc.doc)

    const getDocumentDetails = (doc) => {
        // Vérifier que doc est bien une chaîne de caractères
  if (typeof doc.doc !== "string") {
    return {
      why: "Document inconnu.",
      acceptedDocs: "Non spécifié.",
      attention: "Non spécifié.",
      format: "PDF",
    };
  }

  const lowerCaseDoc = doc.doc.toLowerCase(); // Convertir en minuscule en toute sécurité

        if (lowerCaseDoc.includes("justificatif de domicile")) {
          return {
            why: "Preuve de résidence pour établir les actes à l'adresse de la personne.",
            acceptedDocs: "Facture d'énergie, Facture de téléphone/internet, quittance de loyer, avis d’imposition.",
            attention: "Il faut que le justificatif ait moins de 3 mois.",
            format: "PDF",
          };
        } else if (lowerCaseDoc.includes("acte de naissance")) {
          return {
            why: "Document officiel prouvant la naissance et l'identité.",
            acceptedDocs: "Copie intégrale ou extrait avec filiation de l'acte de naissance.",
            attention: "Doit être récent (moins de 3 mois) et en version originale.",
            where: "Doit être récent (moins de 3 mois) et en version originale.",
            format: "PDF, JPEG",
          };
        } else if (lowerCaseDoc.includes("papier d'identité")) {
          return {
            why: "Identification officielle requise pour valider l'identité.",
            acceptedDocs: "Carte d'identité, passeport, titre de séjour en cours de validité.",
            attention: "Le document doit être en couleur et en bonne qualité.",
            format: "PDF, JPEG",
          };
        } else {
          return {
            why: "Information non disponible pour ce document.",
            acceptedDocs: "Non spécifié.",
            attention: "Non spécifié.",
            format: "PDF",
          };
        }
      };

      const details = getDocumentDetails(doc);

    return (
        <div className="mt-10 relative">
            <div className="bg-violet-50 p-4 rounded-lg  border-gray-300">
                <p className="text-lg font-bold">🤔 C'est quoi ce document ?</p>
            <dl className="space-y-8  sm:space-y-6 ">
                <DocumentTextIcon className="text-indigo-300 size-8 absolute right-2 top-6"/>
                <div>
                    <dd className="mt-1 text-base text-gray-900 sm:col-span-2">{details.why}</dd>
                </div>
                <div>
                    <dt className="text-sm font-medium text-gray-500  sm:shrink-0">Quel(s) document(s) accepté(s) ? </dt>
                    <dd className="mt-1 text-base text-gray-900 sm:col-span-2">{details.acceptedDocs}</dd>
                </div>
                <div>
                    <dt className="text-sm font-medium text-gray-500  sm:shrink-0">Point d'attention </dt>
                    <dd className="mt-1 text-base text-gray-900 sm:col-span-2">{details.attention}</dd>
                </div>
                <div>
                    <dt className="text-sm font-medium text-gray-500  sm:shrink-0">Format accepté</dt>
                    <dd className="mt-1 text-base text-gray-900 sm:col-span-2">{details.format} </dd>
                </div>
                <div>
                    <dt className="text-sm font-medium text-gray-500  sm:shrink-0">Où trouver ce document ? </dt>
                    <dd className="mt-1 text-base text-gray-900 sm:col-span-2">{details.format} </dd>
                </div>
            </dl>
            </div>
        </div>
    );
}

export default DescriptionDocument;