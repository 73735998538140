import React from "react";

const featuredTestimonial = {
    body: 'Votre outil simplifie vraiment les échanges et évite beaucoup de temps perdu. L\'expérience et l\'accompagnement sur Whatsapp font vraiment la différence. Je revendrais chez vous! ',
    author: {
        name: 'Paul',
        handle: 'A acheté sa résidence principale avec Clotere',
    },
}
const testimonials = [
    [
        [
            {
                body: 'L\'équipe est vraiment top, le service client que j\'ai reçu de leur part a parfaitement répondu à mes attentes. Merci',
                author: {
                    name: 'Aurore',
                    handle: 'A réalisé son contrat de mariage sur Clotere',
                },
            },
            // More testimonials...
        ],
        [
            {
                body: 'Je ne savais pas par où commencer, grâce à votre contenu j\'ai trouvé toutes les informations dont j\'avais besoin, ça m\'a rassurée.',
                author: {
                    name: 'Sarah',
                    handle: 'A réalisé ses démarches PMA sur Clotere',
                },
            },
            // More testimonials...
        ],

    ],
]
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Testimonials() {
    return (
        <>
        {/** testimonials */}
        <div className="relative isolate  pb-8 pt-8">
        <div
            aria-hidden="true"
            className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
        >
            <div
                style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
                className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
            />
        </div>
        <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        >
            <div
                style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
                className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
            />
        </div>

        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
                <p className="text-black !text-[50px] lg:leading-[30px]  font-bold mt-10 sm:mt-0 mb-8">
                ❤️ Nos clients nous adorent 
                </p>
            </div>
            <div className="mx-auto mt-12 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm/6 text-gray-900 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
                <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
                    <blockquote className="p-6 text-lg font-semibold tracking-tight text-gray-900 sm:p-12 sm:text-xl/8">
                        <p className="text-xl">{`“${featuredTestimonial.body}”`}</p>
                    </blockquote>
                    <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
                        
                        <div className="flex-auto">
                            <div className="font-semibold">{featuredTestimonial.author.name}</div>
                            <div className="text-gray-600">{featuredTestimonial.author.handle}</div>
                        </div>
                    </figcaption>
                </figure>
                {testimonials.map((columnGroup, columnGroupIdx) => (
                    <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                        {columnGroup.map((column, columnIdx) => (
                            <div
                                key={columnIdx}
                                className={classNames(
                                    (columnGroupIdx === 0 && columnIdx === 0) ||
                                        (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                                        ? 'xl:row-span-2'
                                        : 'xl:row-start-1',
                                    'space-y-8',
                                )}
                            >
                                {column.map((testimonial) => (
                                    <figure
                                        key={testimonial.author.handle}
                                        className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                                    >
                                        <blockquote className="text-gray-900">
                                            <p>{`“${testimonial.body}”`}</p>
                                        </blockquote>
                                        <figcaption className="mt-6 flex items-center gap-x-4">
                                            <div>
                                                <div className="font-semibold">{testimonial.author.name}</div>
                                                <div className="text-gray-600">{testimonial.author.handle}</div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    </div>
        </>

    );
}

export default Testimonials;


