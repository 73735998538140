import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/solid";


function FaqMariage() {
  return (
    <section class=" mt-4 flex flex-row flex-wrap justify-center items-center bg-slate-100">
                <div className="py-14 sm:py-20 ">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto text-center">
                            <h2 className="text-3xl font-bold tracking-tight leading-10 text-gray-900 sm:text-4xl">
                                <span className="font-bold text-indigo-800">Contrat de mariage chez le notaire</span>
                                : 5 informations importantes</h2>

                        </div>
                        <div className="mt-10 lg:col-span-7 lg:mt-0 sm:max-w-[60%] mx-auto">
                            <dl className="space-y-10">


                                <div className="bg-white my-4 rounded-lg p-4">
                                    <dt className="text-lg font-semibold leading-7 ">
                                        Quel régime matrimonial est le plus adapté à notre situation ?
                                    </dt>
                                    <dd className="mt-2 text-md leading-7 text-gray-600">
                                        Il existe plusieurs régimes matrimoniaux en France :
                                        <ul>
                                            <li><strong>• Communauté réduite aux acquêts (par défaut sans contrat)</strong> : chacun conserve les biens acquis avant le mariage, mais les biens achetés après sont communs.</li>
                                            <li><strong>• Séparation de biens</strong> : chaque époux reste propriétaire de ses biens, ce qui protège chacun en cas d’activité professionnelle à risque.</li>
                                            <li><strong>• Participation aux acquêts</strong> : un mélange des deux précédents, chaque époux gère ses biens mais partage les bénéfices à la dissolution du mariage.</li>
                                            <li><strong>• Communauté universelle</strong> : tous les biens (avant et après mariage) sont mis en commun.</li>
                                        </ul>
                                        Le choix dépend de votre situation financière, professionnelle et patrimoniale.

                                    </dd>
                                </div>

                                <div className="bg-white my-4 rounded-lg p-4">
                                    <dt className="text-lg font-semibold leading-7 ">
                                        Comment protéger le conjoint en cas de décès ?
                                    </dt>
                                    <dd className="mt-2 text-md leading-7 text-gray-600">
                                        Un contrat de mariage peut inclure<span className="text-indigo-800 font-bold"> une clause de préciput (qui permet à l’un des époux de récupérer certains biens avant le partage de la succession)</span>.
                                        <br />Une donation entre époux peut aussi être envisagée pour élargir les droits du conjoint survivant.
                                        <br />Si vous avez des enfants d’un premier mariage, il faut anticiper le partage des biens.
                                    </dd>
                                </div>

                                <div className="bg-white my-4 rounded-lg p-4">
                                    <dt className="text-lg font-semibold leading-7 ">
                                        Comment gérer nos biens en cas de séparation ou de divorce ?
                                    </dt>
                                    <dd className="mt-2 text-md leading-7 text-gray-600">
                                        Le choix du régime a un impact sur le partage des biens en cas de séparation.<br /><br />
                                        <ul>
                                            <li>• Avec une séparation de biens, <span className="text-indigo-800 font-bold">chacun repart avec ce qu’il possède</span>.</li>
                                            <li>• En communauté réduite aux acquêts, <span className="text-indigo-800 font-bold">les biens acquis pendant le mariage sont partagés à parts égales</span>.</li>
                                        </ul>
                                        <br />Il est possible d’ajouter des clauses spécifiques pour protéger un époux (exemple : une compensation financière en cas de divorce).
                                    </dd>
                                </div>
                                <div className="bg-white my-4 rounded-lg p-4">
                                    <dt className="text-lg font-semibold leading-7 ">
                                        Quel impact le régime matrimonial a-t-il sur mon activité professionnelle ?
                                    </dt>
                                    <dd className="mt-2 text-md leading-7 text-gray-600">
                                        Si l’un des époux est entrepreneur, un régime de séparation de biens peut éviter que les dettes professionnelles n’affectent le patrimoine du couple.
                                        <ul>
                                            <li>• Un contrat peut prévoir <span className="text-indigo-800 font-bold">des protections pour le conjoint</span>, notamment en cas de faillite ou de difficultés financières.</li>
                                            <li>•	Il est aussi possible<span className="text-indigo-800 font-bold"> d’aménager des clauses pour mieux répartir les revenus générés par une entreprise familiale</span>.</li>
                                        </ul>

                                    </dd>

                                </div>
                                <div className="bg-white my-4 rounded-lg p-4">
                                    <dt className="text-lg font-semibold leading-7 ">
                                        Peut-on modifier le contrat de mariage plus tard ?
                                    </dt>
                                    <dd className="mt-2 text-md leading-7 text-gray-600">
                                        Oui ! Il est possible de changer de régime matrimonial après 2 ans de mariage, sous certaines conditions et avec l’accord des deux époux.
                                        <br />Cela nécessite de repasser devant un notaire et, parfois, de passer devant un juge si des enfants ou des créanciers sont concernés.
                                    </dd>

                                </div>

                            </dl>
                        </div>


                    </div>
                    <div className="mx-auto text-center container">
                        <p className="text-lg"> Débutez aujourd'hui votre dossier chez le notaire</p>
                        <Link to="/onboard/mariage"
                            type="button"
                            className="btn-indigo p-6  no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 text-sm font-semibold text-white shadow-sm w-auto"
                        >
                            Débuter mon dossier
                            <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                        </Link>
                    </div>

                </div>

            </section>
  );
}

export default FaqMariage;