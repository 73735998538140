import React from "react";
import styled from "styled-components";
import {InformationCircleIcon } from "@heroicons/react/20/solid";


export const AideContent = styled.div`

svg.iconflex{
    margin-right:2rem;
    margin-left:2rem;
    color:${props => props.theme.colors.main};
}
.alert{
    background-color: #1af46c2b !important;
    border: 0;
}
li::marker {
    color: ${props => props.theme.colors.main};
}
`;



function AideRca() {
    return (
        <AideContent>
            <p>
                <div className="">
                La reconnaissance conjointe anticipée est une démarche juridique permettant à des parents non mariés d’établir la filiation de leur futur enfant avant sa naissance.
                </div>

                <p className="text-base font-bold leading-7 text-gray-900 mt-3">3 choses à retenir :</p>
                <div className="rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900">Ce document sécurise <span className="text-indigo-800">la flliation</span>.</dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">Contrairement aux couples mariés où la filiation est automatique, cette reconnaissance garantit que le père (ou le second parent dans un couple de femmes) sera mentionné sur l’acte de naissance sans démarches supplémentaires.</p>
                        </div>
                    </div>
                </div>
                <div className="mt-3 rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900"> Un engagement clair et protecteur.</dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">Elle évite toute contestation future et assure les droits et devoirs des deux parents envers l’enfant, notamment en cas de séparation.</p>
                        </div>
                    </div>
                </div>
                <div className="mt-3 rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900"> Ce document est nécessaire pour l'état civil à la mairie.</dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">C'est ce document qu'il faudra présenter à la mairie le jour de la reconnaissance de l'enfant à l'état civil.</p>
                        </div>
                    </div>
                </div>

                

                <div className="rounded-md bg-emerald-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            👋
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-emerald-700 mb-0">Il vous manque une information ? N'hésitez pas à contacter votre conseiller Clotere.</p>

                        </div>
                    </div>
                </div>




            </p>
        </AideContent>
    );
}

export default AideRca;