import React from "react";

import { Link } from "react-router-dom";
//component 
import Listing from "./Listing";
import NavVente from "./NavVente";
import FooterVente from "./FooterVente";
//img et icn
import { CheckBadgeIcon, MagnifyingGlassIcon, CheckIcon } from "@heroicons/react/20/solid";
import { ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon } from '@heroicons/react/20/solid'
import BgSmilePourquoi from "../img/smile-pourquoi-clotere.png"


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const features = [
    {
        name: 'Créer votre annonce gratuitement',
        description:
            'Décrivez simplement votre bien, renseignez toutes les caractéristiques qui intéresse les acheteurs et publier jusqu\'à 10 photos par annonce.',
        href: '#',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'Echangez par messagerie sécurisée',
        description:
            'Les acheteurs vous contactent directement sur notre messagerie, aucune de vos données personnelles ne sera communiqué ni téléphone ni mail.',
        href: '#',
        icon: LockClosedIcon,
    },
    {
        name: 'Accélérez votre passage chez le notaire',
        description:
            'Gagnez du temps sur votre passage chez le notaire avec notre offre gratuite pour constituez un dossier de vente complet et conforme pour le notaire.',
        href: '#',
        icon: ArrowPathIcon,
    },
]

const featuredTestimonial = {
    body: 'Integer id nunc sit semper purus. Bibendum at lacus ut arcu blandit montes vitae auctor libero. Hac condimentum dignissim nibh vulputate ut nunc. Amet nibh orci mi venenatis blandit vel et proin. Non hendrerit in vel ac diam.',
    author: {
        name: 'Brenna Goyette',
        handle: 'brennagoyette',
        imageUrl:
            'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=1024&h=1024&q=80',
        logoUrl: 'https://tailwindui.com/plus/img/logos/savvycal-logo-gray-900.svg',
    },
}
const testimonials = [
    [
        [
            {
                body: 'Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.',
                author: {
                    name: 'Leslie Alexander',
                    handle: 'lesliealexander',
                    imageUrl:
                        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
                },
            },
            // More testimonials...
        ],
        [
            {
                body: 'Aut reprehenderit voluptatem eum asperiores beatae id. Iure molestiae ipsam ut officia rem nulla blanditiis.',
                author: {
                    name: 'Lindsay Walton',
                    handle: 'lindsaywalton',
                    imageUrl:
                        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
                },
            },
            // More testimonials...
        ],

    ],
]

const posts = [
    {
        id: 1,
        title: 'Les frais de notaire sont-ils négociables ?',
        href: 'https://clotere.com/les-frais-de-notaire-sont-ils-negociables/',
        description: "Allant jusqu'à 6% du prix d'achat de votre bien, les frais de notaire représente un poste de dépense important. Bien anticipé ce coût est important, on vous explique tout sur ces frais de notaire.",
        imageUrl:
            'https://i0.wp.com/clotere.com/wp-content/uploads/2024/10/frais-notaire-negociable.png?resize=600%2C403&ssl=1',
        category: { title: 'Comprendre les frais de notaire dans l\'immobilier', href: '#' },
    },
    {
        id: 2,
        title: 'Les clauses suspensives, finalement qu\'est-ce que c\'est ?',
        href: 'https://clotere.com/notaire-immobilier/les-clauses-suspensives-qu-est-ce-que-c-est/',
        description: "Si vous achetez un bien immobilier vous entendrez forcément parlé des clauses suspensives 🤔 ! Ne vous inquiétez pas c'est très simple, on vous explique tout dans cet article.",
        imageUrl:
            'https://clotere.com/wp-content/uploads/2024/10/clauses-suspensives.webp',
        category: { title: 'Les clauses suspensives, finalement qu\'est-ce que c\'est ?', href: '#' },
    },
    {
        id: 3,
        title: 'Comment choisir son notaire pour son achat immobilier ?',
        href: 'https://clotere.com/comment-trouver-un-notaire-achat-immobilier/',
        description: "Acquérir un bien immobilier requiert toujours l’intervention d’un notaire, au moins pour réaliser l’acte de vente. Comment faire le choix d’un notaire pour un achat immobilier ? Découvrez nos conseils.",
        imageUrl:
            'https://i0.wp.com/clotere.com/wp-content/uploads/2023/03/comment-choisir-son-notaire.webp?resize=600%2C399&ssl=1',
        category: { title: 'Comment choisir son notaire pour son achat immobilier ?', href: '#' },
    },

]




function HomeVente() {

    return (
        <>
            <div className="bg-white">
                
                <NavVente/>
                <header className="p-4  sm:p-10">
                    <div className="relative rounded-4xl bg-hero-pattern">
                        <div className='relative p-4 sm:px-8'>
                            <div className="mx-auto max-w-2xl lg:max-w-7xl">
                                <div className=" text-start pb-0 pt-14 sm:pb-22 sm:pt-14 md:pb-38 md:pt-22">
                                    <h1 className="font-bold text-white leading-10 sm:leading-normal text-4xl lg:text-5xl mb-2">
                                        L'immobilier entre particuliers, <br /><span className="relative z-10 inline-block px-1 font-semibold  after:absolute after:-bottom-2 after:right-0 after:-z-10 after:h-4 after:w-full after:-translate-y-5 after:bg-[#7e22ce] after:content-['']">sans commission</span>
                                    </h1>
                                    <p className="text-white/90 text-xl max-w-xl mb-16">
                                        La meilleure plateforme d'immobilier pour vendre votre logement entre particuliers sans aucune commission</p>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/** form search */}
                <section className="relative pb-12">
                    <div className="container relative">
                        <div className="grid grid-cols-1 justify-center">
                            <div className="relative -mt-24 w-full sm:max-w-[50%]">
                                <div className="w-auto grid grid-cols-1">
                                    <div className="w-auto p-3 bg-white  rounded-ss-none rounded-se-none md:rounded-se-xl rounded-xl shadow-md dark:shadow-gray-700">
                                        <form>
                                            <div className="w-auto grid  grid-cols-1 lg:gap-0 gap-6">
                                                <div>
                                                    <div className="grid grid-cols-1">

                                                        <input
                                                            id="lieu"
                                                            name="lieu"
                                                            type="text"
                                                            placeholder="Saisir le lieu ou le code postal"
                                                            className="col-start-1 row-start-1 block w-full rounded-md bg-white py-3 pl-20 pr-6 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:pl-14 sm:text-sm/6"
                                                        />
                                                        <MagnifyingGlassIcon
                                                            aria-hidden="true"
                                                            className="text-indigo-600 pointer-events-none col-start-1 row-start-1 ml-3 size-5 self-center  sm:size-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>{/** end grid */}

                    </div>

                </section>


                {/** dernières annonces */}
                <Listing title="Dernières annonces de biens immobiliers à vendre entre particulier" />

                {/** explication site */}
                <section className="relative pb-16 pt-24">
                    <div className="container relative lg:mt-24 mt-16">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                            <div className="md:col-span-6">
                                <div className="relative">
                                    <img className="w-[70%] mx-auto" src={BgSmilePourquoi} />
                                </div>
                            </div>
                            <div className="md:col-span-6">
                                <div className="lg:ms-4">
                                    <h4 className="mb-6 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">
                                        Le meilleur site de vente d'immobilier entre particuliers
                                    </h4>
                                    <p className="text-slate-600">
                                        Clotere se distingue des autres plateformes immobilières grâce à son concept unique : <strong className="text-indigo-700">un site entièrement gratuit et exclusivement réservé aux particuliers</strong>.
                                        <br />
                                        Ici, aucune agence immobilière n’est admise, garantissant un échange direct entre propriétaires et acheteurs.
                                        <br /><br />
                                        Résultat ? <strong className="text-indigo-700">Zéro frais d’agence</strong> et des <strong className="text-indigo-700">transactions de particulier à particulier</strong> pour maximiser les avantages financiers de chacun.

                                    </p>
                                    <div className="flex items-center gap-5">
                                        <div className="flex items-center gap-2">
                                            <svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.49967 1.83337V4.50004M8.49967 12.5V15.1667M3.78634 3.78671L5.67301 5.67337M11.3263 11.3267L13.213 13.2134M1.83301 8.50004H4.49967M12.4997 8.50004H15.1663M3.78634 13.2134L5.67301 11.3267M11.3263 5.67337L13.213 3.78671" stroke="#1E1E1E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <div className="text-black font-['Inter'] leading-[1.375rem]">Simple</div>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.83301 9.83337C5.83301 9.83337 6.83301 11.1667 8.49967 11.1667C10.1663 11.1667 11.1663 9.83337 11.1663 9.83337M6.49967 6.50004H6.50634M10.4997 6.50004H10.5063M15.1663 8.50004C15.1663 12.1819 12.1816 15.1667 8.49967 15.1667C4.81778 15.1667 1.83301 12.1819 1.83301 8.50004C1.83301 4.81814 4.81778 1.83337 8.49967 1.83337C12.1816 1.83337 15.1663 4.81814 15.1663 8.50004Z" stroke="#1E1E1E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <div className="text-black font-['Inter'] leading-[1.375rem]">Clair</div>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.16667 7.83337V5.16671C5.16667 4.28265 5.51786 3.43481 6.14298 2.80968C6.7681 2.18456 7.61595 1.83337 8.5 1.83337C9.38406 1.83337 10.2319 2.18456 10.857 2.80968C11.4821 3.43481 11.8333 4.28265 11.8333 5.16671V7.83337M3.83333 7.83337H13.1667C13.903 7.83337 14.5 8.43033 14.5 9.16671V13.8334C14.5 14.5698 13.903 15.1667 13.1667 15.1667H3.83333C3.09695 15.1667 2.5 14.5698 2.5 13.8334V9.16671C2.5 8.43033 3.09695 7.83337 3.83333 7.83337Z" stroke="#1E1E1E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <div className="text-black font-['Inter'] leading-[1.375rem]">Fiable</div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <a href="#" className="btn-primary no-underline">En savoir plus</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/** testimonials */}
                <div className="relative isolate  pb-16 pt-32">
                    <div
                        aria-hidden="true"
                        className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                            className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
                        />
                    </div>
                    <div
                        aria-hidden="true"
                        className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                            className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
                        />
                    </div>

                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl text-center">
                            <h2 className="text-base/7 font-semibold text-indigo-600">❤️</h2>
                            <p className="mt-2 text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                Ils ont vendu sur Clotere
                            </p>
                        </div>
                        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm/6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
                            <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
                                <blockquote className="p-6 text-lg font-semibold tracking-tight text-gray-900 sm:p-12 sm:text-xl/8">
                                    <p>{`“${featuredTestimonial.body}”`}</p>
                                </blockquote>
                                <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
                                    <img
                                        alt=""
                                        src={featuredTestimonial.author.imageUrl}
                                        className="size-10 flex-none rounded-full bg-gray-50"
                                    />
                                    <div className="flex-auto">
                                        <div className="font-semibold">{featuredTestimonial.author.name}</div>
                                        <div className="text-gray-600">{`@${featuredTestimonial.author.handle}`}</div>
                                    </div>
                                    <img alt="" src={featuredTestimonial.author.logoUrl} className="h-10 w-auto flex-none" />
                                </figcaption>
                            </figure>
                            {testimonials.map((columnGroup, columnGroupIdx) => (
                                <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                                    {columnGroup.map((column, columnIdx) => (
                                        <div
                                            key={columnIdx}
                                            className={classNames(
                                                (columnGroupIdx === 0 && columnIdx === 0) ||
                                                    (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                                                    ? 'xl:row-span-2'
                                                    : 'xl:row-start-1',
                                                'space-y-8',
                                            )}
                                        >
                                            {column.map((testimonial) => (
                                                <figure
                                                    key={testimonial.author.handle}
                                                    className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                                                >
                                                    <blockquote className="text-gray-900">
                                                        <p>{`“${testimonial.body}”`}</p>
                                                    </blockquote>
                                                    <figcaption className="mt-6 flex items-center gap-x-4">
                                                        <img alt="" src={testimonial.author.imageUrl} className="size-10 rounded-full bg-gray-50" />
                                                        <div>
                                                            <div className="font-semibold">{testimonial.author.name}</div>
                                                            <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/** Comment ça marche  ? */}
                <div className="overflow-hidden  py-14">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto grid max-w-1xl grid-cols-1 gap-x-8 gap-y-12 items-center">
                            <div className="lg:pr-8 lg:pt-4">
                                <div className="lg:max-w-lg">

                                    <h2 className="text-base/8 font-semibold text-indigo-600">Conçu par des vendeurs pour des vendeurs</h2>

                                    <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                                        Tout ce dont vous avez besoin pour vendre seul
                                    </p>

                                </div>
                            </div>
                            <div class="grid grid-cols-1 sm:grid-cols-4 gap-4">
                                <div className="flex flex-column justify-start gap-2">
                                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-indigo-200 lg:h-20 lg:w-20 ">
                                        <CheckBadgeIcon aria-hidden="true" className="size-10 text-indigo-600" />
                                    </div>

                                    <h3 className="font-bold text-xl sm:max-w-[70%]">100% gratuit, et ça le restera !</h3>
                                    <p>L'ensemble de nos services sont gratuits et le resteront. Notre ambition est de vous donner la possibilité de vendre votre bien sans frais !</p>
                                </div>
                                <div className="flex flex-column justify-start gap-2">
                                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-indigo-200 lg:h-20 lg:w-20 ">
                                        <CheckBadgeIcon aria-hidden="true" className="size-10 text-indigo-600" />
                                    </div>
                                    <h3 className="font-bold text-xl sm:max-w-[70%]">On ne vendra pas vos données</h3>
                                    <p>A la différence des autres sites, nous ne vendront pas vos données à des agents immobiliers ou agence immobilière.</p>
                                </div>
                                <div className="flex flex-column justify-start gap-2">
                                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-indigo-200 lg:h-20 lg:w-20 ">
                                        <CheckBadgeIcon aria-hidden="true" className="size-10 text-indigo-600" />
                                    </div>
                                    <h3 className="font-bold text-xl sm:max-w-[70%]">Échangez simplement</h3>
                                    <p>Vous recevez vos demandes via messagerie sécurisée, sans transmission de vos données, sauf si vous le choisissez.</p>
                                </div>
                                <div className="flex flex-column justify-start gap-2">
                                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-indigo-200 lg:h-20 lg:w-20 ">
                                        <CheckBadgeIcon aria-hidden="true" className="size-10 text-indigo-600" />
                                    </div>
                                    <h3 className="font-bold text-xl">Accélérez le passage chez le notaire</h3>
                                    <p>Constituez un dossier de vente complet et conforme pour le notaire et gagnez du temps sur la signature de l'acte de vente.</p>
                                </div>
                            </div>
                        </div>
                        <div className="pt-16">
                            <Link to="/publier-annonce" className="btn-primary mx-auto no-underline">
                                Publiez votre annonce gratuitement</Link>
                        </div>
                    </div>

                </div>

                {/** Ressources */}
                <div>
                    <div className="py-20">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto max-w-2xl text-center">
                                <span className="mb-8 inline-flex w-fit items-center rounded-full bg-emerald-50 px-2 py-1 text-sm font-bold text-emerald-700 ring-1 ring-inset ring-emerald-700/10">
                                    Articles & ressources
                                </span>
                                <h2 className="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                    Conseils et astuces en immobilier
                                </h2>
                            </div>
                            <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                                {posts.map((post) => (
                                    <article
                                        key={post.id}
                                        className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
                                    >
                                        <img alt="" src={post.imageUrl} className="absolute inset-0 -z-10 h-full w-full object-cover" />
                                        <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                                        <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                                        <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">

                                            <div className="-ml-4 flex items-center gap-x-4">
                                                <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                                                    <circle r={1} cx={1} cy={1} />
                                                </svg>
                                                <div className="flex gap-x-2.5">
                                                    <img alt="" src="http://localhost:3000/static/media/icn-logo.7a16ec775e6f041426f1a66d292f908b.svg" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                                                    Clotere
                                                </div>
                                            </div>
                                        </div>
                                        <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                                            <a href={post.href} target="blank" className="text-white no-underline">
                                                <span className="absolute inset-0" />
                                                {post.title}
                                            </a>
                                        </h3>
                                    </article>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>


                {/** cta */}
                <div className="relative  sm:py-16">
                    <div aria-hidden="true" className="absolute inset-x-0 top-0 hidden h-1/2  lg:block" />
                    <div className="mx-auto max-w-7xl bg-emerald-600 lg:bg-transparent lg:px-8">
                        <div className="lg:grid lg:grid-cols-12">
                            <div className="relative z-10 lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:bg-transparent lg:py-16">
                                <div aria-hidden="true" className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden" />
                                <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:max-w-none lg:p-0">
                                    <div className="aspect-h-6 aspect-w-10 sm:aspect-h-1 sm:aspect-w-2 lg:aspect-w-1 hidden sm:block">
                                        <img
                                            alt=""
                                            src="https://images.unsplash.com/photo-1560518883-ce09059eeffa?q=80&w=1373&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                            className="rounded-3xl object-cover object-center shadow-2xl"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="relative bg-gradient-to-r from-teal-200 to-teal-500 lg:col-span-10 lg:col-start-3 lg:row-start-1 lg:grid lg:grid-cols-10 lg:items-center lg:rounded-3xl">
                                <div aria-hidden="true" className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block">
                                    <svg
                                        fill="none"
                                        width={404}
                                        height={384}
                                        viewBox="0 0 404 384"
                                        aria-hidden="true"
                                        className="absolute bottom-full left-full -translate-x-2/3 translate-y-1/3 transform xl:bottom-auto xl:top-0 xl:translate-y-0"
                                    >
                                        <defs>
                                            <pattern
                                                x={0}
                                                y={0}
                                                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                                width={20}
                                                height={20}
                                                patternUnits="userSpaceOnUse"
                                            >
                                                <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-indigo-500" />
                                            </pattern>
                                        </defs>
                                        <rect fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" width={404} height={384} />
                                    </svg>
                                    <svg
                                        fill="none"
                                        width={404}
                                        height={304}
                                        viewBox="0 0 404 384"
                                        aria-hidden="true"
                                        className="absolute top-full -translate-x-1/3 -translate-y-1/3 transform xl:-translate-y-1/2"
                                    >
                                        <defs>
                                            <pattern
                                                x={0}
                                                y={0}
                                                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                                width={20}
                                                height={20}
                                                patternUnits="userSpaceOnUse"
                                            >
                                                <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-indigo-500" />
                                            </pattern>
                                        </defs>
                                        <rect fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" width={404} height={384} />
                                    </svg>
                                </div>
                                <div className="relative mx-auto max-w-md space-y- px-6 py-5 sm:max-w-3xl sm:py-16 lg:col-span-6 lg:col-start-4 lg:max-w-none lg:p-0">
                                    <h2 id="join-heading" className="text-3xl font-bold tracking-tight text-white">
                                        Vendez votre maison ou votre appartement de particulier à particulier aujourd'hui
                                    </h2>
                                    <div className="flex flex-row gap-3 flex-wrap">
                                        <Link to="/publier-annonce" className="btn-primary  no-underline">
                                            Ajoutez gratuitement mon annonce
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FooterVente/>

                
            </div>
        </>
    );
}

export default HomeVente;