import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  Offcanvas,
  ListGroup,
  ListGroupItem,
  OffcanvasHeader,
  OffcanvasBody,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  TitlePage,
  TitlePageApp,
  TitleSection,
  Panel,
} from "../style/Layout";
import { PopupButton } from '@typeform/embed-react';
import { CheckIcon } from '@heroicons/react/20/solid'
import TimelineListItem from "../components/TimelineListItem";
import Articles from "../components/Articles";
import FaqVendeur from "../components/FaqVendeur";
import FaqAcheteur from "../components/FaqAcheteur";
import TimelineChronologie from "../components/TimelineChronologie";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import CardHelp from "../components/CardHelp";
import AideCompromis from "../components/AideCompromis";
import AideActe from "../components/AideActe";
import styled from "styled-components";
import backWelcome from "../img/back-alert-welcome.png";
import photoNotaire from "../img/photo-notaire.png";
import CardNotaireDashboard from '../components/CardNotaireDashboard';
import RendezVousNotaire from "../components/RendezVousNotaire";

function Dashboard(args) {
  const [canvas, setCanvas] = useState(false);
  const [canvasCompromis, setCanvasCompromis] = useState(false);
  const [canvasActe, setCanvasActe] = useState(false);

  const toggle3 = () => setCanvas(!canvas);
  const toggleCompromis = () => setCanvasCompromis(!canvasCompromis);
  const toggleActe = () => setCanvasActe(!canvasActe);


  const [pdfName, setPdfName] = useState(null);
  const toggleModal = () => setPdfName(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  //console.log("args dahboasrd", args);


  if (args.info === "newUser") {
    return (
      <>
        <div className="container">
          <Row>
            <TitlePageApp>
              <Col md="12">
                <TitlePage>
                  Bonjour <span role="img">👋</span>{" "}
                </TitlePage>
                <p>
                  Votre transaction est en attente de validation. Nous vous
                  enverrons un email lorsquelle sera validée.{" "}
                </p>
              </Col>
            </TitlePageApp>
            <Col md="12">
              <TitleSection className="mt-5">
                Suivi de votre transaction
              </TitleSection>
              <Panel>
                <ListGroupActionAmener numbered>
                  <ListGroupItem className="done">
                    <div className="d-flex flex-column">
                      <h4>Création de votre transaction immobilière</h4>
                      <p>Aujourd'hui</p>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem className="encours">
                    <div className="d-flex flex-column">
                      <h4>Vérification de votre transaction immobilière</h4>
                      <p>En cours</p>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem className="avenir">
                    <div className="d-flex flex-column">
                      <h4>
                        Ouvertures des accès aux utilisateurs (acheteur(s),
                        vendeur(s), agent immobilier)
                      </h4>
                      <p>A venir</p>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem className="avenir">
                    <div className="d-flex flex-column">
                      <h4>Prise en charge de l'affaire par votre notaire</h4>
                      <p>A venir</p>
                    </div>
                  </ListGroupItem>
                </ListGroupActionAmener>
              </Panel>
            </Col>
          </Row>
        </div>
      </>
    );
  } else if (args !== null) {
    return (
      <>
        <div className="container">
          <Row className="d-flex align-self-start">
            <TitlePageApp>
              <Col md="7">
                <TitlePage>Bonjour {args.user.prenom} 👋</TitlePage>
                <p>
                  Voici l'avancement de votre transaction :{" "}
                  <b>{args.transaction.nom}</b>{" "}
                </p>
              </Col>
              <Col md="5" className="text-right">
                <PopupButton
                  id="JM2MTsDD"
                  size={100}
                  autoClose={false}
                  style={{ border: '0' }}
                >
                  <button className='btn-main'>+ Ajouter une affaire</button>
                </PopupButton>
              </Col>
            </TitlePageApp>

            {/**  Composant de prise de rdv classique avec son notaire */}
            <section>

              {args.rdv && args.rdv.length > 0 && (
                (() => {
                  const rdvNotaire = args.rdv.find(r => r.nom === "Rendez-vous notaire");

                  return rdvNotaire ? (

                    <RendezVousNotaire rdvNotaire={rdvNotaire} id_affaire={args.transaction.id} type="immo" />

                  ) : null;
                })()
              )}
            </section>

            <Col md="12" className="mt-3">
              {args.transaction.statut_marketplace_notaire === "notaire trouvé" &&
                <>
                  <p className='mt-1 text-lg text-gray-500'><span role="img">👋</span> Votre dossier a bien été pris en charge par votre notaire.</p>
                  <CardNotaireDashboard nom={args.transaction.nom_from_notaire} prenom={args.transaction.prenom_from_notaire} email={args.transaction.email_from_notaire} telephone={args.transaction.telephone_from_notaire} adresse={args.transaction.adresse_from_notaire} cp={args.transaction.code_postal_from_notaire} ville={args.transaction.ville_from_notaire} photo={args.transaction.photo_profil_from_notaire} />
                </>
              }
              {args.transaction.statut_marketplace_notaire === "en recherche de notaire" &&
                <>
                  <div color="primary" className="hidden sm:flex mb-4 justify-items-center rounded-2xl  items-center py-2 px-5 bg-gradient-to-r from-teal-200 to-teal-500">
                    <div className="flex-1">
                      <h3>Trouvez le notaire idéal</h3>
                      <ul className="m-0 p-0 mb-3">
                        <li><CheckIcon className="size-6 mr-2 inline-flex" /> Suivez l'avancement depuis votre espace Clotere</li>
                        <li><CheckIcon className="size-6 mr-2 inline-flex" />  Transmettez vos documents de manière sécurisée</li>
                        <li><CheckIcon className="size-6 mr-2 inline-flex" />  Bénéficiez de l'accompagnement de votre conseiller</li>
                      </ul>
                      <Link to="/app/notaire" className="btn-primary no-underline">Trouver mon notaire</Link>
                    </div>

                    <div className="flex-1"><img className="w-auto mx-auto max-w-[300px]" src={photoNotaire} alt="trouver votre notaire" /></div>

                  </div>
                </>
              }


              {/**Informations sur l'attribution de l'affaire au notaire */}
              {args.transaction.statut_marketplace_notaire === "vérification avant attribution" &&
                <>
                  <AlertWelcome>
                    <p><span role="img">👋</span> Nous avons averti(e) votre notaire de votre choix, il va bientôt prendre en charge votre affaire.
                    </p>
                  </AlertWelcome>
                </>
              }
              {args.transaction.statut_marketplace_notaire === "en cours d'attribution" &&
                <>
                  <AlertWelcome>
                    <p><span role="img">👋</span> Nous avons averti(e) votre notaire de votre choix, il va bientôt prendre en charge votre affaire.
                    </p>
                  </AlertWelcome>
                </>
              }

              {/** action à mener**/}
              {args.action !== undefined && args.action.length > 0 ? (
                <>
                  <TitleSection>Vos actions à mener</TitleSection>
                  <Panel>
                    <ListGroupActionAmener numbered>
                      {args.action
                        .slice() // Crée une copie pour éviter de modifier l'original
                        .sort((a, b) => (a.ordre || 0) - (b.ordre || 0)) // Trier par ordre croissant
                        .map((col, i) => (
                          <>
                            {args.action[i].nom === "Ajout document" ? (
                              <>
                                <TimelineListItem
                                  type="document"
                                  statut={args.action[i].statut}
                                />
                              </>
                            ) : (
                              <> </>
                            )}
                            {args.action[i].nom ===
                              "Questionnaire de connaissance" ? (
                              <>
                                <TimelineListItem
                                  user={args.user}
                                  type="questionnaire"
                                  statut={args.action[i].statut}
                                  action={args.action[i].id}
                                />
                              </>
                            ) : (
                              <> </>
                            )}

                            {args.action[i].nom === "Rib" ? (
                              <>
                                <TimelineListItem
                                  type="rib"
                                  statut={args.action[i].statut}
                                />
                              </>
                            ) : (
                              <> </>
                            )}
                          </>
                        ))}
                    </ListGroupActionAmener>
                  </Panel>
                </>
              ) : (
                <></>
              )}



              {/** help section **/}
              <TitleSection className="mt-5">Respirez on vous explique tout 🧘 </TitleSection>
              <Row>
                <Col md='8'>
                  {args.user.role === "vendeur" &&
                    <FaqVendeur />
                  }
                  {args.user.role === "acheteur" &&
                    <FaqAcheteur />
                  }

                </Col>
                <Col md='4'>
                  <TimelineChronologie />
                </Col>
              </Row>

              <TitleSection className="mt-5">Votre notaire en clair 🔥 <br /></TitleSection>
              <Articles />
              <Row><Col md="12" align="center" className="mt-4 mb-4"><a className="btn-main no-underline" color="primary" href="https://clotere.framer.website" target="blank">Tous nos articles</a></Col></Row>



              <CardHelp email={args.user.email} />
            </Col>
          </Row>
        </div>

        {/** Toutes les modals et canvas */}
        <Offcanvas
          isOpen={canvas}
          toggle={toggle3}
          {...args}
          direction="end"
          scrollable
        >
          <OffcanvasHeader toggle={toggle3}>
            Vous n'êtes plus disponible ?
          </OffcanvasHeader>
          <OffcanvasBody>
            <p>
              Pour indiquer les raisons de votre indisponibilités veuillez
              contacter votre conseillé directement.
            </p>
          </OffcanvasBody>
        </Offcanvas>
        <Offcanvas
          isOpen={canvasCompromis}
          toggle={toggleCompromis}
          {...args}
          direction="end"
          scrollable
        >
          <OffcanvasHeader toggle={toggleCompromis}>
            Qu'est-ce que la promesse de vente ?
          </OffcanvasHeader>
          <AideCompromis />
        </Offcanvas>
        <Offcanvas
          isOpen={canvasActe}
          toggle={toggleActe}
          {...args}
          direction="end"
          scrollable
        >
          <OffcanvasHeader toggle={toggleActe}>
            Qu'est-ce que l'acte authentique de vente ?
          </OffcanvasHeader>
          <AideActe />
        </Offcanvas>

        <Modal isOpen={pdfName != null} toggle={toggleModal} size="lg" centered>
          {pdfName != null ? (
            pdfName.type === "application/pdf" ? (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={pdfName.url}
                  plugins={[
                    // Register plugins
                    defaultLayoutPluginInstance,
                  ]}
                />
              </Worker>
            ) : (
              <img alt="document" src={pdfName.url}></img>
            )
          ) : (
            <></>
          )}
        </Modal>
      </>
    );
  }
}

const ListGroupActionAmener = styled(ListGroup)`
  border-radius: 0;
  border: 0;
  .list-group-item {
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 30px;
    &:before {
      content: counters(section, "") "";
      counter-increment: section;
      display: inline;
      border: 1px solid #ddd;
      font-weight: 500;
      border-radius: 100px;
      padding: 10px 17px;
      margin-right: 30px;
    }
    &.done {
      &:before {
        content: "✔️";
      }
    }
    h4 {
      color: #1d2b28;
    }
    color: #84847c;
  }
  @media all and (max-width: 768px) {
    .list-group-item {
      flex-direction: column;
      &:before {
        margin-bottom: 1rem;
      }
    }
  }
`;

const AlertWelcome = styled(Alert)`
  background-image: url(${backWelcome});
  background-size: cover;
  background-repeat: no-repeat;
  color: white !important;
  border: 0 !important;
  border-radius: 16px !important;
  margin-bottom: 3rem;
  padding: 3rem !important;
  p {
    margin-bottom: 0;
    font-size: 18px;
  }
  a {
    color: white !important;
  }
`;





export default Dashboard;
