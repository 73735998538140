import React, { useState, useEffect } from "react";
import { useMemberstack, } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
//img et icn
import { CheckIcon } from '@heroicons/react/20/solid'
import { DocumentCheckIcon, DocumentIcon, UserCircleIcon, MapPinIcon, InformationCircleIcon,StarIcon, CheckBadgeIcon} from "@heroicons/react/24/solid";
import AddressInput from "../components/AdresseInput";
import FaqOnboard from "../components/FaqOnboard";



const timeline = [
    {
        id: 1,
        content: 'Informations sur votre situation',
        target: 'Validée',
        icon: CheckIcon,
        iconBackground: 'bg-slate-700',
    },
    {
        id: 2,
        content: 'Choix de votre notaire',
        target: 'Choisissez votre notaire parmi nos recommandations',
        icon: UserCircleIcon,
        iconBackground: 'bg-indigo-600',
    },
    {
        id: 3,
        content: 'Dépot de vos documents',
        target: 'Ajoutez vos documents dans votre espace sécurisé',
        icon: DocumentIcon,
        iconBackground: 'bg-slate-300',
    },
    {
        id: 4,
        content: 'Vos actes notariés',
        target: 'Votre notaire valide vos informations et rédige vos actes notariés.',
        icon: DocumentCheckIcon,
        iconBackground: 'bg-slate-300',
    }
]

const includedFeatures = [
    'Vos actes notariés sans vous déplacer !',
    'Sécurité et confidentialité des données',
    'Accompagnement juridique jusqu\'à la signature',
    'Pas de frais cachés',
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function FormPma({ onNextStep }) {

    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const [debut, setDebut] = useState(null);
    const [situation, setSituation] = useState(null);
    const [prenom, setPrenom] = useState(null);
    const [nom, setNom] = useState(null);
    const [telephone, setTelephone] = useState(null);
    const [email, setEmail] = useState(null);
    const [adresse, setAdresse] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);

    //step
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [step4, setStep4] = useState(false);
    const [stepEnd, setStepEnd] = useState(false);
    const [stepPaiement, setStepPaiement] = useState(false);
    const [sendData, setSendData] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        //console.log("here")
        setSendData(true);
        sendToZapier();
        setTimeout(() => navigate("/connexion"), 1000); // Reset status after 2 seconds

    };


    //set member if logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
    }, []);


    const handleSubmitCoordonnees = (event) => {
        event.preventDefault();
        setPrenom(event.target.prenom.value);
        setNom(event.target.nom.value);
        setEmail(event.target.email.value);
        setTelephone(event.target.telephone.value);
        setStep3(false);
        setStep4(true);
    }

    const handleSubmitAdresse = (event) => {
        event.preventDefault();
        setAdresse(selectedAddress);
        setStep4(false);
        setStepEnd(true);
    }

    const sendToZapier = async () => {
        const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/2kniiw2/"; // 👈 Specify your Zapier Webhook URL here

        try {
            const response = await fetch(zapierURL, {
                method: "POST",
                mode: "cors",
                body: JSON.stringify({ debut, situation, prenom, nom, telephone, email, selectedAddress })
            });
            const res = await response.json();
            console.log(res);
        } catch (e) {
            console.log(e);
        }

    };



    console.log(situation)

    return (
        <>
            <div className={`sm:mx-auto w-full  flex flex-col sm:items-center ${stepPaiement === true || stepEnd === true  ? "" : "lg:max-w-xl"}`} >

                {/** q1 */}
                {step1 === true &&
                    <div className="w-full">
                        <div>
                            <p className="text-sm font-medium text-slate-500 mb-1">Question <span className="text-indigo-800">1</span>/6</p>
                            <h2 className="text-2xl/9 font-bold tracking-tight text-gray-900">Êtes-vous déjà en processus de PMA (stimulation, FIV, etc.) ?</h2>
                        </div>

                        <div className="mt-10 w-full">
                            <ul className="grid w-full gap-1 md:grid-cols-1 p-0">
                                <li>
                                    <input type="radio" id="oui" name="start" className="hidden peer" required onClick={() => { setDebut("oui"); setStep1(false); setStep2(true); }} />
                                    <label htmlFor="oui" className="radio-button">
                                        Oui
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="non" name="start" className="hidden peer" required onClick={() => { setDebut("non"); setStep1(false); setStep2(true); }} />
                                    <label htmlFor="non" className="radio-button">
                                        Non
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {/** situations */}
                {step2 === true &&
                    <div className="w-full">
                        <div>
                            <p className="text-sm font-medium text-slate-500 mb-1">Question <span className="text-indigo-800">2</span>/6</p>
                            <h2 className=" text-2xl/9 font-bold tracking-tight text-gray-900">Quelle est votre situation ?</h2>
                        </div>

                        <div className="mt-10">
                            <ul className="grid w-full gap-1 md:grid-cols-1 p-0">
                                <li>
                                    <input type="radio" id="seule" name="situation" className="hidden peer" required onClick={() => { setSituation("seule"); setStep2(false); setStep3(true); }} />
                                    <label htmlFor="seule" className="radio-button">
                                        Femme seule
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="couplehomo" name="situation" className="hidden peer" required onClick={() => { setSituation("couplehomo"); setStep2(false); setStep3(true); }} />
                                    <label htmlFor="couplehomo" className="radio-button">
                                        Couple homosexuel
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="couplehetero" name="situation" className="hidden peer" required onClick={() => { setSituation("couplehetero"); setStep2(false); setStep3(true); }} />
                                    <label htmlFor="couplehetero" className="radio-button">
                                        Couple hétérosexuel
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="autre" name="situation" className="hidden peer" required onClick={() => { setSituation("autre"); setStep2(false); setStep3(true); }} />
                                    <label htmlFor="autre" className="radio-button">
                                        Autre
                                    </label>
                                </li>
                            </ul>

                        </div>
                    </div>
                }

                {/** coordonnées */}
                {step3 === true &&
                    <div className="max-w-xl">
                        <div>
                            <p className="text-sm font-medium text-slate-500 mb-1">Question <span className="text-indigo-800">3</span>/6</p>
                            <h2 className=" text-2xl/9 font-bold tracking-tight text-gray-900">Quelles sont vos coordonnées</h2>
                            <p className="text-sm text-slate-600">Pas d'inquiétude on ne les vendra pas ! C'est uniquement pour vous joindre facilement en cas de besoin.</p>
                        </div>
                        <form action="#" method="POST" className="space-y-6 w-full" data-ms-form="signup" onSubmit={handleSubmitCoordonnees}>
                            <div className="grid sm:grid-cols-2 gap-3">
                                <div>
                                    <label className="block text-base font-medium text-gray-900"> Votre nom<sup>*</sup></label>
                                    <input
                                        required
                                        name="nom"
                                        type="text"
                                        className="input-clotere"
                                    />
                                </div>
                                <div>
                                    <label className="block text-base font-medium text-gray-900">Votre Prénom<sup>*</sup> </label>
                                    <input
                                        required
                                        name="prenom"
                                        type="text"
                                        className="input-clotere"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-base font-medium text-gray-900">
                                    Votre Email<sup>*</sup>
                                </label>
                                <div className="mt-2">
                                    <input
                                        required
                                        name="email"
                                        type="email"
                                        className="input-clotere"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-base font-medium text-gray-900">
                                    Votre Téléphone<sup>*</sup>
                                </label>
                                <div className="mt-2">
                                    <input
                                        required
                                        name="telephone"
                                        type="text"
                                        className="input-clotere"
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="btn-indigo  p-4"
                                >
                                    Continuer
                                </button>
                            </div>
                        </form>


                    </div>
                }

                {/** adresse */}
                {step4 === true &&
                    <div className="w-full">
                        <div>
                            <p className="text-sm font-medium text-slate-500 mb-1">Question <span className="text-indigo-800">4</span>/6</p>
                            <h2 className=" text-2xl/9 font-bold tracking-tight text-gray-900">Quelle est votre adresse ?</h2>
                        </div>

                        <div className="mt-10 w-full">
                            <form action="#" method="POST" className="space-y-6 w-full" onSubmit={handleSubmitAdresse}>
                                <div>
                                    <label className="block text-base font-medium text-gray-900">
                                        Adresse<sup>*</sup>
                                    </label>
                                    <AddressInput className="input-clotere" onAddressSelect={(place) => setSelectedAddress(place)} />
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="btn-indigo  no-underline  p-4"
                                    >
                                        Continuer
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                }

                {/** etape intermédiaire */}
                {stepEnd === true &&
                <>
                            <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                                <CheckIcon aria-hidden="true" className="size-6 text-green-600" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h2 as="h3" className=" text-2xl/9 font-bold tracking-tight text-gray-900">
                                    Deux notaires sont disponibles pour votre affaire.
                                </h2>
                                {/**
                                <div className="flow-root relative mb-3">
                                    <ul role="list" className=" mt-10">
                                        {timeline.map((event, eventIdx) => (
                                            <li key={event.id}>
                                                <div className="relative pb-3">
                                                    {eventIdx !== timeline.length - 1 ? (
                                                        <span aria-hidden="true" className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" />
                                                    ) : null}
                                                    <div className="relative flex space-x-3 items-center">
                                                        <div>
                                                            <span
                                                                className={classNames(
                                                                    event.iconBackground,
                                                                    'flex size-8 items-center justify-center rounded-full ring-8 ring-white',
                                                                )}
                                                            >
                                                                <event.icon aria-hidden="true" className="size-5 text-white" />
                                                            </span>
                                                        </div>
                                                        <div className="flex min-w-0 flex-1 justify-start space-x-4 pt-1.5 text-left">
                                                            <div>
                                                                <p className="text-sm text-gray-500 m-0">
                                                                    {event.content}<br />{' '}
                                                                    <span className="font-medium text-black">{event.target}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>

                                </div> */}
                                <div className="flow-root relative mb-3">
                                    <div className="flex flex-col sm:flex-row gap-12 ">
                                        {/** notaire 1 */}
                                        <div className="rounded-lg bg-gray-50 ring-1 shadow-xs ring-gray-900/5">
                                            <dl className="flex flex-wrap items-center">
                                                <div className="flex-none self-end px-6 pt-4">
                                                    <img className="rounded-full size-24" src={"https://v5.airtableusercontent.com/v3/u/39/39/1742680800000/YKGRDJKXVznxEVXiSWYw5A/U4NyDn5JEqKkE6bZvGbLfRkvE1vnTYHOFMVqRNs0G8DkiF7UTZdHO1EcqwG0yDiC49TyH64druJ1kMJnelOeudjDFywQmggcGhvg7qGy51xlPP8APM1z3-QxeqKspdFpkxLSk6LnOCTZzmIl7wKkj11nkXEe_MZSEsnufTjIeVkO314qHD1nUArYTGtZQY9l/PdXcPXOS39fVt5otrUvlG6jsBKmMM24ft6uRaQg6yrA"} alt="notaire" />
                                                </div>
                                                <div className="flex-auto pt-6 pl-6 text-left">
                                                <a href={"https://clotere.fr/notaire/b%C3%A9reng%C3%A8re-bois-samier"} target="blank" className="no-underline"> <dt className="text-xl font-semibold text-gray-900">Maitre Bérengère Bois-Samier</dt></a>
                                                    <span className="bg-gradient-to-r from-teal-200 to-teal-500 inline-flex items-center rounded-md px-2 py-1 text-sm font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
                                                        Notaire recommandé
                                                    </span>
                                                </div>

                                                <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                                    <dt className="flex-none">
                                                        <span className="sr-only">Lieu</span>
                                                        <MapPinIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                                                    </dt>
                                                    <dd className="text-sm font-medium text-gray-900">
                                                    20 rue Joseph Serlin 69001 LYON
                                                    </dd>
                                                </div>

                                                <div className="flex flex-wrap gap-2 w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                                    <span className="inline-flex items-center rounded-md bg-slate-100 p-2 text-sm font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
                                                                Droit de la famille
                                                    </span>
                                                    <span className="inline-flex items-center rounded-md bg-slate-100 p-2 text-sm font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
                                                                Droit Immobilier
                                                    </span>
                                                </div>
                                                    <div className="flex flex-wrap gap-2 w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                                        <div className="rounded-md bg-blue-50 p-2">
                                                            <div className="flex">
                                                                <div className="shrink-0">
                                                                    <InformationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
                                                                </div>
                                                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                                                <p className="text-sm text-blue-700 m-0 text-left">
                                                                        Reçoit également en rendez-vous à Paris et Lyon
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                            </dl>
                                        </div>

                                        {/**notaire 2 */}
                                        <div className="rounded-lg bg-gray-50 ring-1 shadow-xs ring-gray-900/5">
                                            <dl className="flex flex-wrap items-center">
                                                <div className="flex-none self-end px-6 pt-4">
                                                    <img className="rounded-full size-24" src={"https://v5.airtableusercontent.com/v3/u/39/39/1742680800000/dQ7m7OCNR1m36oJHT2qkZQ/C1d3R5Io84890BW3RnWbgXCClRGP6IlitWdHBcWw5-YccXNarbbFskktcC4XQHzWXUH3LOSRhwZpz-Iwx63doKsRhVD3cB5PtBdCjGa14EEX7lu1dBCYat5JvijblQ3fFgQSKx9zuJDKnaV_Ro78o5z4J8GGJcn5XrY49QODoh68kSt6KKGnJoh1EUq4-DC0/xj69uTr74VaTxv54tHntXfBOs73VRJHuGhe4-kYbcvE"} alt="notaire" />
                                                </div>
                                                <div className="flex-auto pt-6 pl-6 text-left">
                                                <a href={"https://clotere.fr/notaire/nuno-monteiro"} target="blank" className="no-underline"> <dt className="text-xl font-semibold text-gray-900">Maitre Nuno Monteiro</dt></a>
                                                    <span className="bg-gradient-to-r from-teal-200 to-teal-500 inline-flex items-center rounded-md px-2 py-1 text-sm font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
                                                        Notaire recommandé
                                                    </span>
                                                </div>

                                                <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                                    <dt className="flex-none">
                                                        <span className="sr-only">Lieu</span>
                                                        <MapPinIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                                                    </dt>
                                                    <dd className="text-sm font-medium text-gray-900">
                                                    50 Avenue du bac 94210 LA VARENNE ST HILAIRE
                                                    </dd>
                                                </div>

                                                <div className="flex flex-wrap gap-2 w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                                    <span className="inline-flex items-center rounded-md bg-slate-100 p-2 text-sm font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
                                                                Droit de la famille
                                                    </span>
                                                    <span className="inline-flex items-center rounded-md bg-slate-100 p-2 text-sm font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
                                                                Droit Immobilier
                                                    </span>
                                                </div>
                                                    <div className="flex flex-wrap gap-2 w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                                        <div className="rounded-md bg-blue-50 p-2">
                                                            <div className="flex">
                                                                <div className="shrink-0">
                                                                    <InformationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
                                                                </div>
                                                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                                                    <p className="text-sm text-blue-700 m-0 text-left">
                                                                        Reçoit également en rendez-vous à Paris et Toulouse
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                            </dl>
                                        </div>

                                    </div>
                                </div>
                                <button onClick={handleClick} className="btn-indigo  p-4 no-underline w-auto mx-auto">
                                    Me connecter et choisir mon notaire
                                </button>
                            </div>
                            </>
                }

                {/** etape paiement */}
                {stepPaiement === true &&
                    <>
                        <div className="mx-auto  px-6 lg:px-8">
                            <h2 className="font-bold text-center">Un tarif tout compris, sans surprise</h2>
                            <p className="text-base/7 text-gray-600 text-center">
                                Clotere.fr simplifie la création de votre dossier notarial et vous connecte à un notaire réactif pour un traitement rapide.
                            </p>

                            <div className="mx-auto  rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
                                <div className="p-8 sm:p-10 lg:flex-auto">
                                    <div className="flex-row">

                                        <div className="flex items-center gap-x-4">
                                            <h4 className="flex-none text-sm/6 font-semibold ">Ce qui est inclus</h4>
                                            <div className="h-px flex-auto bg-gray-100" />
                                        </div>
                                    </div>

                                    <ul role="list" className="pl-0 grid grid-cols-1 gap-4 text-sm/6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                                        {includedFeatures.map((feature) => (
                                            <li key={feature} className="flex gap-x-3 font-bold">
                                                <CheckBadgeIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                    <figure className="mx-auto max-w-2xl bg-slate-50 rounded-md p-4 mt-10">
                                        <div className="flex gap-x-1 text-indigo-600">
                                            <StarIcon aria-hidden="true" className="size-5 flex-none" />
                                            <StarIcon aria-hidden="true" className="size-5 flex-none" />
                                            <StarIcon aria-hidden="true" className="size-5 flex-none" />
                                            <StarIcon aria-hidden="true" className="size-5 flex-none" />
                                            <StarIcon aria-hidden="true" className="size-5 flex-none" />
                                        </div>
                                        <blockquote className="text-xl font-semibold tracking-tight text-gray-900 mb-0">
                                            <p className="mb-0">
                                                “Je ne savais pas par où commencer, grâce à votre contenu j'ai trouvé toutes les informations dont j'avais besoin, ça m'a rassurée.”
                                            </p>
                                        </blockquote>
                                        <figcaption className="flex items-center gap-x-6">

                                            <div className="text-sm/6">
                                                <div className="font-regular text-gray-900">Sarah, <span className="font-regular text-gray-600">A réalisé ses démarches PMA sur Clotere</span></div>

                                            </div>
                                        </figcaption>
                                    </figure>
                                </div>

                                {situation === "couplehomo" &&
                                    <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:shrink-0">
                                        <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                                            <div className="mx-auto max-w-xs px-8">
                                                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                                    <span className="text-5xl font-semibold tracking-tight text-gray-900">378€</span>
                                                </p>
                                                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                                    2 actes notariés : Consentement à la PMA + Reconnaissance conjointe anticipée
                                                </p>
                                                <a
                                                    href={"https://buy.stripe.com/00g4ie9g90gJ1eU3cg?prefilled_email=" + email}
                                                    className="no-underline mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Valider mon offre
                                                </a>
                                                <p className="mt-6 text-xs/5 text-gray-600">
                                                    Paiement 100% sécurisé via notre partenaire Stripe
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {situation !== "couplehomo" &&
                                    <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:shrink-0">
                                        <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                                            <div className="mx-auto max-w-xs px-8">
                                                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                                    <span className="text-5xl font-semibold tracking-tight text-gray-900">189€</span>
                                                </p>
                                                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                                    1  acte notarié : Consentement à la PMA
                                                </p>
                                                <a
                                                    href={"https://buy.stripe.com/5kAdSOboh0gJ2iY4gj?prefilled_email=" + email}
                                                    className="no-underline mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Valider mon offre
                                                </a>
                                                <p className="mt-6 text-xs/5 text-gray-600">
                                                    Paiement 100% sécurisé via notre partenaire Stripe
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                            <FaqOnboard type="pma" />
                        </div>
                    </>
                }


            </div>
        </>
    );
}


export default FormPma;