import React from "react";
import styled from "styled-components";
import {InformationCircleIcon } from "@heroicons/react/20/solid";


export const AideContent = styled.div`

svg.iconflex{
    margin-right:2rem;
    margin-left:2rem;
    color:${props => props.theme.colors.main};
}
.alert{
    background-color: #1af46c2b !important;
    border: 0;
}
li::marker {
    color: ${props => props.theme.colors.main};
}
`;



function AideMariage() {
    return (
        <AideContent>
            <p>
                <div className="">
                Lorsqu’un couple envisage de se marier, il est essentiel de déterminer le régime matrimonial qui régira leurs biens. Par défaut, sans contrat spécifique, le régime de la communauté réduite aux acquêts s’applique. Cependant, en fonction de leur situation personnelle et patrimoniale, les futurs époux peuvent opter pour un autre régime en établissant un contrat de mariage devant notaire.
                </div>

                <p className="text-base font-bold leading-7 text-gray-900 mt-3">3 choses à retenir :</p>
                <div className="rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900">Ce document vous permet de choisir <span className="text-indigo-800">votre régime matrimonial</span>.</dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">Lorsqu’un couple envisage de se marier, il est essentiel de déterminer le régime matrimonial qui régira leurs biens. En fonction de leur situation personnelle et patrimoniale, les futurs époux peuvent opter pour un autre régime en établissant un contrat de mariage devant notaire.   <a href="https://clotere.framer.website/notaire-et-mariage/contrat-de-mariage-chez-le-notaire" target="blank" className="text-indigo-800 font-bold">Découvrez les 4 types de régimes matromoniaux</a></p>
                        </div>
                    </div>
                </div>
                <div className="mt-3 rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900"> Un régime par défaut.</dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">👉 Par défaut, sans contrat spécifique, le régime de la communauté réduite aux acquêts s'applique.</p>
                        </div>
                    </div>
                </div>
                <div className="mt-3 rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900"> Le tarif exacte de votre contrat de mariage peut varier</dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">Le prix peut évoluer si les apports des époux sont supérieurs à 30 800€.</p>
                        </div>
                    </div>
                </div>

                

                <div className="rounded-md bg-emerald-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            👋
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-emerald-700 mb-0">Il vous manque une information ? N'hésitez pas à contacter votre conseiller Clotere.</p>

                        </div>
                    </div>
                </div>




            </p>
        </AideContent>
    );
}

export default AideMariage;