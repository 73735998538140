import React, { useState } from "react";
/** composants **/
import { Navbar } from '../Navbar';
import Footer from '../components/Footer';
import { Container } from "../annuaire/Container";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import PourquoiClotereNew from "../annuaire/PourquoiClotereNew";
import PrendreRdv from "./prendreRdv";
import FaqMariage from "../components/FaqMariage";
import AddressInput from "../components/AdresseInput";
//img & icon
import { CheckIcon } from "@heroicons/react/20/solid";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import BackHeaderTop from "../components/BackHeaderTop";
import BackHeaderBottom from "../components/BackHeaderBottom";
import AvatarNotaire from "../img/avatar-notaire.png";


function Mariage(args) {
    const [role, setRole] = useState(null);
        const navigate = useNavigate();
        const [selectedAddress, setSelectedAddress] = useState(null);
    
        const handleLead = (event) => {
            event.preventDefault();
            //console.log("envoyer");
            const email = event.target.email.value;
            const telephone = event.target.telephone.value;
            const type = "mariage";
    
            const sendToZapier = async () => {
                const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/2qnty6g/"; // 👈 Specify your Zapier Webhook URL here
                try {
                    const response = await fetch(zapierURL, {
                        method: "POST",
                        mode: "cors",
                        body: JSON.stringify({ role, email, telephone, type,selectedAddress })
                    });
                    const res = await response.json();
                    console.log(res);
                    if (res.status === "success") {
                        navigate("/success-notaire-dispo-mariage");
                    }
                } catch (e) {
                    console.log(e);
                }
            };
            sendToZapier();
        }
    return (
        <>
            <Helmet>
                <title>Trouver un notaire pour votre contrat de mariage</title>
                <meta name="robots" content="index,follow" />
                <meta name="googlebot" content="index,follow" />
                <meta name='description' content='Clotere.fr vous aide à trouver votre notaire pour votre contrat de mariage.' />
                <meta property="og:url" content="https://clotere.fr/trouver-un-notaire-contrat-de-mariage" />
                <meta property="og:site_name" content="Clotere" />
                <meta property="og:image" content="https://clotere.fr/facebook-share-webp" />
                <meta property="og:type" content="website" />
            </Helmet>

            {/**header 2 */}
            <div className="relative rounded-4xl ">
                <Navbar user={args.user} />
                <BackHeaderTop/>
                <div className="container relative">

                    <header class="flex flex-row flex-wrap justify-center sm:py-10">
                        <div className="w-full sm:basis-1/2 p-3 sm:p-4">
                            <h1 className="font-medium text-transparent sm:!text-[45px] tracking-[0] leading-10 mb-3" style={{ lineHeight: "50px" }}>
                                <span className=" text-black">Sécurisez  <span className="font-bold mt-2 text-indigo-800">votre contrat de mariage</span> avec un notaire. </span>
                            </h1>

                            <div className="flex flex-column justify-start gap-3">
                                <div className="flex items-center gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Simple</b> : quelques informations suffisent pour démarrer votre projet.</p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Sûr</b> : déposez dans votre espace sécurisé les documents nécessaires.</p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Rapide</b> : votre notaire prends en charge votre dossier rapidement.</p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Suivi</b> : un conseiller dédié disponible pour vous accompagner de A à Z.</p>
                                    </div>
                                </div>
                            </div>
                            {/** card notaire dispo */}
                            <figure className="rounded-2xl bg-white ring-1 shadow-lg ring-gray-900/5 mt-4">
                                <figcaption className="flex items-center gap-x-4 gap-y-4 border-t border-gray-900/10 p-3 sm:flex-nowrap w-content">
                                    <img
                                        alt="notaires disponibles"
                                        src={AvatarNotaire}
                                        className="flex"
                                    />
                                    <div className="flex-auto">
                                        <div className="font-semibold"><span className="text-indigo-800">Des notaires disponibles</span> pour prendre en charge votre affaire.</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                        {/** colonne droite */}
                        <div className="w-full sm:w-1/2  p-0 lg:p-5  sm:flex overflow-hidden items-center">
                            <div className="p-6 bg-white/50 text-center w-full rounded-3xl">
                                <form action="#" method="POST" className="space-y-3 text-left w-full" onSubmit={handleLead}>
                                    <div>
                                        <label className="block text-md font-medium text-gray-900">
                                            Êtes-vous déjà marié ?
                                        </label>
                                        <div className="mt-2">
                                            <ul class="grid w-full gap-1 md:grid-cols-2 p-0 items-stretch">
                                                <li>
                                                    <input type="radio" id="oui" name="situation" class="hidden peer" required onClick={() => { setRole("dejaMarie"); }} />
                                                    <label htmlFor="oui" class="radio-button">
                                                        Oui
                                                    </label>
                                                </li>
                                                <li>
                                                    <input type="radio" id="non" name="situation" class="hidden peer" required onClick={() => { setRole("pasMarie"); }} />
                                                    <label htmlFor="non" class="radio-button">
                                                        Non, pas encore
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <input
                                            name="email"
                                            type="email"
                                            required
                                            className="input-clotere"
                                            placeholder="Votre email*"
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <AddressInput autoComplete="off" placeholderText="Votre code postal*" className="input-clotere" onAddressSelect={(place) => setSelectedAddress(place)} />
                                    </div>
                                    <div>
                                        <div className="mt-2">
                                            <input
                                                name="telephone"
                                                type="tel"
                                                className="input-clotere"
                                                placeholder="Votre telephone*"
                                                maxLength="10"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="rounded-md bg-blue-50 p-4">
                                        <div className="flex">
                                            <div className="shrink-0">
                                                👋
                                            </div>
                                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                                <p className="text-sm text-blue-700 p-0 m-0">Vos données restent privées, elles nous servent uniquement à vous mettre en relation avec des notaires adaptés à vos besoins..</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="btn-indigo p-4"
                                        >
                                            Voir les notaires disponibles
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </header>
                </div>
                <BackHeaderBottom/>
            </div>


            {/** section ccm **/}
            <section id='section1' className="mt-20 mb-20">
                <Container>
                    <div class="container mx-auto px-[15px] h-full">
                        <div class="flex flex-col justify-between items-start gap-[30px]">
                            <p class="text-black leading-[28px] text-[20px]  sm:text-[40px] lg:leading-[50px] lg:text-[45px] font-bold ">Votre <span className="text-indigo-800">contrat de mariage</span> avec Clotere, comment ça marche ?</p>

                            <div class="grid lg:grid-cols-4 w-full gap-[10px] ">
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                    <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z" clip-rule="evenodd" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Répondez à quelques questions</h3>
                                        <p>Nous avons besoin de certaines informations pour préparer votre dossier.</p>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                    <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                                            <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Ajoutez vos documents</h3>
                                        <p>Ajoutez dans votre espace sécurisé les documents nécessaires à votre dossier chez le notaire.</p>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                    <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Choisissez votre notaire</h3>
                                        <p>A vous de choisir votre notaire pour gérer. 100% à distance ou à proximité de chez vous c’est vous qui choisissez.</p>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">

                                    <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                            <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
                                        </svg>
                                        <h3 class="text-black font-bold text-[22px] leading-[22px]">Obtenez votre contrat de mariage</h3>
                                        <p>Faites vos rendez-vous en visio ou présentiel, et obtenez directement dans votre espace sécurisé vos documents notariés.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col gap-3 mt-5">
                            <div className="flex -space-x-2 overflow-hidden">
                                <img
                                    alt="trouver mon notaire acheteur"
                                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="notaire pour votre achat immobilier"
                                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="trouver mon notaire"
                                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="trouver un notaire"
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                            </div>
                            <div className="">
                                <p className="text-lg">Votre conseiller dédié Clotere se fera un plaisir de répondre à toutes vos questions, tout au long de votre démarche </p>
                            </div>

                        </div>
                        <Link to="/onboard/mariage"
                            type="button"
                            className="box-content no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Débuter mon dossier
                            <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                        </Link>
                    </div>
                </Container>
            </section>

            {/** section accompagneent */}
            <PourquoiClotereNew />

            {/** section FAQ */}
            <FaqMariage/>

            {/** section rdv */}
            <PrendreRdv />
            <Footer />
        </>
    );
}

export default Mariage;