import React, { useState, useEffect, useRef } from "react";
import { fetchMessages, sendMessage } from "../functions/apiMessage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Messaging = ({ user, affaire }) => {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [receiver, setReceiver] = useState(null);
    const messagesEndRef = useRef(null); // Permet de scroller automatiquement en bas

    useEffect(() => {
        if (!receiver && affaire.email_from_notaire) {
            setReceiver(affaire.email_from_notaire[0]);
        }
    }, [affaire.email_from_notaire, receiver]);

    useEffect(() => {
        if (receiver) {
            loadMessages();
        }
    }, [receiver]);

    const loadMessages = async () => {
        const data = await fetchMessages();
        setMessages(data.filter(
            (msg) =>
                (msg.sender === user.email && msg.receiver === receiver) ||
                (msg.sender === receiver && msg.receiver === user.email)
        ));

        // 🛠 Déplacer le scroll tout en bas après chargement des messages
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    const handleSendMessage = async () => {
        if (!message.trim()) return;

        const success = await sendMessage(user.email, receiver, message, affaire);
        if (success) {
            toast.success("Message envoyé !");
            setMessage("");
            loadMessages();
        } else {
            toast.error("Erreur d'envoi du message");
        }
    };
    console.log("message", affaire)

    if(affaire.statut_marketplace_notaire === "en recherche de notaire"){
        return (
            <>
                {/* Zone de messages (scrollable) */}
            <div className="flex-1 overflow-y-auto p-4">
                <ul className="space-y-12 grid grid-cols-1">
                    {messages.length === 0 && <>
                        <div className="text-center">

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mx-auto size-12 text-gray-400">
                                <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
                                <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
                            </svg>

                            <h2 className="mt-2 text-base font-semibold text-gray-900">Choisissez votre notaire pour pouvoir échangez avec lui</h2>
                            <p className="mt-1 text-sm text-gray-500">
                                Regroupez ici tout vos échanges pour plus de simplicité et de sécurité. Vous recevrez une notification par email à chaque nouveau message.
                            </p>
                        </div>
                    </>}
                </ul>
            </div>
            </>
        )
    }

    return (
        <div className="h-screen flex flex-col bg-white/20">
            {/* Zone de messages (scrollable) */}
            <div className="flex-1 overflow-y-auto p-4">
                <ul className="space-y-12 grid grid-cols-1">
                    {messages.length === 0 && <>
                        <div className="text-center">

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mx-auto size-12 text-gray-400">
                                <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
                                <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
                            </svg>

                            <h2 className="mt-2 text-base font-semibold text-gray-900">Echangez ici avec votre notaire</h2>
                            <p className="mt-1 text-sm text-gray-500">
                                Regroupez ici tout vos échanges pour plus de simplicité et de sécurité. Vous recevrez une notification par email à chaque nouveau message.
                            </p>
                        </div>
                    </>}
                    {messages.map((msg, index) => (
                        <>
                            {/** mes messages à moi */}
                            {msg.sender === user.email &&
                                <>
                                    <div
                                        key={index}
                                        className="p-3 rounded-2xl space-y-2  bg-slate-300 text-right text-black place-self-end rounded-tr-none"
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: msg.content }} />
                                    </div>
                                    <p className="text-xs place-self-end  text-gray-500 mt-1">
                                        {msg.timestamp
                                            ? new Date(String(msg.timestamp)).toLocaleString("fr-FR", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: false,
                                            }).replace(",", "h")
                                            : "Date inconnue"}
                                    </p>
                                </>
                            }
                            {/** messages de l'autre personne*/}
                            {msg.sender !== user.email &&
                                <>
                                    <div className="flex flex-row justify-start gap-2">
                                        <div className="shrink-0">
                                            <img
                                                alt=""
                                                src={affaire.photo_profil_from_notaire[0].thumbnails.small.url}
                                                className="inline-block size-10 rounded-full"
                                            />
                                        </div>
                                        <div>
                                            <div
                                                key={index}
                                                className="p-3 rounded-2xl space-y-2 bg-indigo-500 text-left text-white  place-self-start rounded-tl-none"
                                            >
                                                <div dangerouslySetInnerHTML={{ __html: msg.content }} />
                                            </div>
                                            <p className="text-xs place-self-start text-gray-500 mt-1"> Votre notaire -
                                                {msg.timestamp
                                                    ? new Date(String(msg.timestamp)).toLocaleString("fr-FR", {
                                                        day: "2-digit",
                                                        month: "2-digit",
                                                        year: "numeric",
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        hour12: false,
                                                    }).replace(",", "h")
                                                    : "Date inconnue"}
                                            </p>
                                        </div>
                                    </div>

                                </>
                            }

                        </>
                    ))}
                    {/* Élément invisible pour scroller en bas */}
                    <div ref={messagesEndRef} />
                </ul>
            </div>

            {/* Input sticky en bas */}
            <div className="sticky bottom-0 left-0 w-full bg-white p-3 shadow-md">
                <ReactQuill
                    theme="snow"
                    value={message}
                    onChange={setMessage}
                    className="w-full"
                    placeholder="Écrivez un message..."
                    modules={{
                        toolbar: [
                            ["bold", "italic", "underline"],
                            [{ list: "ordered" }, { list: "bullet" }],
                            ["link"]
                        ]
                    }}
                />
                <button onClick={handleSendMessage} className="bg-blue-600 text-white px-4 py-2 mt-2 rounded-lg w-full">
                    Envoyer
                </button>
            </div>

            <ToastContainer />
        </div>
    );
};

export default Messaging;