import React, { useState, useEffect } from "react";
import { useMemberstack, } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
import { Form, Input, Offcanvas, OffcanvasHeader, OffcanvasBody, } from "reactstrap";
import logoClotere from "./img/icn-logo.svg";
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import Alert from "./components/Alert"




function ConnexionLink(args, props) {
    const memberstack = useMemberstack();
    const [formError, setFormError] = useState(false);
    const [token, setToken] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("");
    const [member, setMember] = useState(null);
    const [emailSent, setEmailSent] = useState(null);
    const navigate = useNavigate();
    const [canvas, setCanvas] = useState(false);

    const toggleEnSavoirPlus = () => setCanvas(!canvas);

    //redirect if already logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
    }, []);

    if (member) {
        navigate("/app/dashboard");
    }

    const onChangeCode = (event) => {
        const value = event.target.value;
        //console.log("code",value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        //console.log("email 1",email)

        // Send magic link email 
        memberstack.sendMemberLoginPasswordlessEmail({
            email
        }).then(() => {
            // Email sent 
            setEmailSent(email);
            setToken(true);
            //console.log("token d'envoi",token)
        }).catch(er => {
            // Handle error 
            console.log("erreur sent email", er);
            setFormError(true);
            if (er.code === "passwordless-email-not-found") {
                setFormErrorMessage("Cet email n'a pas de compte Clotere.");
            } else {
                setFormErrorMessage(er.message);
            }

        });

    }

    const handleSubmit2 = (event, emailSent) => {
        event.preventDefault();
        const to = event.target.token.value;
        const emailre = event.target.emailre.value;
        //console.log("email & code",emailre,to)

        // Sign up member after they click email link 
        memberstack.loginMemberPasswordless({
            passwordlessToken: to,
            email: emailre
        }).then(result => {
            //console.log("result", result);
            setMember(member);
            navigate("/app/dashboard");
            // Use accessToken from result 
        }).catch(error => {
            // Handle invalid or expired token 
            // Handle error 
            //console.log("erreur sent email", error);
            setFormError(true);
            setFormErrorMessage("Une erreur s'est produit veuillez recommencer");
        });
    }

    return (
        <>
            {!member && (
                <div>
                    <div className="flex min-h-[100vh] flex-1  ">
                        <div className="relative hidden sm:flex flex-1 flex-col w-1/3 justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 border-r-2">
                                        <div
                                    aria-hidden="true"
                                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                                >
                                    <div
                                        style={{
                                            clipPath:
                                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                        }}
                                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                                    />
                                </div>

                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-xl mb-8">Simplifiez votre passage chez le notaire</p>

                            <dl className="grid grid-cols-1 gap-x-8 gap-y-8 lg:gap-y-8 pl-4">
                                <div className="relative pl-16 pt-2">
                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                        </div>
                                        Suivez simplement l'avancée de votre dossier.
                                    </dt>
                                </div>
                                <div className="relative pl-16 pt-2">
                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                        </div>
                                        Regroupez tous vos échanges au même endroit.
                                    </dt>
                                </div>
                                <div className="relative pl-16 pt-2">
                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                        </div>
                                        Trouvez un notaire plus rapidement.
                                    </dt>
                                </div>
                                <div className="relative pl-16 pt-2">
                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                        </div>
                                        Fixez vos rendez-vous simplement avec votre notaire.
                                    </dt>
                                </div>
                            </dl>
                        </div>
                        <div className="bg-white flex flex-1 flex-col w-2/3 justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                            <div className="mx-auto w-full max-w-sm lg:w-96">
                                <div>
                                    <Link to="/">
                                        <img alt="Clotere"
                                            src={logoClotere} className="h-10 w-auto"></img>
                                    </Link>
                                </div>

                                <div className="mt-10">
                                    <div>

                                        {token === false ? (
                                            <>
                                                <h2 className="mt-8 text-2xl/9 font-bold tracking-tight text-gray-900">Connectez-vous à votre espace client</h2>
                                                
                                                <Form data-ms-form="passwordless-login" onSubmit={handleSubmit} className="">

                                                    <>

                                                        {formError ? (
                                                            <Alert type="error">
                                                                {formErrorMessage}
                                                            </Alert>
                                                        ) : (<>{" "}</>)}

                                                        <div className="mt-2">
                                                            <Input name="email" type="email" data-ms-member="email" required placeholder="Votre email" />
                                                        </div>
                                                    </>
                                                    <div className="mt-2">
                                                        <button className="btn-indigo w-full justify-center" color="primary" type="submit" data-ms-passwordless-button="Confirm & Signup">Obtenir mon code de connexion</button>
                                                    </div>
                                                    <div className="flex flex-row gap-3 mt-6 justify-start">
                                                        <InformationCircleIcon className="flex size-6 text-slate-300" />
                                                        <div>
                                                            <p className="text-xs">Pour votre sécurité, Clotere n’utilise pas de mot de passe. <br />Vous recevrez un lien de connexion par email (<a onClick={toggleEnSavoirPlus} className="underline">en savoir plus</a>).
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </>
                                        ) : (<>
                                            <h2 className="mt-8 text-2xl/9 font-bold tracking-tight text-gray-900">Code de vérification</h2>
                                            <p className="mt-2 text-sm/6 text-gray-500">
                                                Saisissez le code envoyé à l'adresse <b className="text-indigo-800">{emailSent}</b>

                                            </p>
                                            <Form data-ms-form="passwordless-login" onSubmit={handleSubmit2}>
                                                <div className="mt-8">
                                                    <input name="emailre" value={emailSent} className="hidden" />
                                                    <div data-ms-passwordless="step-2">
                                                        <Input onChange={onChangeCode} name="token" type="text" data-ms-member="token" placeholder="Saisissez votre code de vérification 167589" focus />

                                                    </div>
                                                </div>

                                                <div className="mt-4 mb-4">
                                                    <button className="btn-indigo" color="primary" type="submit" data-ms-passwordless-button="VALIDER">Valider et se connecter</button>
                                                </div>

                                                <Alert className="mt-8" type="info" message="Vous ne trouvez pas votre code de vérification ? Assurez-vous de vérifier votre dossier spam" />
                                            </Form>
                                        </>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <Offcanvas
                        isOpen={canvas}
                        toggle={toggleEnSavoirPlus}
                        {...args}
                        direction="end"
                        scrollable>
                        <OffcanvasHeader toggle={toggleEnSavoirPlus}>
                            Pourquoi n'ai-je pas de mot de passe Clotere ? 🤔
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <p>Clotere est une entreprise où la sécurité et la vie privée de nos utilisateurs est prise extrêmement au sérieux.
                                Nous savons que vous nous faites confiance en nous confiant des données personnelles et sensibles, et nous mettons tout en œuvre pour les protéger</p>

                            <p>
                                <b className="text-indigo-800">L'objectif principal est qu'il soit impossible pour un intrus de se connecter à votre place</b> !
                            </p>
                            <p>
                                Pour nous en assurer, nous avons choisi de ne pas utiliser de mot de passe pour vous authentifier. Voici pourquoi :
                            </p>
                            <p>
                                <b>Les mots de passe sont un des plus gros risque de sécurité sur internet</b>, car vous êtes nombreux à les réutiliser sur plusieurs sites. On vous comprend : difficile de tous les retenir ! Mais il suffit qu'un seul de ces sites ait été hacké pour que votre précieux mot de passe soit connu de pirates.
                            </p>
                            <p className="rounded-md bg-indigo-50 p-4">
                                Nous utilisons donc une solution passwordless, qui vous impose de renseigner un code de sécurité que nous vous envoyons par email à chaque connexion.
                            </p>
                        </OffcanvasBody>

                    </Offcanvas>
                </div>
            )}
        </>
    );
}

export default ConnexionLink;