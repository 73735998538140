import React from "react";
import BackHeaderTop from "./BackHeaderTop";
import BackHeaderBottom from "./BackHeaderBottom";
import { Link } from "react-router-dom";
import { ArrowRightIcon} from "@heroicons/react/24/solid";
import Illu from "../img/illu-home-acheteur.webp";
import IlluMobile from "../img/illu-home-acheteur-mobile.webp";
import GoogleAvis from "../img/avis-google-clotere.png";

function HeaderNew(args) {


    return (
        <>
            {/**header 2 */}
            <div className="relative rounded-4xl ">
                <BackHeaderTop/>
                <div className="relative container">

                    <header class="flex flex-row flex-wrap justify-center sm:py-4 text-center">
                        <div className="w-full p-3 sm:p-4 text-center">
                            <h1 className="font-medium sm:!text-[70px] sm:max-w-[80%] mx-auto tracking-[0] leading-12 mb-3 sm:leading-[70px]">
                                <span className=" text-black"> Profitez des  <span className="font-bold mt-2 text-indigo-800">conseils de votre notaire</span>, on s'occupe du reste</span>.
                            </h1>
                            <p className="mb-0 font-medium  sm:!text-[20px] tracking-[0] sm:leading-8 sm:max-w-[80%] mx-auto">
                            Fini les formulaires, les délais et les mails sans fin. Clotere vous permet de gérer votre dossier notarial en ligne pour profiter pleinement de l'accompagnement des conseils de votre notaire.
                            </p>
                            <div className="mb-4">
                                <Link to="/onboard/empty"
                                    type="button"
                                    className="box-content mt-3 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Trouver mon notaire
                                    <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                                </Link>
                            </div>
                            <div className="flex justify-center">
                                <img src={GoogleAvis} className="w-48" alt='Avis Google Clotere'/>
                            </div>

                            {/**
                            <div className="flex flex-row justify-center gap-3">
                                <div className="flex items-center gap-2 relative flex-[0_0_auto]">
                                    <HandThumbUpIcon className="!relative !w-4 !h-4 text-indigo-800" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Simple</b></p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <LockClosedIcon className="!relative !w-4 !h-4 text-indigo-800" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Fiable</b></p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <StarIcon className="!relative !w-4 !h-4 text-indigo-800" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Rapide</b></p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="w-full  p-0 lg:p-5  sm:flex overflow-hidden  hidden">
                            <img className="w-[80%] mx-auto" src={Illu} alt="Vos démarches chez le notaire pour votre achat immobilier." />
                        </div>
                        <div className="flex sm:hidden w-full  p-0 lg:p-5 ">
                            <img className="w-[100%] mx-auto" src={IlluMobile} alt="Vos démarches chez le notaire pour votre achat immobilier." />
                        </div>
                    </header>
                </div>
                <BackHeaderBottom/>
            </div>
        </>
    );
}

export default HeaderNew;
