import React, { useState} from "react";
/** composants **/
import { Navbar } from '../Navbar';
import Footer from '../components/Footer';
import Faq from '../components/Faq';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import PrendreRdv from "./prendreRdv";
import PourquoiClotereNew from "../annuaire/PourquoiClotereNew2";
import CommentCaMarche from '../components/CommentCaMarche';
import Qsm from '../components/Qsm';
//img & icon
import { ArrowRightIcon} from "@heroicons/react/24/solid";
import { CheckIcon,CheckBadgeIcon } from "@heroicons/react/20/solid";
import AvatarNotaire from "../img/avatar-notaire.png";
import BackHeaderTop from "../components/BackHeaderTop";
import BackHeaderBottom from "../components/BackHeaderBottom";

const posts = [
    {
        id: 1,
        title: 'Quel est le rôle du notaire dans un achat immobilier ?',
        href: 'https://clotere.framer.website/blog/le-r%C3%B4le-du-notaire-dans-la-vente-d-un-bien-immobilier',
        description: "Si vous avez un projet d’achat immobilier, vous devez savoir que le notaire tient un rôle indispensable dans le processus d’achat, que ce soit pour établir des documents administratifs et juridiques, des signatures, mais aussi pour une mission de conseil. Voici ses différentes attributions.",
        imageUrl:
            'https://framerusercontent.com/images/iSpNwoZYeVubhimwPBiD3iJpg8.webp',
        category: { title: 'Rôle du notaire', href: '#' },
    },
    {
        id: 2,
        title: 'Les frais de notaire sont-ils négociables ?',
        href: 'https://clotere.framer.website/blog/les-frais-de-notaire-sont-ils-n%C3%A9gociables?utm_source=clotere',
        description: "Lorsque vous achetez un bien immobilier, les frais de notaire sont souvent une dépense conséquente à prendre en compte.",
        imageUrl:
            'https://framerusercontent.com/images/F3h1yX3O10qgOmdhrFM57kQp3s.webp?scale-down-to=512',
        category: { title: 'Frais de notaire', href: '#' },
    },
    {
        id: 3,
        title: 'Les clauses suspensives qu\'est-ce que c\'est ?',
        href: 'https://clotere.framer.website/blog/les-clauses-suspensives-qu-est-ce-que-c-est?utm_source=clotere',
        description: "Si vous achetez un bien immobilier vous entendrez forcément parler des clauses suspensives 🤔 ! Ne vous inquiétez pas c'est très simple, on vous explique tout dans cet article.",
        imageUrl:
            'https://framerusercontent.com/images/7qUTqENEJz4uogCoIgtjNUWQA.webp',
        category: { title: 'Notaire acheteur', href: '#' },
    },
]

function Acheter(args) {
const navigate = useNavigate();
    const [role, setRole] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleLead = (event) => {
        event.preventDefault();
        //console.log("envoyer");
        const email = event.target.email.value;
        const telephone = event.target.telephone.value;
        const type = "immo";

        const sendToZapier = async () => {
            const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/2qnty6g/"; // 👈 Specify your Zapier Webhook URL here
            try {
              const response = await fetch(zapierURL, {
                method: "POST",
                mode: "cors",
                body: JSON.stringify({role,email,telephone,type})
              });
              const res = await response.json();
              console.log(res);
              if(res.status === "success"){
                if (res.status === "success") {
                    navigate("/success-notaire-dispo-acheteur");
                }
              }
            } catch (e) {
              console.log(e);
            }
          };
          sendToZapier();
    }

    return (
        <>
            <Helmet>
                <title>Trouver un notaire pour votre achat immobilier</title>
                <meta name="robots" content="index,follow" />
                <meta name="googlebot" content="index,follow" />
                <meta name='description' content='Trouvez votre notaire pour vous accompagner dans votre achat immobilier grâce à Clotere, la solution qui simplifie votre passage chez le notaire.' />
                <meta property="og:url" content="https://clotere.fr/notaire-acheteur" />
                <meta property="og:site_name" content="Clotere" />
                <meta property="og:image" content="https://clotere.fr/facebook-share-webp" />
                <meta property="og:type" content="website" />
            </Helmet>

            {/**header 2 */}
            <div className="relative rounded-4xl ">
            <BackHeaderTop/>
                <Navbar user={args.user} />
                <div className="relative container">

                    <header class="flex flex-row flex-wrap justify-center sm:py-4">
                        {/** colonne gauche */}
                        <div className="w-full sm:w-1/2 p-3 sm:p-4 text-left">
                            <h1 className="font-medium sm:!text-[70px] mx-auto tracking-[0] leading-12 mb-3 sm:leading-[60px]">
                                Trouvez <span className="font-bold mt-2 text-indigo-800">votre notaire</span> pour votre achat immobilier.
                            </h1>
                            <div className="flex flex-column justify-start gap-3">
                                <div className="flex items-center gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Prise en charge immédiate</b> de votre dossier</p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">100% gratuit</b> pour l'acheteur et le vendeur</p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Une assistance juridique</b> illimitée</p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Un notaire adapté à vos besoins</b></p>
                                    </div>
                                </div>
                            </div>
                            {/** card notaire dispo */}
                            <figure className="rounded-2xl bg-white ring-1 shadow-lg ring-gray-900/5 mt-4">
                                <figcaption className="flex items-center gap-x-4 gap-y-4 border-t border-gray-900/10 p-3 sm:flex-nowrap w-content">
                                    <img
                                        alt="notaires disponibles"
                                        src={AvatarNotaire}
                                        className="flex"
                                    />
                                    <div className="flex-auto">
                                        <div className="font-semibold"><span className="text-indigo-800">Des notaires disponibles</span> pour prendre en charge votre affaire.</div>
                                    </div>
                                </figcaption>
                                </figure>
                        </div>
                        {/** colonne droite */}
                        <div className="w-full sm:w-1/2  p-0 lg:p-5  sm:flex overflow-hidden items-center">
                            <div className="p-6 bg-white/50 text-center w-full rounded-3xl">
                            {success === false && <>
                                <form action="#" method="POST" className="space-y-3 text-left w-full" onSubmit={handleLead}>
                                <div>
                                                <label className="block text-md font-medium text-gray-900">
                                                    Avez-vous déjà une offre d'achat acceptée ?
                                                </label>
                                                <div className="mt-2">
                                                    <ul class="grid w-full gap-1 md:grid-cols-2 p-0">
                                                        <li>
                                                            <input type="radio" id="offreAccepte" name="offre" class="hidden peer" required onClick={() => { setRole("offreAccepte"); }} />
                                                            <label htmlFor="offreAccepte" class="radio-button">
                                                                Oui 🥳
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="pasOffre" name="offre" class="hidden peer" required onClick={() => { setRole("pasOffre"); }} />
                                                            <label htmlFor="pasOffre" class="radio-button">
                                                                Non, pas encore
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                    </div>
                                    
                                    <div>
                                        <div className="mt-2">
                                            <input
                                                name="email"
                                                type="email"
                                                required
                                                className="input-clotere"
                                                placeholder="Votre email*"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mt-2">
                                            <input
                                                 name="telephone"
                                                 type="tel"
                                                 className="input-clotere"
                                                 placeholder="Votre telephone*"
                                                 maxLength="10"
                                                 required
                                            />
                                        </div>
                                    </div>
                                    <div className="rounded-md bg-blue-50 p-4">
                                        <div className="flex">
                                            <div className="shrink-0">
                                                👋
                                            </div>
                                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                                <p className="text-sm text-blue-700 p-0 m-0">Vos données restent privées, elles nous servent uniquement à vous mettre en relation avec des notaires adaptés à vos besoins..</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="btn-indigo p-4"
                                        >
                                            Choisir mon notaire
                                        </button>
                                    </div>
                                </form>
                            </>}
                            {success === true && <>
                            <div className="!text-center">
                                <CheckBadgeIcon className="size-24 text-indigo-800 mx-auto"/>
                                <p className="text-xl">Nous avons bien reçu votre demande, nous allons vous envoyer par mail les profils de notaires disponibles pour votre affaire.</p>
                                </div>
                            </>}
                                
                            </div>
                        </div>
                    </header>
                </div>
                <BackHeaderBottom/>
            </div>
            <CommentCaMarche />
            <Qsm />

            <div className="container">

                {/** section accompagneent */}
                <PourquoiClotereNew />

                {/** section Blog */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="bg-white sm:py-20 ">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto max-w-2xl text-center">
                                <h2 className="title-clotere">Bien comprendre votre passage chez le notaire pour <span className="font-bold text-indigo-800">votre achat immobilier</span></h2>

                            </div>
                            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
                                {posts.map((post) => (
                                    <article key={post.id} className="flex flex-col items-start justify-between overflow-hidden bg-white shadow sm:rounded-lg p-3">
                                        <div className="relative w-full">
                                            <img
                                                alt=""
                                                src={post.imageUrl}
                                                className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                            />
                                            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="max-w-xl">
                                            <div className="mt-8 flex items-center gap-x-4 text-sm">
                                                <p
                                                    className="relative z-10 rounded-full bg-emerald-50 px-3 py-1.5 font-medium text-emerald-600 hover:bg-gray-100"
                                                >
                                                    {post.category.title}
                                                </p>
                                            </div>
                                            <div className="group relative">
                                                <h3 className="text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                                                    <a href={post.href} target='blank' className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600 no-underline">
                                                        <span className="absolute inset-0" />
                                                        {post.title}
                                                    </a>
                                                </h3>
                                                <a href={post.href} target='blank' className="btn-main no-underline mt-3">Lire l'article</a>
                                            </div>
                                        </div>
                                    </article>
                                ))}
                            </div>
                            <div className="mx-auto max-w-2xl text-center">
                                <a href="https://clotere.framer.website/notaire-et-immobilier?utm_source=clotere" target="blank"
                                    type="button"
                                    className="mt-5 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Consultez nos guides notaire et immobilier
                                    <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                                </a>
                            </div>


                        </div>

                    </div>

                </section>
            </div>
            <PrendreRdv />
            <Faq />


            <Footer />
        </>
    );
}

export default Acheter;