import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import CardNotaire from "../components/CardNotaireApp";
import Loading from '../components/Loading';
import { useParams } from "react-router";
import { ChatBubbleLeftIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";



function Notaires(args, props) {

    const { type } = useParams();
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [recommandations, setRecommandations] = useState();
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    //console.log("args notaire",type,id)

    useEffect(() => {
        const fetchData = async () => {
            let tableName = ""; // Initialisation du nom de la table

            // Sélectionner la bonne table selon la variable `type`
            switch (type) {
                case "immo":
                    tableName = "transaction";
                    break;
                case "pma":
                    tableName = "pma";
                    break;
                case "mariage":
                    tableName = "mariage";
                    break;
                case "divorce":
                    tableName = "divorce";
                    break;
                default:
                    console.error("Type inconnu:", type);
                    return; // Arrêter l'exécution si le type n'est pas valide
            }

            // Construire l'URL avec la table sélectionnée
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/${tableName}/${id}`;

            try {
                const response = await fetch(URL, {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        "Content-Type": "application/x-www-form-urlencoded",
                        Accept: "application/json, text/plain, */*",
                    },
                });

                const result = await response.json();
                setData(result.fields); // Mettre à jour le state avec les données reçues
                console.log(`Données de ${tableName}:`, result.fields);
            } catch (error) {
                console.error(`Erreur lors de la récupération des données de ${tableName}:`, error);
            }
        };
        fetchData();
    }, [user]); // Déclenchement lorsque `type` ou `user` change

    //on va récupérer les infos sur la ou les reco
    const fetchRecommandations = async () => {
        try {
            // Vérifier si recommandations_notaire existe et contient des données
            if (!data.recommandations_notaire || data.recommandations_notaire.length === 0) {
                console.error("recommandations_notaire est vide ou non défini");
                return;
            }

            // Construire toutes les URLs nécessaires
            const urls = data.recommandations_notaire.map(id =>
                `https://api.airtable.com/v0/appD48APNaGA4GN0B/recommandations_notaire/${id}`
            );

            //console.log("URLs utilisées :", urls);

            // Effectuer toutes les requêtes en parallèle avec Promise.all()
            const responses = await Promise.all(
                urls.map(url =>
                    fetch(url, {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                            "Content-Type": "application/json",
                            Accept: "application/json, text/plain, */*",
                        },
                    })
                )
            );

            // Vérifier si toutes les réponses sont OK
            const results = await Promise.all(responses.map(response => {
                if (!response.ok) throw new Error(`Erreur HTTP ${response.status}`);
                return response.json();
            }));

            // Extraire les `fields` de chaque réponse et les stocker dans le state
            const recommandationsData = results.map(res => res.fields);
            setRecommandations(recommandationsData);

            console.log("Recommandations récupérées :", recommandationsData);
        } catch (error) {
            console.error("Erreur lors de la récupération des recommandations :", error);
        }
    };

    // Exécuter la fonction dans useEffect
    useEffect(() => {
        if (data) {
            fetchRecommandations();
        }
        setLoading(false)

    }, [data]); // Exécution lorsque `data` change

    console.log("la", recommandations)

    //on recupere la data de la card après success
    const [message, setMessage] = useState("");
    const handleSuccess = (msg) => {
        setMessage(msg);
      };

    if (isLoading) {
        return <Loading />;
    }
    return (
        <div>
            {recommandations &&
                <div className="relative isolate px-6  lg:px-8">
                    <div
                        aria-hidden="true"
                        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        />
                    </div>

                    <div className=" py-8 ">
                        <div className="container">
                            <Link to="/app/notaire" className="text-sm font-semibold text-gray-900 inline-flex no-underline items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-4">
                                    <path fill-rule="evenodd" d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" />
                                </svg>
                                Retour
                            </Link>

                            <div className="flex flex-col sm:flex-row justify-center items-start  py-12 sm:py-28 sm:gap-20 min-h-screen">
                                <div className="flex flex-col  flex-1 sm:w-1/2 items-center relative">
                                    <div className="sticky top-0">
                                    <div className="mb-10">
                                        <p className="mt-2 text-lg font-bold text-gray-900 mb-2">Voici le(s) notaire(s) que nous vous recommandons </p>
                                        <p className="text-lg text-gray-600">Nos recommandations sont basées sur la <span className="font-bold text-indigo-800">complexité de votre affaire</span>, la <span className="font-bold text-indigo-800">spécialisation du notaire</span> et sur vos <span className="font-bold text-indigo-800">besoins</span>.</p>
                                    </div>
                                    <ul role="list" className="hidden sm:block p-0 m-0 divide-y divide-gray-100">
                                        <p className="text-lg font-bold mb-8"><span role="img">👋</span> Quelques conseils pour bien choisir</p>
                                        <li className="flex gap-x-4 py-2">
                                            <MapPinIcon className="size-12 flex-none rounded-full text-indigo-200" />
                                            <div className="min-w-0">
                                                <p className="text-base font-semibold text-gray-900 mb-0">Vérifiez l’adresse de l’étude</p>
                                                <p className="mt-1  text-base text-gray-500">Il y aura des déplacements à prévoir à l’étude du notaire. Sauf si vous décidez de faire une dérogation.</p>
                                            </div>
                                        </li>
                                        <li className="flex gap-x-4 py-2">
                                            <ChatBubbleLeftIcon className="size-12 flex-none rounded-full text-indigo-200" />
                                            <div className="min-w-0">
                                                <p className="text-base font-semibold text-gray-900 mb-0">N'hésitez pas à demander à votre conseiller Clotere</p>
                                                <p className="mt-1  text-base text-gray-500">Si vous avez des questions avant de faire votre choix, vous pouvez contacter votre conseiller.</p>
                                            </div>
                                        </li>
                                        <li className="flex gap-x-4 py-2">
                                            <QuestionMarkCircleIcon className="size-12 flex-none rounded-full text-indigo-200" />
                                            <div className="min-w-0">
                                                <p className="text-base font-semibold text-gray-900 mb-0">Et si ce notaire ne me convient plus ? </p>
                                                <p className="mt-1  text-base text-gray-500">
                                                Vous pouvez changer de notaire gratuitement si il ne vous convient pas.

                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                {/** affichage des reco */}
                                <div className=" w-full gap-4 flex flex-col items-center flex-1">
                                    {recommandations.length > 1 ?
                                        <>
                                            {recommandations.map((reco, i) => (
                                                <>
                                                    <CardNotaire className="reco"
                                                        reco={reco}
                                                        nom={reco.prenom[0] + " " + reco.nom[0]}
                                                        adresse={reco.adresse[0]}
                                                        cp={reco.code_postal[0]}
                                                        ville={reco.ville[0] && (reco.ville[0])}
                                                        site={reco.site_web[0]}
                                                        telephone={reco.telephone[0]}
                                                        type_reco={reco.type_reco[0]}
                                                        use="oui"
                                                        type={type}
                                                        photo={reco.photo_profil[0] ? reco.photo_profil[0].url : 'https://firebasestorage.googleapis.com/v0/b/clotere-33ee1.appspot.com/o/icn-notaire.svg?alt=media&token=598450ff-1cca-4afe-b05d-c8d7ecc8f1cd'}
                                                        lien_fiche={reco.lien_fiche[0] ? reco.lien_fiche[0] : 'non'}
                                                        idAffaire={id}
                                                        idReco={reco.id[0]}
                                                        idNotaire={reco.notaire[0]}
                                                    />
                                                </>
                                            ))}
                                        </> :
                                        <>
                                            <CardNotaire className="reco"
                                                reco={recommandations[0]}
                                                nom={recommandations[0].prenom + " " + recommandations[0].nom}
                                                adresse={recommandations[0].adresse}
                                                cp={recommandations[0].cp}
                                                ville={recommandations[0].ville}
                                                site={recommandations[0].site}
                                                telephone={recommandations[0].telephone}
                                                use="oui"
                                                type={type}
                                                photo={recommandations[0].photo_profil ? recommandations[0].photo_profil[0].url : 'https://firebasestorage.googleapis.com/v0/b/clotere-33ee1.appspot.com/o/icn-notaire.svg?alt=media&token=598450ff-1cca-4afe-b05d-c8d7ecc8f1cd'}
                                                type_reco={recommandations[0].type_reco}
                                                lien_fiche={recommandations[0].lien_fiche ? recommandations[0].lien_fiche[0] : 'non'}
                                                idAffaire={id}
                                                idReco={recommandations[0].id}
                                                idNotaire={recommandations[0].notaire}
                                            />
                                        </>
                                    }
                                </div>
                                {/**affichage des conseils en mobile */}
                                <br/>
                                <ul role="list" className=" mt-16 sm:hidden p-0 m-0 divide-y divide-gray-100">
                                        <p className="text-lg font-bold mb-8"><span role="img">👋</span> Quelques conseils pour bien choisir</p>
                                        <li className="flex gap-x-4 py-2">
                                            <MapPinIcon className="size-12 flex-none rounded-full text-indigo-200" />
                                            <div className="min-w-0">
                                                <p className="text-base font-semibold text-gray-900 mb-0">Vérifiez l’adresse de l’étude</p>
                                                <p className="mt-1  text-base text-gray-500">Il y aura des déplacements à prévoir à l’étude du notaire. Sauf si vous décidez de faire une dérogation.</p>
                                            </div>
                                        </li>
                                        <li className="flex gap-x-4 py-2">
                                            <ChatBubbleLeftIcon className="size-12 flex-none rounded-full text-indigo-200" />
                                            <div className="min-w-0">
                                                <p className="text-base font-semibold text-gray-900 mb-0">N'hésitez pas à demander à votre conseiller Clotere</p>
                                                <p className="mt-1  text-base text-gray-500">Si vous avez des questions avant de faire votre choix, vous pouvez contacter votre conseiller.</p>
                                            </div>
                                        </li>
                                        <li className="flex gap-x-4 py-2">
                                            <QuestionMarkCircleIcon className="size-12 flex-none rounded-full text-indigo-200" />
                                            <div className="min-w-0">
                                                <p className="text-base font-semibold text-gray-900 mb-0">Et si ce notaire ne me convient plus ? </p>
                                                <p className="mt-1  text-base text-gray-500">
                                                Vous pouvez changer de notaire gratuitement si il ne vous convient pas.

                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                            </div>




                        </div>

                    </div>
                </div>
            }
        </div>

    );

}

export default Notaires;