import React from "react";



function CtaNotaire(args) {
    return (
        <>
            <div className="relative">
                <div class="">
                    <div className=" bg-[#edf0fb]">
                        <div className="p-14 md:ml-auto ">
                            <h2 className="text-base font-semibold leading-7 text-indigo-800">Améliorer votre relation client</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl">Vous êtes notaire ?</p>
                            <p className="mt-6 text-lg leading-7 text-gray-800">
                                Nos solutions vous aident à créer une expérience mémorable et renforcent votre relation client
                            </p>
                            <div className="mt-8">
                                <a
                                    href="https://notaire.clotere.fr?utm_source=www"
                                    target="blank"
                                    className="no-underline inline-flex items-center justify-center px-5 py-[calc(theme(spacing.3)-1px)] rounded-full  border-transparent bg-gray-950 shadow-md whitespace-nowrap text-base font-medium text-white data-[disabled]:bg-[#1DF36C] data-[hover]:bg-gray-800 data-[disabled]:opacity-40"
                                >
                                    Clotere pour les notaires
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default CtaNotaire;

