import React, { useState} from "react";
/** composants **/
import { Navbar } from '../Navbar';
import Footer from '../components/Footer';
import { Container } from "../annuaire/Container";
import Faq from '../components/Faq';
import { Helmet } from "react-helmet";
//img & icon
import { CheckIcon,CheckBadgeIcon } from "@heroicons/react/20/solid";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import PrendreRdv from "./prendreRdv";
import AvatarNotaire from "../img/avatar-notaire.png";
import PourquoiClotereNew from "../annuaire/PourquoiClotereNew2";
import Qsm from '../components/Qsm';
import BackHeaderTop from "../components/BackHeaderTop";
import BackHeaderBottom from "../components/BackHeaderBottom";
import AddressInput from "../components/AdresseInput";

const posts = [
    {
        id: 1,
        title: 'Quels sont les frais à prévoir lors de la vente d’un bien immobilier ?',
        href: 'https://clotere.framer.website/blog/quels-sont-les-frais-%C3%A0-pr%C3%A9voir-lors-de-la-vente-d-un-bien-immobilier?utm_source=clotere',
        description: "La vente d'un bien immobilier ne se limite pas à la simple transaction entre le vendeur et l'acheteur. En effet, elle implique aussi des frais annexes qu'il est important de prévoir pour éviter les mauvaises surprises.",
        imageUrl:
            'https://framerusercontent.com/images/oHanIMlzl6HOQ0CmHjJrGGa0Wo.png',
        category: { title: 'Notaire et immobilier', href: '#' },
    },
    {
        id: 2,
        title: 'Quels sont les documents nécessaires pour vendre ?',
        href: 'https://clotere.framer.website/blog/quels-sont-les-documents-n%C3%A9cessaires-pour-vendre-un-bien-immobilier?utm_source=clotere',
        description: "La vente d'un bien immobilier implique la préparation de divers documents essentiels pour assurer la transparence et la conformité légale de la transaction. Voici une liste des principaux documents à fournir par le vendeur principalement, lors de la vente de votre propriété.",
        imageUrl:
            'https://framerusercontent.com/images/7e3tCn5m9oQMkT40jRyfEeoWw.png',
        category: { title: 'Documents de vente', href: '#' },
    },
    {
        id: 3,
        title: 'Vendre un bien immobilier : les pièges à éviter avec l’aide du notaire',
        href: 'https://clotere.framer.website/blog/vendre-un-bien-immobilier-les-pi%C3%A8ges-%C3%A0-%C3%A9viter-avec-l-aide-du-notaire?utm_source=clotere',
        description: "Le notaire joue un rôle central pour garantir la sécurité juridique de la vente et vous aider à éviter les mauvaises surprises.",
        imageUrl:
            'https://framerusercontent.com/images/B45l3ig5EOgnybNo3qJLA9ixY.webp',
        category: { title: 'Notaire vendeur', href: '#' },
    },
]

function Acheter(args) {
        const [success, setSuccess] = useState(false);
        const [selectedAddress, setSelectedAddress] = useState(null);
    
        const handleLead = (event) => {
            event.preventDefault();
            console.log("envoyer");
            const email = event.target.email.value;
            const telephone = event.target.telephone.value;
            const type = "immo";
            const role = "vendeur";
    
            const sendToZapier = async () => {
                const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/2qnty6g/"; // 👈 Specify your Zapier Webhook URL here
                try {
                  const response = await fetch(zapierURL, {
                    method: "POST",
                    mode: "cors",
                    body: JSON.stringify({role,email,telephone,type,selectedAddress})
                  });
                  const res = await response.json();
                  console.log(res);
                  if(res.status === "success"){
                    //navigate("/onboard/notaire-immo");
                    setSuccess(true)
                  }
                } catch (e) {
                  console.log(e);
                }
              };
              sendToZapier();
        }
    return (
        <>
            <Helmet>
                <title>Trouver un notaire pour vendre votre bien immobilier</title>
                <meta name="robots" content="index,follow" />
                <meta name="googlebot" content="index,follow" />
                <meta name='description' content='Trouvez votre notaire pour vous accompagner dans votre vente immobilière et gagner du temps grâce à Clotere, la solution qui simplifie votre passage chez le notaire.' />
                <meta property="og:url" content="https://clotere.fr/notaire-vendeur" />
                <meta property="og:site_name" content="Clotere" />
                <meta property="og:image" content="https://clotere.fr/facebook-share-webp" />
                <meta property="og:type" content="website" />
            </Helmet>

            {/**header 2 */}
            <div className="relative rounded-4xl ">
            <BackHeaderTop/>
                <Navbar user={args.user} />
                <Container className="relative ">

                    <header class="flex flex-row flex-wrap justify-center sm:py-4">
                        {/** colonne gauche */}
                        <div className="w-full sm:w-1/2 p-3 sm:p-4 text-left">
                            <h1 className="font-medium sm:!text-[70px] mx-auto tracking-[0] leading-12 mb-3 sm:leading-[60px]">
                                Trouvez <span className="font-bold mt-2 text-indigo-800">votre notaire</span> pour votre vente immobilière.
                            </h1>
                            <div className="flex flex-column justify-start gap-3">
                                <div className="flex items-center gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Prise en charge immédiate</b> de votre dossier</p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">100% gratuit</b> pour l'acheteur et le vendeur</p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Une assistance juridique</b> illimitée</p>
                                    </div>
                                </div>
                                <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                    <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                        <p className="m-0 text-lg"><b className="text-indigo-800">Un notaire adapté à vos besoins</b></p>
                                    </div>
                                </div>
                            </div>
                            {/** card notaire dispo */}
                            <figure className="rounded-2xl bg-white ring-1 shadow-lg ring-gray-900/5 mt-4">
                                <figcaption className="flex items-center gap-x-4 gap-y-4 border-t border-gray-900/10 p-3 sm:flex-nowrap w-content">
                                    <img
                                        alt="notaires disponibles"
                                        src={AvatarNotaire}
                                        className="flex"
                                    />
                                    <div className="flex-auto">
                                        <div className="font-semibold"><span className="text-indigo-800">Des notaires disponibles</span> pour prendre en charge votre affaire.</div>
                                    </div>
                                </figcaption>
                                </figure>
                        </div>
                        {/** colonne droite */}
                        <div className="w-full sm:w-1/2  p-0 lg:p-5  sm:flex overflow-hidden items-center">
                            <div className="p-6 bg-white/50 text-center w-full rounded-3xl">
                            {success === false && <>
                                <form action="#" method="POST" className="space-y-3 text-left w-full" onSubmit={handleLead}>
                                <div className="mt-2">
                                        <AddressInput placeholderText="Quel est le code postal de votre bien ? *" className="input-clotere" onAddressSelect={(place) => setSelectedAddress(place)} />
                                    </div>
                                    
                                    <div>
                                        <div className="mt-2">
                                            <input
                                                name="email"
                                                type="email"
                                                required
                                                className="input-clotere"
                                                placeholder="Votre email*"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mt-2">
                                            <input
                                                name="telephone"
                                                type="phone"
                                                className="input-clotere"
                                                placeholder="Votre telephone"
                                            />
                                        </div>
                                    </div>
                                    <div className="rounded-md bg-blue-50 p-4">
                                        <div className="flex">
                                            <div className="shrink-0">
                                                👋
                                            </div>
                                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                                <p className="text-sm text-blue-700 p-0 m-0">Vos données restent privées, elles nous servent uniquement à vous mettre en relation avec des notaires adaptés à vos besoins..</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="btn-indigo p-4"
                                        >
                                            Choisir mon notaire
                                        </button>
                                    </div>
                                </form>
                            </>}
                            {success === true && <>
                            <div className="!text-center">
                                <CheckBadgeIcon className="size-24 text-indigo-800 mx-auto"/>
                                <p className="text-xl">Nous avons bien reçu votre demande, nous allons vous envoyer par mail les profils de notaires disponibles pour votre affaire.</p>
                                </div>
                            </>}
                                
                            </div>
                        </div>
                    </header>
                </Container>
                <BackHeaderBottom/>
            </div>

            {/** section ccm **/}
            <section id='section1' className="mt-20 mb-20">
                    <Container>
                        <div class="container mx-auto px-[15px] h-full">
                            <div class="flex flex-col justify-between items-start gap-[30px]">
                                <div class="py-2 px-4 rounded-[20px] text-center bg-emerald-500/30 text-emerald-500">
                                    Trouvez un notaire pour votre vente simplement
                                </div>
                                <h3 class="text-black leading-[48px] text-[40px] lg:leading-[50px] lg:text-[45px] font-bold sm:max-w-[60%]">Votre notaire immobilier avec Clotere, comment ça marche ?</h3>

                                <div class="grid lg:grid-cols-4 w-full gap-[10px] ">
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Choisissez votre notaire</h3>
                                            <p>A vous de choisir votre notaire pour gérer votre vente. 100% à distance ou à proximité, c’est vous qui choisissez</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Répondez à quelques questions</h3>
                                            <p>Nous avons besoin de quelques informations sur vous et votre vente pour constituer votre dossier.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                                                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Ajoutez vos documents</h3>
                                            <p>On vous liste l'ensemble des documents nécessaires pour votre vente, vous n'avez plus qu'à les ajouter dans votre espace sécurisé.</p>
                                        </div>
                                    </div>
                                    
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">

                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Fixez vos rendez-vous de signature</h3>
                                            <p>Faites vos rendez-vous en visio ou présentiel, et signez plus vite votre acte authentique de vente.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            <Qsm />
            <PourquoiClotereNew/>
                

                

                {/** section Blog */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="bg-white sm:py-20 ">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto max-w-2xl text-center">
                                <h2 className="title-clotere">Bien comprendre votre passage chez le notaire pour <span className="font-bold text-indigo-800">votre vente immobilière</span></h2>

                            </div>
                            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
                                {posts.map((post) => (
                                    <article key={post.id} className="flex flex-col items-start justify-between overflow-hidden bg-white shadow sm:rounded-lg p-3">
                                        <div className="relative w-full">
                                            <img
                                                alt=""
                                                src={post.imageUrl}
                                                className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                            />
                                            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="max-w-xl">
                                            <div className="mt-8 flex items-center gap-x-4 text-sm">
                                                <p
                                                    className="relative z-10 rounded-full bg-emerald-50 px-3 py-1.5 font-medium text-emerald-600 hover:bg-gray-100"
                                                >
                                                    {post.category.title}
                                                </p>
                                            </div>
                                            <div className="group relative">
                                                <h3 className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                                                    <a href={post.href} target='blank' className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600 no-underline">
                                                        <span className="absolute inset-0" />
                                                        {post.title}
                                                    </a>
                                                </h3>
                                                <a href={post.href} target='blank' className="btn-main no-underline">Lire l'article</a>
                                            </div>
                                        </div>
                                    </article>
                                ))}
                            </div>
                            <div className="mx-auto max-w-2xl text-center">
                                <a href="https://clotere.framer.website/notaire-et-immobilier?utm_source=clotere" target="blank"
                                    type="button"
                                    className="mt-5 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Consultez nos fiches pratiques notaire et immobilier
                                    <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                                </a>
                            </div>


                        </div>

                    </div>

                </section>
            <PrendreRdv />
            <Faq />


            <Footer />
        </>
    );
}

export default Acheter;