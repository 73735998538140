import React from "react";
/** composants **/
import { Navbar } from '../Navbar';
import Footer from '../components/Footer';
import { Container } from "../annuaire/Container";
import FaqMariage from '../components/FaqMariage';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
//img & icon
import { CheckIcon } from "@heroicons/react/20/solid";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import PourquoiClotereNew from "../annuaire/PourquoiClotereNew";
import PrendreRdv from "./prendreRdv";
import BackHeaderTop from "../components/BackHeaderTop";
import BackHeaderBottom from "../components/BackHeaderBottom";

const posts = [
    {
        id: 1,
        title: 'Les différents régimes matrimoniaux.',
        href: 'https://clotere.framer.website/blog/les-diff%C3%A9rents-r%C3%A9gimes-matrimoniaux-choisir-le-bon-pour-votre-couple',
        category: { title: 'Type de contrat de mariage' },
    },
    {
        id: 2,
        title: 'Contrat de mariage idées reçues et vérités',
        href: 'https://clotere.framer.website/blog/contrat-de-mariage-id%C3%A9es-re%C3%A7ues-et-v%C3%A9rit%C3%A9s',
        category: { title: 'Contrat de mariage' },
    },
    {
        id: 3,
        title: 'Pourquoi choisir un contrat de mariage ?',
        href: 'https://clotere.framer.website/blog/pourquoi-choisir-un-contrat-de-mariage',
        category: { title: 'Contrat de mariage' },
    },
    {
        id: 4,
        title: 'Les étapes de votre contrat de mariage',
        href: 'https://clotere.framer.website/blog/les-%C3%A9tapes-pour-%C3%A9tablir-un-contrat-de-mariage',
        category: { title: 'Etapes contrat de mariage'},
    },
]

function Mariage(args) {
    return (
        <>
            <Helmet>
                <title>Trouver un notaire pour votre contrat de mariage</title>
                <meta name="robots" content="index,follow" />
                <meta name="googlebot" content="index,follow" />
                <meta name='description' content='Clotere.fr vous aide à trouver votre notaire pour votre contrat de mariage.' />
                <meta property="og:url" content="https://clotere.fr/notaire-contrat-mariage" />
                <meta property="og:site_name" content="Clotere" />
                <meta property="og:image" content="https://clotere.fr/facebook-share-webp" />
                <meta property="og:type" content="website" />
            </Helmet>


            {/**header 2 */}
                        <div className="relative rounded-4xl ">
                        <BackHeaderTop/>
                            <Navbar user={args.user} />
                            
                            <Container className="relative ">
            
                                <header class="flex flex-row flex-wrap justify-center sm:py-10">
                                    <div className="w-full sm:basis-1/2 p-3 sm:p-4">
                                        <h1 className="font-medium text-transparent sm:!text-[45px] tracking-[0] leading-10 mb-3" style={{ lineHeight: "50px" }}>
                                            <span className=" text-black">Trouvez votre notaire pour  <span className="font-bold mt-2 text-indigo-800">votre contrat de mariage</span> </span>
                                        </h1>
            
                                        <div className="flex flex-column justify-start gap-3">
                                            <div className="flex items-center gap-2 relative flex-[0_0_auto]">
                                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                                    <p className="m-0 text-lg"><b className="text-indigo-800">Simple</b> : quelques informations suffisent pour démarrer votre projet.</p>
                                                </div>
                                            </div>
                                            <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                                    <p className="m-0 text-lg"><b className="text-indigo-800">Sûr</b> : déposez dans votre espace sécurisé les documents nécessaires.</p>
                                                </div>
                                            </div>
                                            <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                                    <p className="m-0 text-lg"><b className="text-indigo-800">Rapide</b> : votre notaire prends en charge votre dossier rapidement.</p>
                                                </div>
                                            </div>
                                            <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                                    <p className="m-0 text-lg"><b className="text-indigo-800">Suivi</b> : un conseiller dédié disponible pour vous accompagner de A à Z.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <Link to="/onboard/mariage"
                                                type="button"
                                                className="box-content mt-3 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Débuter mon dossier
                                                <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="w-full sm:basis-1/2 p-0 lg:p-5  sm:flex bg-yellow-500 overflow-hidden rounded-2xl">
                                        <img className="object-cover" src="https://images.unsplash.com/photo-1515626553181-0f218cb03f14?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8ODB8fG1hcmlhZ2V8ZW58MHx8MHx8fDA%3D" alt="Vos démarches chez le notaire pour votre contrat de mariage" />
            
                                    </div>
                                </header>
                            </Container>
                            <BackHeaderBottom/>
                        </div>
               
                {/** section ccm **/}
                <section id='section1' className="mt-20 mb-20">
                    <Container>
                        <div class="container mx-auto px-[15px] h-full">
                            <div class="flex flex-col justify-between items-start gap-[30px]">
                                <div class="py-2 px-4 rounded-[20px] text-center bg-emerald-500/30 text-emerald-500">
                                    Trouvez votre notaire simplement
                                </div>
                                <p class="text-black text-[20px] leading-[28px] sm:leading-[48px]   sm:text-[40px]  lg:text-[45px] font-bold sm:max-w-[60%]">Votre <span className="text-indigo-800">contrat de mariage</span> avec Clotere, comment ça marche ?</p>

                                <div class="grid lg:grid-cols-4 w-full gap-[10px] ">
                                <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Choisissez votre notaire</h3>
                                            <p>A vous de choisir votre notaire pour gérer. 100% à distance ou à proximité de chez vous c’est vous qui choisissez.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Répondez à quelques questions</h3>
                                            <p>Nous avons besoin de certaines informations pour préparer votre contrat de mariage.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                                                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Complétez votre dossier</h3>
                                            <p>Ajoutez dans votre espace sécurisé les documents nécessaires à votre dossier chez le notaire.</p>
                                        </div>
                                    </div>
                                    
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">

                                        <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Fixez votre rendez-vous de signature</h3>
                                            <p>Faites vos rendez-vous en visio ou présentiel, et obtenez votre contrat de mariage.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>

                {/** section accompagneent */}
            <PourquoiClotereNew />
            {/** section Blog */}
            <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="bg-white  sm:py-20 ">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto text-center">
                                <h2 className="title-clotere">Bien comprendre votre passage chez le notaire pour <span className="font-bold text-indigo-800">votre contrat de mariage</span></h2>

                            </div>
                            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-4 ">
                                {posts.map((post) => (
                                    <article key={post.id} className="flex flex-col items-start gap-2 sm:gap-8 justify-center overflow-hidden bg-white shadow rounded-lg p-3">
                                         <p className="font-bold text-indigo-600 hover:bg-gray-100 m-0">{post.category.title} </p>
                                            
                                                <h3 className="mt-3 text-xl sm:!text-2xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                                                    
                                                       
                                                        {post.title}
                                                    
                                                    </h3>
                                                
                                                <a href={post.href} target='blank' className="btn-primary no-underline">Lire l'article</a>
                                            
                                    </article>
                                ))}
                            </div>
                            <div className="mx-auto max-w-2xl text-center">
                                <a href="https://clotere.framer.website/notaire-et-mariage?utm_source=clotere" target="blank"
                                    type="button"
                                    className="mt-5 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Consultez nos fiches pratiques notaire et contrat de mariage
                                    <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                                </a>
                            </div>


                        </div>

                    </div>

                </section>

                {/** section SEO 
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="w-full sm:basis-1/2  p-4 ">
                        <img alt="trouvez un notaire pour votre achat immobilier" src={AchatAvantage2} className="rounded overflow-hidden w-4/5" />
                    </div>
                    <div className="w-full sm:basis-1/2  p-4">
                        <h2 className="title-clotere">Tout savoir sur <span className="text-indigo-800">le contrat de mariage</span></h2>
                        <p>
                        Un contrat de mariage est une étape essentielle pour préparer votre union et protéger vos biens. Ce document, rédigé et validé par un notaire, permet de définir les règles de gestion de votre patrimoine pendant le mariage et en cas de séparation ou de décès.

                        <br/><br/>Le contrat de mariage n’est pas obligatoire, mais <span className="font-bold text-indigo-500">il offre une sécurité juridique et évite d’appliquer automatiquement le régime légal (communauté réduite aux acquêts)</span>. Il s’adapte à vos besoins et à votre situation : entrepreneur, propriétaire d’un bien immobilier, famille recomposée, etc.

                        <br/><br/>Clotere vous permet de créer votre dossier de contrat de mariage en ligne, et d'échanger avec un notaire pour vous accompagner dans cette démarche.

                        </p>
                        <Link to="/onboard/contrat-mariage"
                            type="button"
                            className="no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Débuter mon contrat de mariage
                            <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                        </Link>
                    </div>

                </section>
                */}
                <FaqMariage />
             <PrendreRdv />
            


            <Footer />
        </>
    );
}

export default Mariage;