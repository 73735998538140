import React from "react";
import { ListGroup, ListGroupItem,  Badge} from "reactstrap";
import { Link } from 'react-router-dom';
import { faLink,  faLocationDot, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import icnNotaire from '../img/icn-notaire.svg';
import { CardNotaires, BadgeUse, PhotoProfil } from "../style/Annuaire";



function CardNotaire(args) {

    return (
        <>
            <CardNotaires className={(args.use === "oui" ? 'reco' : '')}>
                {args.photo ? <PhotoProfil><img src={args.photo} alt={args.prenom + " " + args.nom} /></PhotoProfil> : <img src={icnNotaire} alt="notaire" />}

                <div>
                    {args.type_reco === "recommandation agent" &&
                        <>
                            <ListGroupItem className='p-0'>
                                <p><FontAwesomeIcon icon={faStar} className='mr-3 rounded' />
                                    <b>Votre agent vous recommande ce notaire</b></p>
                            </ListGroupItem>
                        </>
                    }
                    <div className="head">
                        {args.fiche && 
                        <>
                            {args.fiche === "non" ?
                            <h4> Maitre {args.prenom} {" "}{args.nom}</h4>
                            :
                            <Link to={`/notaire/${args.fiche}`}>
                                <h4> Maitre {args.prenom} {" "}{args.nom}</h4>
                            </Link>
                        }
                        </>
                        }
                        {/** composant pour la page notaire app */}
                        {args.lien_fiche &&
                        <>
                            <Link to={args.lien_fiche} target="_blank">
                            <h4> Maitre {args.prenom} {" "}{args.nom}</h4>
                            </Link>
                        </>
                        }
                    </div>
                    
                    {args.use === "oui" ? <BadgeUse className="use">Notaire recommandé</BadgeUse> : <Badge>Notaire</Badge>}



                    <ListGroup horizontal>

                        <ListGroupItem className='p-0'>
                            <FontAwesomeIcon icon={faLocationDot} className='mr-3 rounded' />
                            <p>{args.adresse} {" "} {args.cp}{" "} {args.ville}</p>
                        </ListGroupItem>

                    

                        {args.site &&
                            <>
                                <ListGroupItem className='p-0'>
                                    <FontAwesomeIcon icon={faLink} className='mr-3 rounded' />
                                    <a href={`${args.site}?utm_source=clotere`} target="blank">Consulter le site internet</a>
                                </ListGroupItem>
                            </>
                        }
                        {args.use === "oui" &&
                            <>
                                <>
                                    <ListGroupItem className='p-0 w-full text-center'>
                                        <Link to={`/notaire/${args.fiche}`} className="no-underline inline-flex items-center justify-center px-5 py-[calc(theme(spacing.3)-1px)] rounded-full  border-transparent bg-gray-950 shadow-md whitespace-nowrap text-base font-medium text-white data-[disabled]:bg-[#1DF36C] data-[hover]:bg-gray-800 data-[disabled]:opacity-40">
                                                En savoir plus
                                            </Link>

                                    </ListGroupItem>
                                </>
                            </>
                        }
                    </ListGroup>
                </div>
            </CardNotaires>


        </>


    );
}

export default CardNotaire;