import React from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
const faqs = [
    {
      question: "Quand dois-je effectuer les paiements ?",
      answer:
        "A la signature du compromis de vente vous devrez verser une somme appelé le sequestre, d'un montant d’environ 5 % à 10 % du prix de. Il n'y a pas de règle légale le montant dépends de l'accord entre les parties.La somme est généralement versé par virement.",
    },
    {
      question: "Puis-je changer d'avis et me rétracter ?",
      answer:
        "Vous disposez de 10 jours après la signature du compromis de vente pour vous rétracter sans frais. Au dela de ce délai, il est possible de se rétracter mais cela peut engendrer des frais.",
    },
    {
        question: "De combien de temps je dispose pour trouver un financement ?",
        answer:
          "Vous avez généralement 2 à 3 mois pour trouver un financement. Dans le cas ou vous n'avez pas encore d'offre de prêt au moment de la signature du compromis de vente, il vous sera conseillé d'ajouter une clause suspensive  pour conditionner la vente du bien immobilier à l’obtention de votre financement. Par exemple, si les deux parties signent le compromis de vente le 23 mars, la date limite indiquée par le notaire sera comprise entre le 23 mai et le 23 juin.",
      },
      {
        question: "Que se passe-t-il si je n'ai pas mon prêt immobilier ?",
        answer:
          "Si malheureusement vous n'avez pas obtenu l'accord pour votre financement, vous pouvez annuler la vente sans payer des dommages et intérêts au propriétaire. Néanmoins, vous devez fournir des documents justificatifs pour récupérer votre séquestre.",
      },
    // More questions...
  ]


function FaqAcheteur(args) {

    return (
        <>
        <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
      <div className="mx-auto max-w-7xl px-6 py-4">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <dl className="space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-4">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                      <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
                    </>
    );
}

export default FaqAcheteur;