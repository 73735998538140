import React from "react";
import styled from "styled-components";
import { CalendarDaysIcon, InformationCircleIcon } from "@heroicons/react/20/solid";


export const AideContent = styled.div`

svg.iconflex{
    margin-right:2rem;
    margin-left:2rem;
    color:${props => props.theme.colors.main};
}
.alert{
    background-color: #1af46c2b !important;
    border: 0;
}
li::marker {
    color: ${props => props.theme.colors.main};
}
`;



function AideConsentementPma() {
    return (
        <AideContent>
            <p>
                <div className="">
                Le consentement à la PMA (Procréation Médicalement Assistée) est une démarche juridique essentielle qui garantit que toutes les parties impliquées (couples ou femmes célibataires) acceptent librement et en toute connaissance de cause le recours aux techniques médicales de procréation, comme l’insémination artificielle ou la fécondation in vitro (FIV).
                </div>

                <p className="text-base font-bold leading-7 text-gray-900 mt-3">3 choses à retenir :</p>
                <div className="rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900">Ce document doit être rédigé <span className="text-indigo-800"> avant d'entamer un parcours de PMA</span>.</dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">Avant d’entamer un parcours de PMA, un consentement écrit doit être signé devant un notaire pour sécuriser l’engagement des futurs parents, notamment en cas de séparation ou de décès.</p>
                        </div>
                    </div>
                </div>
                <div className="mt-3 rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900">Ce document permet la reconnaissance de la <span className="text-indigo-800">filiation</span>.</dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">Pour les couples non mariés ou les couples de femmes, ce consentement garantit que l’enfant à naître sera légalement rattaché aux deux parents.</p>
                        </div>
                    </div>
                </div>
                <div className="mt-3 rounded-md bg-gray-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </div>
                        <div className="ml-3">
                            <dt className="text-base font-bold leading-7 text-gray-900">Après signature ce document est<span className="text-indigo-800">irrévocable</span></dt>
                            <p className="mt-2 text-base leading-7 text-gray-600 mb-0">Une fois signé, ce consentement ne peut être annulé unilatéralement après la conception de l’embryon.</p>
                        </div>
                    </div>
                </div>

                <p className="text-base font-semibold leading-7 text-gray-900 mt-2 mb-0 flex gap-2 items-center"><CalendarDaysIcon aria-hidden="true" className="h-5 w-5 text-indigo-500" />Quand signe-t-on ce document ?</p>
                <p className="text-base leading-7 text-gray-600">Ce document est signé chez le notaire <b>avant le début de votre procédure PMA </b></p>

                

                <div className="rounded-md bg-emerald-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            👋
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-emerald-700 mb-0">Il vous manque une information ? N'hésitez pas à contacter votre conseiller Clotere.</p>

                        </div>
                    </div>
                </div>




            </p>
        </AideContent>
    );
}

export default AideConsentementPma;