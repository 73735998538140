import React, { useState, useRef } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

const GOOGLE_MAPS_API_KEY = "AIzaSyBQAy93ytjrAJpeKmtsOjae0cXOoBWxI8A"; // Remplacez par votre clé API

const AddressInput = ({ onAddressSelect,placeholderText }) => {
    const [address, setAddress] = useState("");
    const autocompleteRef = useRef(null);

    // Fonction appelée quand l'utilisateur sélectionne une adresse
    const handlePlaceSelect = () => {
        const place = autocompleteRef.current.getPlace();
        if (place && place.formatted_address) {
            setAddress(place.formatted_address);
            onAddressSelect(place); // Transmet l'adresse sélectionnée au parent
        }
    };

    return (
        <LoadScript
            googleMapsApiKey={GOOGLE_MAPS_API_KEY}
            libraries={["places"]} // Nécessaire pour Autocomplete
        >
            <Autocomplete
                onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                onPlaceChanged={handlePlaceSelect}
                options={{ componentRestrictions: { country: "fr" } }} // Restriction à la France 🇫🇷
            >
                <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder={placeholderText}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
            </Autocomplete>
        </LoadScript>
    );
};

export default AddressInput;