import React, { useEffect, useState,useRef } from 'react';
import { Navbar, TabContent, TabPane } from 'reactstrap';
import { useMemberstack } from "@memberstack/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from '../components/Loading';

// Composants
import Dashboard from './Dashboard';
import Bien from './Bien';
import Interlocuteurs from './Interlocuteurs';
import Documents from './Documents';
import Profil from './Profil';
import Notaire from './Notaire';
import Message from './Message';
import DashboardDonation from './donation/DashboardDonation';
import DocumentsDonation from './donation/DocumentsDonation';
import DashboardPma from './pma/Dashboard';
import DocumentsPma from './pma/Documents';
import NotairePma from './pma/Notaire';
import DashboardMariage from './mariage/Dashboard';
import DocumentsMariage from './mariage/Documents';
import NotaireMariage from './mariage/Notaire';

// Styles & Icônes
import icnLogo from '../img/icn-logo.svg';
import { Sidebar, SidebarBody, SidebarFooter, SidebarHeader, SidebarItem, SidebarLabel, SidebarSection, SidebarSpacer } from '../components/catalyst/sidebar';
import { SidebarLayout } from '../components/catalyst/sidebar-layout';
import { ArrowRightStartOnRectangleIcon, ChevronUpIcon, BuildingOfficeIcon, DocumentIcon, HomeIcon, UserIcon, UserCircleIcon, ChatBubbleLeftRightIcon, UsersIcon } from '@heroicons/react/20/solid'
import { Avatar } from '../components/catalyst/avatar';
import { Dropdown, DropdownButton, DropdownDivider, DropdownItem, DropdownLabel, DropdownMenu } from '../components/catalyst/dropdown';

// Fonction générique pour récupérer des données depuis Airtable
const fetchFromAirtable = async (table, id = null, filter = null, fieldName = null) => {
    let url = `https://api.airtable.com/v0/appD48APNaGA4GN0B/${table}`;

    if (id) url += `/${id}`;
    if (filter && fieldName) {
        url += `?filterByFormula=FIND("${filter}", {${fieldName}})`;
    }

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                'content-type': 'application/x-www-form-urlencoded',
                "Accept": "application/json, text/plain, /"
            },
        });
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        return await response.json();
    } catch (error) {
        console.error(`❌ Erreur lors de la récupération de ${table}:`, error);
        return null;
    }
};

// Fonction pour récupérer uniquement les `fields` des records
const fetchRecordsFields = async (table, filterId, fieldName) => {
    const response = await fetchFromAirtable(table, null, filterId, fieldName);
    return response?.records ? response.records.map(record => record.fields) : null;
};

function Layout() {
    const location = useLocation();
    const navigate = useNavigate();
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const [currentActiveTab, setCurrentActiveTab] = useState(null);
    const [data, setData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [selectedTransactionIndex, setSelectedTransactionIndex] = useState(0);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    //on fait toutes les requêtes de récupération de données
    useEffect(() => {
        const fetchData = async () => {
            const memberData = await memberstack.getCurrentMember();
            if (!memberData.data) return;
            setMember(memberData.data);

            if (!memberData.data.metaData.airtable_id) return;

            const userData = await fetchFromAirtable("user", memberData.data.metaData.airtable_id);
            if (!userData) return;

            setUserData(userData); // Stocke les données utilisateur pour le reste du composant
            console.log("user",userData)

            let detectedDemarche = null;
            const demarches = ["transaction", "donation", "pma", "mariage"];
            for (const d of demarches) {
                if (userData.fields[d]) {
                    detectedDemarche = d;
                    break;
                }
            }
            console.log("detectedDemarche",detectedDemarche)

            let transactionData = detectedDemarche
                ? await fetchFromAirtable(detectedDemarche, userData.fields[detectedDemarche][0])
                : null;
            let pmaData = detectedDemarche
                ? await fetchFromAirtable(detectedDemarche, userData.fields[detectedDemarche][0])
                : null;
            let mariageData = detectedDemarche
                ? await fetchFromAirtable(detectedDemarche, userData.fields[detectedDemarche][0])
                : null;



            if (detectedDemarche && detectedDemarche === "transaction") {
                console.log("lenght", userData.fields.transaction.length)
                if (userData.fields.transaction && Array.isArray(userData.fields.transaction) && userData.fields.transaction.length > 1) {
                    console.log("plusieurs transac :", userData.fields.transaction.length);
                    const transactionIds = userData.fields.transaction;

                    const allTransactions = await Promise.all(
                        transactionIds.map(async (id) => {
                            const transactionData = await fetchFromAirtable("transaction", id);
                            return transactionData ? transactionData.fields : null;
                        })
                    );

                    const validTransactions = allTransactions.filter(t => t !== null);
                    setTransactions(validTransactions);

                    loadTransactionData(0, validTransactions, userData);
                    


                } else {
                    console.log("une seule transac.");
                    setData({
                        user: userData.fields,
                        demarche: detectedDemarche,
                        transaction: transactionData ? transactionData.fields : null,
                        bien: detectedDemarche === "transaction" ? await fetchRecordsFields("bien", userData.fields.transaction[0], "transaction") : null,
                        evenement: await fetchRecordsFields("event", userData.fields[detectedDemarche][0], "transaction"),
                        rdv: await fetchRecordsFields("rdv", userData.fields[detectedDemarche][0], "transaction"),
                        action: await fetchRecordsFields("actions", userData.fields[detectedDemarche][0], "transaction"),
                        document: await fetchRecordsFields("document", userData.fields[detectedDemarche][0], "transaction")
                    });
                    
                }

            }
            if (userData.fields.transaction && userData.fields.transaction.length > 0) {
                const transactionIds = userData.fields.transaction; // Liste des transactions

                // Récupération de toutes les transactions
                const allTransactions = await Promise.all(
                    transactionIds.map(async (id) => {
                        const transactionData = await fetchFromAirtable("transaction", id);
                        return transactionData ? transactionData.fields : null;
                    })
                );

                const validTransactions = allTransactions.filter(t => t !== null); // Filtrer les transactions valides

                setTransactions(validTransactions); // Stocker toutes les transactions
                console.log("vald transaction", validTransactions)

                // Charger directement la première transaction si elle est la seule
                if (validTransactions.length === 1) {
                    loadTransactionData(0, validTransactions, userData);
                }
            }
            if (detectedDemarche && detectedDemarche === "pma") {
                setData({
                    user: userData.fields,
                    demarche: detectedDemarche,
                    pma: pmaData ? pmaData.fields : null,
                    evenement: await fetchRecordsFields("event", userData.fields[detectedDemarche][0], "pma"),
                    rdv: await fetchRecordsFields("rdv", userData.fields[detectedDemarche][0], "pma"),
                    action: await fetchRecordsFields("actions", userData.fields[detectedDemarche][0], "pma"),
                    document: await fetchRecordsFields("document", userData.fields[detectedDemarche][0], "pma")
                });
                setCurrentActiveTab("dashboard-pma");
            }
            if (detectedDemarche && detectedDemarche === "mariage") {
                //console.log("hello")
                setData({
                    user: userData.fields,
                    demarche: detectedDemarche,
                    mariage: mariageData ? mariageData.fields : null,
                    evenement: await fetchRecordsFields("event", userData.fields[detectedDemarche][0], "mariage"),
                    rdv: await fetchRecordsFields("rdv", userData.fields[detectedDemarche][0], "mariage"),
                    action: await fetchRecordsFields("actions", userData.fields[detectedDemarche][0], "mariage"),
                    document: await fetchRecordsFields("document", userData.fields[detectedDemarche][0], "mariage")
                });
                setCurrentActiveTab("dashboard-mariage");
            }


        };
        fetchData();
    }, []);

    //fonction de load des transactions si plusieurs transactions immo
    const loadTransactionData = async (index, transactionsList = transactions, userData) => {
        //console.log("📌 Chargement des données pour l'index :", index);

        if (!transactionsList[index]) {
            //console.log("❌ Erreur : Aucune transaction trouvée à cet index !");
            return;
        }

        if (!userData || !userData.fields || !userData.fields.transaction || !userData.fields.transaction[index]) {
            //console.log("❌ Erreur : L'ID de la transaction n'existe pas dans userData.");
            return;
        }

        const transactionId = userData.fields.transaction[index];
        //console.log("🆔 ID de la transaction sélectionnée :", transactionId);

        try {
            const bienData = await fetchRecordsFields("bien", transactionId, "transaction");
            //console.log("🏠 Bien récupéré :", bienData);

            const evenementData = await fetchRecordsFields("event", transactionId, "transaction");
            //console.log("📅 Événements récupérés :", evenementData);

            const rdvData = await fetchRecordsFields("rdv", transactionId, "transaction");
            //console.log("📞 Rendez-vous récupérés :", rdvData);

            const actionData = await fetchRecordsFields("actions", transactionId, "transaction");
            //console.log("✅ Actions récupérées :", actionData);

            const documentData = await fetchRecordsFields("document", transactionId, "transaction");
            //console.log("📄 Documents récupérés :", documentData);

            setData({
                user: userData.fields,
                demarche: "transaction",
                transaction: transactionsList[index],
                bien: bienData,
                evenement: evenementData,
                rdv: rdvData,
                action: actionData,
                document: documentData,
            });

            //console.log("✅ Mise à jour de l'état `data` réussie !");
            setSelectedTransactionIndex(index);
        } catch (error) {
            console.log("❌ Erreur dans loadTransactionData :", error);
        }
    };


    useEffect(() => {
        console.log("🔄 Changement de route détecté :", location.pathname);
        console.log("🟢 currentActiveTab avant mise à jour :", currentActiveTab);
    
        const path = location.pathname.replace("/app/", "") || "dashboard";
        setCurrentActiveTab(path);
    
        console.log("✅ Nouveau currentActiveTab :", path);
    }, [location.pathname]);
    
    const navigateTo = (tab) => {
        navigate(`/app/${tab}`);
        setCurrentActiveTab(tab); // Assurer le changement immédiat
    };

    useEffect(() => {
        switch (location.pathname) {
            case "/app/documents":
                setCurrentActiveTab("documents");
                break;
            case "/app/dashboard":
                setCurrentActiveTab("dashboard");
                break;
            case "/app/notaire":
                setCurrentActiveTab("notaire");
                break;
            case "/app/message":
                setCurrentActiveTab("message");
                break;
            case "/app/interlocuteurs":
                setCurrentActiveTab("interlocuteurs");
                break;  // ✅ AJOUT DU BREAK ICI
            case "/app/bien":
                setCurrentActiveTab("bien");
                break;
            case "/app/dashboard-pma":
                setCurrentActiveTab("dashboard-pma");
                break;
            case "/app/document-pma":
                setCurrentActiveTab("document-pma");
                break;
            case "/app/notaire-pma":
                setCurrentActiveTab("notaire-pma");
                break;
            case "/app/dashboard-mariage":
                setCurrentActiveTab("dashboard-mariage");
                break;
            case "/app/document-mariage":
                setCurrentActiveTab("document-mariage");
                break;
            case "/app/notaire-mariage":
                setCurrentActiveTab("notaire-mariage");
                break;
            case "/app/profil":
                setCurrentActiveTab("profil");
                break;
            default:
                setCurrentActiveTab("dashboard");
        }
    }, [location.pathname]);

    

    const getSidebar = () => {
        if (!data || !data.demarche) return null;

        const navigateTo = (tab) => {
            setCurrentActiveTab(tab);
            navigate(`/app/${tab}`);
        };

        const renderSidebarContent = () => {
            switch (data.demarche) {
                case "transaction":
                    return (
                        <>

                            <Link to="/app/dashboard" className='no-underline'>
                                <SidebarItem className={currentActiveTab === "dashboard" ? "!bg-indigo-600" : ""}>
                                    <HomeIcon />
                                    <SidebarLabel>Tableau de bord</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            <Link to="/app/bien" className='no-underline'>
                                <SidebarItem className={currentActiveTab === "bien" ? "!bg-indigo-600" : ""}>
                                    <BuildingOfficeIcon />
                                    <SidebarLabel>Bien</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            <Link to="/app/notaire" className='no-underline'>
                                <SidebarItem className={currentActiveTab === "notaire" ? "!bg-indigo-600" : ""}>
                                    <UserIcon />
                                    <SidebarLabel>Notaire</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            <Link to="/app/interlocuteurs" className='no-underline'>
                                <SidebarItem className={currentActiveTab === "interlocuteurs" ? "!bg-indigo-600" : ""}>
                                    <UsersIcon />
                                    <SidebarLabel>Contacts</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            <Link to="/app/documents" className='no-underline'>
                                <SidebarItem className={currentActiveTab === "documents" ? "!bg-indigo-600" : ""}>
                                    <DocumentIcon />
                                    <SidebarLabel>Documents</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            <Link to="/app/message" className='no-underline'>
                                <SidebarItem className={currentActiveTab === "message" ? "!bg-indigo-600" : ""}>
                                    <ChatBubbleLeftRightIcon />
                                    <SidebarLabel>Messagerie</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            
                        </>
                    );

                case "donation":
                    return (
                        <>

                            <Link to="/app/dashboard-donation" className='no-underline'>
                                <SidebarItem
                                    onClick={() => navigateTo("dashboardDonation")}
                                    className={currentActiveTab === "dashboardDonation" ? "!bg-indigo-600" : ""}
                                >
                                    <HomeIcon />
                                    <SidebarLabel>Tableau de bord</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            <Link to="/app/document-donation" className='no-underline'>
                                <SidebarItem
                                    onClick={() => navigateTo("documentDonation")}
                                    className={currentActiveTab === "documentDonation" ? "!bg-indigo-600" : ""}
                                >
                                    <DocumentIcon />
                                    <SidebarLabel>Documents</SidebarLabel>
                                </SidebarItem>
                            </Link>
                        </>
                    );

                case "pma":
                    return (
                        <>
                        
                            <Link to="/app/dashboard-pma" className='no-underline'>
                                <SidebarItem
                                    onClick={() => navigateTo("dashboard-pma")}
                                    className={currentActiveTab === "dashboard-pma" ? "!bg-indigo-600" : ""}
                                >
                                    <HomeIcon />
                                    <SidebarLabel>Tableau de bord</SidebarLabel>
                                </SidebarItem>
                            </Link>

                            <Link to="/app/notaire-pma" className='no-underline'>
                                <SidebarItem
                                    onClick={() => navigateTo("notaire-pma")}
                                    className={currentActiveTab === "notaire-pma" ? "!bg-indigo-600" : ""}
                                >
                                    <UserCircleIcon />
                                    <SidebarLabel>Votre notaire</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            
                            <Link to="/app/document-pma" className='no-underline'>
                                <SidebarItem
                                    onClick={() => navigateTo("document-pma")}
                                    className={currentActiveTab === "documentPma" ? "!bg-indigo-600" : ""}
                                >
                                    <DocumentIcon />
                                    <SidebarLabel>Documents</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            <Link to="/app/message" className='no-underline'>
                                <SidebarItem className={currentActiveTab === "message" ? "!bg-indigo-600" : ""}>
                                    <ChatBubbleLeftRightIcon />
                                    <SidebarLabel>Messagerie</SidebarLabel>
                                </SidebarItem>
                            </Link>
                        </>
                    );

                case "mariage":
                    return (
                        <>
                            <Link to="/app/dashboard-mariage" className="no-underline">
                                <SidebarItem>
                                    <HomeIcon />
                                    <SidebarLabel>Tableau de bord</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            <Link to="/app/notaire-mariage" className="no-underline">
                                <SidebarItem>
                                    <HomeIcon />
                                    <SidebarLabel>Votre notaire</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            <Link to="/app/document-mariage" className="no-underline">
                                <SidebarItem>
                                    <DocumentIcon />
                                    <SidebarLabel>Documents</SidebarLabel>
                                </SidebarItem>
                            </Link>
                            <Link to="/app/message" className='no-underline'>
                                <SidebarItem className={currentActiveTab === "message" ? "!bg-indigo-600" : ""}>
                                    <ChatBubbleLeftRightIcon />
                                    <SidebarLabel>Messagerie</SidebarLabel>
                                </SidebarItem>
                            </Link>
                        </>
                    ); 
            }
        };

        return (
            <Sidebar>
                <SidebarHeader>
                    <Dropdown>
                        <DropdownButton as={SidebarItem} className="mb-2.5">
                            <Avatar src={icnLogo} alt="Clotere" />
                            <SidebarLabel>Clotere</SidebarLabel>
                        </DropdownButton>
                    </Dropdown>
                    {/** affichage du bouton de switch affaire */}
                    {userData && transactions && transactions.length > 1 && (
                        <div className="z-50 relative inline-block text-left mb-4">
                            <p className='text-md font-bold'>Sélectionner votre affaire</p>
                            <div>
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-between rounded-md border border-gray-300 bg-emerald-600 px-4 py-2 text-sm font-medium text-white hover:text-white shadow-sm hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                >
                                    {transactions[selectedTransactionIndex]?.nom || `Transaction ${selectedTransactionIndex + 1}`}
                                    <svg
                                        className="-mr-1 ml-2 h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>

                            {isDropdownOpen && (
                                <div className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="py-1">
                                        {transactions.map((transaction, index) => (
                                            <button
                                                key={index}
                                                onClick={() => {
                                                    loadTransactionData(index, transactions, userData);
                                                    setIsDropdownOpen(false);
                                                }}
                                                className={`block w-full text-left px-4 py-2 text-sm ${selectedTransactionIndex === index
                                                        ? "bg-emerald-600 text-white"
                                                        : "text-gray-700 hover:bg-emerald-100"
                                                    }`}
                                            >
                                                {transaction.nom || `Transaction ${index + 1}`}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </SidebarHeader>
                <SidebarBody>
                    <SidebarSection>{renderSidebarContent()}</SidebarSection>
                    <SidebarSpacer />
                </SidebarBody>
                <SidebarFooter>
                    <Dropdown>
                        <DropdownButton as={SidebarItem}>
                            <span className="flex min-w-0 items-center gap-3">
                                <span className="min-w-0">
                                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{data.user.prenom}</span>
                                    <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">{data.user.email}</span>
                                </span>
                            </span>
                            <ChevronUpIcon />
                        </DropdownButton>
                        <DropdownMenu className="min-w-64" anchor="top start">
                            <DropdownItem
                                onClick={() => navigateTo("profil")}
                            >
                                <UserCircleIcon />
                                <DropdownLabel>Profil</DropdownLabel>
                            </DropdownItem>

                            <DropdownDivider />
                            <DropdownItem onClick={() =>
                                memberstack.logout()
                                    .then(({ data, type }) => {
                                        window.location.replace('/');
                                    })}>
                                <ArrowRightStartOnRectangleIcon />
                                <DropdownLabel>Se deconnecter</DropdownLabel>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </SidebarFooter>

            </Sidebar>
        );
    };

    const getContent = () => {
        if (!data) return <Loading />;

        return (
            <TabContent activeTab={currentActiveTab}>
                {data.demarche === "transaction" && (
                    <>
                        <TabPane tabId="dashboard">
                            <Dashboard {...data} />
                        </TabPane>
                        <TabPane tabId="bien">
                            <Bien user={data.user} bien={data.bien} />
                        </TabPane>
                        <TabPane tabId="notaire">
                            <Notaire user={data.user} transaction={data.transaction} evenement={data.evenement} />
                        </TabPane>
                        <TabPane tabId="documents">
                            <Documents user={data.user} transaction={data.transaction} document={data.document} />
                        </TabPane>
                        <TabPane tabId="interlocuteurs">
                            <Interlocuteurs user={data.user} transaction={data.transaction} />
                        </TabPane>
                        <TabPane tabId="profil">
                            <Profil user={data.user} transaction={data.transaction} />
                        </TabPane>
                        <TabPane tabId="message">
                            <Message user={data.user} affaire={data.transaction}  />
                        </TabPane>

                    </>
                )}

                {data.demarche === "donation" && (
                    <>
                        <TabPane tabId="dashboard-donation">
                            <DashboardDonation donation={data.donation} user={data.user} action={data.action} evenement={data.evenement} />
                        </TabPane>
                        <TabPane tabId="document-donation">
                            <DocumentsDonation user={data.user} />
                        </TabPane>
                        <TabPane tabId="profil">
                            <Profil user={data.user} transaction={data.transaction} />
                        </TabPane>
                    </>
                )}

                {data.demarche === "pma" && (
                    <>
                        <TabPane tabId="dashboard-pma">
                            <DashboardPma pma={data.pma} user={data.user} action={data.action} rdv={data.rdv} evenement={data.evenement} />
                        </TabPane>
                        <TabPane tabId="notaire-pma">
                            <NotairePma user={data.user} pma={data.pma} evenement={data.evenement} />
                        </TabPane>
                        <TabPane tabId="document-pma">
                            <DocumentsPma pma={data.pma} user={data.user} document={data.document} />
                        </TabPane>
                        <TabPane tabId="message">
                            <Message user={data.user} affaire={data.pma}  />
                        </TabPane>
                        <TabPane tabId="profil">
                            <Profil user={data.user}  />
                        </TabPane>
                    </>
                )}

                {data.demarche === "mariage" && (
                    <>
                        <TabPane tabId="dashboard-mariage">
                            <DashboardMariage mariage={data.mariage} user={data.user} action={data.action} rdv={data.rdv} evenement={data.evenement} />
                        </TabPane>
                        <TabPane tabId="notaire-mariage">
                            <NotaireMariage user={data.user} mariage={data.mariage} evenement={data.evenement} />
                        </TabPane>
                        <TabPane tabId="document-mariage">
                            <DocumentsMariage mariage={data.mariage} user={data.user} document={data.document} />
                        </TabPane>
                        <TabPane tabId="message">
                            <Message user={data.user} affaire={data.mariage}  />
                        </TabPane>
                        <TabPane tabId="profil">
                            <Profil user={data.user} transaction={data.transaction} />
                        </TabPane>
                    </>
                )}
            </TabContent>
        );
    };

    

    //console.log("data", data)
    return (

        <SidebarLayout sidebar={getSidebar()} navbar={<Navbar />}>
            {getContent()}
        </SidebarLayout>
    );
}

export default Layout;