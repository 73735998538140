
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useParams } from "react-router";

function Inventaire() {
    const navigate = useNavigate();
    const { id_action } = useParams();

    const [formData, setFormData] = useState({
        nom: "",
        type: "",
        valeur: "",
    });

    const [enregistrements, setEnregistrements] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmitInventaire = (e) => {
        e.preventDefault();
        if (formData.nom && formData.type && formData.valeur) {
            setEnregistrements((prev) => [...prev, formData]);
            setFormData({ nom: "", type: "", valeur: "" }); // Reset du formulaire
        } else {
            alert("Veuillez remplir tous les champs !");
        }
    };

    const handleDelete = (index) => {
        setEnregistrements((prev) => prev.filter((_, i) => i !== index));
    };

    const validationInventaire = async () => {

        const UpdateStatutInventaire = async () => {

            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/action/${id_action}`;
            //console.log("🔗 URL API :", URL);

            const body = {
                "fields": {
                    "statut": "fait",
                }
            };
            //console.log("📦 Body envoyé :", JSON.stringify(body, null, 2));

            try {
                const response = await fetch(URL, {
                    method: "PATCH",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(body)
                });

                const responseData = await response.json();
                console.log("✅ Réponse API :", responseData);

            } catch (error) {
                console.error("❌ Erreur fetch:", error);
            }
        };


        const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/2f30r50/"; // 👈 Specify your Zapier Webhook URL here
        try {
            const response = await fetch(zapierURL, {
                method: "POST",
                mode: "cors",
                body: JSON.stringify({ id_action, enregistrements })
            });
            const resp = await response.json();
            console.log(resp);
            if (resp.status === "success") {
                UpdateStatutInventaire();
                setTimeout(() => navigate("/app/dashboard"), 1000);
                
            }
        } catch (e) {
            console.log(e);
        }
    };


    //console.log("id_action", id_action);
    //console.log("enregistrements", enregistrements);

    return (

        <div className='container'>
            <Link to="/app/dashboard" className="mt-8 d-flex flex-col no-underline text-slate-800">
                <p className="m-0 inline-flex"><ArrowLeftIcon className='size-6 flex' /> {"        "} Retour</p>
            </Link>
            <div className='mt-10'>
                <h3 className="mt-3">Créez votre inventaire</h3>
                <p>Listez les biens mobiliers ou immobiliers de votre patrimoine. Pas d'iquiétude vous pourrez revenir sur ces informations avec votre notaire.</p>
            </div>

            <div className=''>
                <form onSubmit={handleSubmitInventaire}>
                    <div className="flex flex-row w-full gap-3">

                        <div className="flex-1">
                            <label htmlFor="" className="block text-sm/6 font-medium text-gray-900">
                                Nom du bien
                            </label>
                            <input
                                name="nom"
                                value={formData.nom}
                                onChange={handleChange}
                                className="input-clotere"
                            />
                        </div>

                        <div className="flex-1">
                            <div className='flex flex-col'>
                                {/* Label avec espace sous le texte */}
                                <label htmlFor="" className="block text-sm font-medium text-gray-900 mb-2">
                                    Type de bien:
                                </label>

                                {/* Conteneur des boutons radio */}
                                <div className="flex space-x-4 justify-stretch">
                                    <label className="cursor-pointer w-full">
                                        <input
                                            type="radio"
                                            name="type"
                                            value="mobilier"
                                            checked={formData.type === "mobilier"}
                                            onChange={handleChange}
                                            className="hidden"
                                        />
                                        <span
                                            className={`radio-button inline-block px-4 py-3 rounded-lg border cursor-pointer text-sm transition
                    ${formData.type === "mobilier"
                                                    ? "bg-emerald-500 text-white border-emerald-500"
                                                    : "bg-white text-gray-700 border-gray-300"
                                                }`}
                                        >
                                            Mobilier
                                        </span>
                                    </label>

                                    <label className="cursor-pointer w-full">
                                        <input
                                            type="radio"
                                            name="type"
                                            value="immobilier"
                                            checked={formData.type === "immobilier"}
                                            onChange={handleChange}
                                            className="hidden"
                                        />
                                        <span
                                            className={`radio-button inline-block px-4 py-3 rounded-lg border cursor-pointer text-sm transition
                    ${formData.type === "immobilier"
                                                    ? "bg-emerald-500 text-white border-emerald-500"
                                                    : "bg-white text-gray-700 border-gray-300"
                                                }`}
                                        >
                                            Immobilier
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="flex-1">
                            <label htmlFor="" className="block text-sm/6 font-medium text-gray-900">
                                Valeur du bien
                            </label>
                            <input
                                name="valeur"
                                value={formData.valeur}
                                onChange={handleChange}
                                placeholder="250€"
                                className="input-clotere"
                            />
                        </div>
                        <div className="flex-1">
                            <button className="btn-main mt-6" type="submit">Ajouter</button></div>
                    </div>

                </form>
            </div>
            <div className='mt-10'>
                {/* Affichage du tableau des enregistrements */}
                <h3 className="mt-3 text-base font-semibold text-gray-900">Liste de vos biens</h3>

                <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Nom
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Type
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Valeur
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {enregistrements.map((item, index) => (
                                <tr key={index}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {item.nom}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.type}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.valeur}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <button
                                            onClick={() => handleDelete(index)}
                                            className="text-sm bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                                        >
                                            Supprimer
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <button className='btn-main mt-8' onClick={validationInventaire}>Valider votre inventaire</button>
            </div>
        </div>
    );
}

export default Inventaire;