import React from "react";
import { Container } from "./Container";
import Pourquoi2 from "../img/pourquoi-clotere-2.webp"
import Notaire from "../img/notaire-clotere.webp"
import Support from "../img/support-clotere.webp"
import { CheckIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";



function PourquoiClotereNew2(args) {
  return (
    <>
      <Container>
        <div className="relative isolate overflow-hidden px-6  lg:overflow-visible lg:px-0">
          <p className="mt-2 mb-10 leading-[60px] text-5xl font-semibold tracking-tight text-gray-900 text-center">
            Nous vous fournissons gratuitement tout ce dont vous avez besoin chez le notaire</p>

            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 mt-28 mb-28">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 items-center">
              
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <h2 className="title-clotere">Un notaire qualifié et réactif pour votre dossier</h2>
                  <p className="mt-6 text-lg leading-8 text-gray-700">
                  </p>
                  <ul className="pl-2 gap-2 flex flex-col mb-0">
                    <li className="text-lg flex items-center gap-2">
                      <CheckIcon className="size-4" />
                      A proximité ou à distance c'est vous qui choisissez<br />
                    </li>
                    <li className="text-lg flex items-center gap-2">
                      <CheckIcon className="size-4" />
                      Tout vos échanges au même endroit<br />
                    </li>
                    <li className="text-lg flex items-center gap-2">
                      <CheckIcon className="size-4" />
                      Notaires partenaires spécialisés en immobilier<br />
                    </li>
                    <li className="text-lg  flex items-center gap-2">
                      <CheckIcon className="size-4" />
                      Réactifs et connectés<br />
                    </li>
                  </ul>
                  <div className="mb-4">
                    <Link to="/onboard/achat"
                      type="button"
                      className="box-content mt-3 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Débutez maintenant, c'est gratuit
                      <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                    </Link>
                  </div>

                </div>
              </div>
              <div className="lg:pr-4">
                <img src={Notaire} alt="clotere" />
              </div>
            </div>
          </div>

          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 mt-28">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 items-center">
              
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <h2 className="title-clotere">Constitution de votre dossier en ligne</h2>
                  <p className="mt-6 text-lg leading-8 text-gray-700">
                  </p>
                  <ul className="pl-2 gap-2 flex flex-col mb-0">
                    <li className="text-lg flex items-center gap-2">
                      <CheckIcon className="size-4" />
                      Obtenez la liste des documents nécessaires à votre dossier<br />
                    </li>
                    <li className="text-lg flex items-center gap-2">
                      <CheckIcon className="size-4" />
                      Contrôle de vos documents dès l'ajout<br />
                    </li>
                    <li className="text-lg flex items-center gap-2">
                      <CheckIcon className="size-4" />
                      Suivi de votre dossier depuis votre espace<br />
                    </li>
                    <li className="text-lg  flex items-center gap-2">
                      <CheckIcon className="size-4" />
                      Prise en charge immédiate de votre dossier<br />
                    </li>
                  </ul>
                  <div className="mb-4">
                    <Link to="/onboard/achat"
                      type="button"
                      className="box-content mt-3 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Débutez maintenant, c'est gratuit
                      <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                    </Link>
                  </div>

                </div>
              </div>
              <div className="lg:pr-4">
                <img src={Pourquoi2} alt="clotere" />
              </div>
            </div>
          </div>

          

          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 mt-28">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 items-center">
              
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <h2 className="title-clotere">Support & Accompagnement en continu</h2>
                  <p className="mt-6 text-lg leading-8 text-gray-700">
                  </p>
                  <ul className="pl-2 gap-2 flex flex-col mb-0">
                    <li className="text-lg flex items-center gap-2">
                      <CheckIcon className="size-4" />
                      Un conseiller Clotere dédié à votre dossier<br />
                    </li>
                    <li className="text-lg flex items-center gap-2">
                      <CheckIcon className="size-4" />
                      Assistance juridique illimité<br />
                    </li>
                    <li className="text-lg flex items-center gap-2">
                      <CheckIcon className="size-4" />
                      Echanges et support Whatsapp 24/24<br />
                    </li>
                  </ul>
                  <div className="mb-4">
                    <Link to="/onboard/achat"
                      type="button"
                      className="box-content mt-3 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Débutez maintenant, c'est gratuit
                      <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                    </Link>
                  </div>

                </div>
              </div>
              <div className="lg:pr-4">
                <img src={Support} alt="clotere" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default PourquoiClotereNew2;

