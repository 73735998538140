import React from "react";
import Pourquoi2 from "../img/pourquoi-clotere-2.webp"
import Notaire from "../img/notaire-clotere.webp"
import Support from "../img/support-clotere.webp"
import Rdv from "../img/rdv-notaire.png"
import { CheckIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import BackHeaderBottom from "./BackHeaderBottom";



function PourquoiClotereNew3(args) {
  return (
    <>
    <div className="relative">
      <div className="container">
      
        <div className="relative isolate overflow-hidden px-6  lg:overflow-visible lg:px-0">
          <p className="mt-2 sm:mb-10 sm:leading-[60px] text-xl sm:text-3xl font-semibold tracking-tight text-gray-900 text-center">
          Tout ce qu’il vous faut pour un passage chez le notaire sans stress !</p>
          
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 sm:mt-28 sm:mb-28">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 items-center">
              
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <h2 className="title-clotere">1. Choisissez votre notaire</h2>
                  <ul className="pl-2 gap-2 flex flex-col mb-0 mt-4">
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      A proximité ou à distance c'est vous qui choisissez<br />
                    </li>
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      Prise en charge immédiate de votre dossier<br />
                    </li>
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      Notaires partenaires réactifs et connectés<br />
                    </li>
                  </ul>
                  <div className="mt-4 mb-4">
                    <Link to="/onboard/empty"
                      type="button"
                      className="box-content no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Débutez maintenant
                      <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                    </Link>
                  </div>

                </div>
              </div>
              <div className="lg:pr-4">
                <img src={Notaire} alt="clotere" className="sm:w-full w-[70%] mx-auto" />
              </div>
            </div>
          </div>

          <div className="relative mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 sm:mt-28 mt-10">
            
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 items-center">
              
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <h2 className="title-clotere">2. Constituez votre dossier</h2>
                  <ul className="pl-2 gap-2 flex flex-col mb-0 mt-4">
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      Obtenez la liste des documents nécessaires à votre dossier<br />
                    </li>
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      Contrôle de vos documents dès l'ajout<br />
                    </li>
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      Suivi de votre dossier depuis votre espace<br />
                    </li>
                  </ul>
                  <div className="mb-4 mt-4">
                    <Link to="/onboard/empty"
                      type="button"
                      className="box-content no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Débutez maintenant
                      <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                    </Link>
                  </div>

                </div>
              </div>
              <div className="lg:pr-4">
                <img src={Pourquoi2} alt="clotere trouvez votre notaire" className="sm:w-full w-[70%] mx-auto"  />
              </div>
            </div>
          </div>

          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 sm:mt-28 mt-10">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 items-center">
              
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <h2 className="title-clotere">3. Obtenez vos actes notariés </h2>
                  <ul className="pl-2 gap-2 flex flex-col mb-0 mt-4">
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      Fixez vos rendez-vous de signature en 3 clis<br />
                    </li>
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      En présentiel ou à distance c'est vous qui choisissez<br />
                    </li>
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      Obtenez simplement vos actes notariés<br />
                    </li>
                  </ul>
                  <div className="mb-4 mt-4">
                    <Link to="/onboard/empty"
                      type="button"
                      className="box-content no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Débutez maintenant
                      <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                    </Link>
                  </div>

                </div>
              </div>
              <div className="lg:pr-4">
                <img src={Rdv} alt="votre notaire en ligne" className="sm:w-full w-[70%] mx-auto"  />
              </div>
            </div>
          </div>

          

          <div className="bg-slate-200 p-6 rounded-lg mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 sm:mt-28 mt-10">
          
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 items-center">
           
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                <h2 className="title-clotere">On vous accompagne de A à Z avec un support sans faille</h2>
                  <ul className="pl-2 gap-2 flex flex-col mb-0 mt-4">
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      Un conseiller Clotere dédié à votre dossier<br />
                    </li>
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      Assistance juridique illimité<br />
                    </li>
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      Echanges et support Whatsapp 24/24<br />
                    </li>
                    <li className="text-lg flex items-center gap-2 text-gray-500 font-semibold">
                      <CheckIcon className="size-4" />
                      Des guides et articles sans jargon juridique pour mieux comprendre !<br />
                    </li>
                  </ul>

                </div>
              </div>
              <div className="lg:pr-4">
                <img src={Support} alt="clotere" className="sm:w-full w-[70%] mx-auto"  />
              </div>
            </div>
          </div>
        </div>
        <BackHeaderBottom/>
      </div>
      </div>
    </>
  );
}

export default PourquoiClotereNew3;

