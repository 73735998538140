'use client'
import React, { useState, useEffect } from "react";
import { useMemberstack } from "@memberstack/react";
import {
  Disclosure,
  DisclosurePanel
} from '@headlessui/react'
import {  PhoneIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom';
import Logo from './img/logo-clotere.svg'
import LogoMobile from './img/icn-logo-noir.svg'
import { PlusGrid, PlusGridItem, PlusGridRow } from './annuaire/Plus-grid'
import { Container } from "./annuaire/Container";


function DesktopNav() {
  const memberstack = useMemberstack();
  const [member, setMember] = useState(null);

  useEffect(() => {
    if (member === null) {
      memberstack.getCurrentMember()
        .then(({ data: member }) => setMember(member))
    }
  }, []);

  return (
    <nav className="relative hidden lg:flex">
      <PlusGridItem className="relative flex">
      <p className="flex items-center px-4 py-3 m-0 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]">
      Ref{" "} <b>{" "} F-6577669</b>
      </p>
        <p className="flex items-center px-4 py-3 m-0 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]">
      <span role="img">👋</span>{" "}Besoin d'aide ? 
      </p>
      <a
      href="tel:0757831364"
          target="blank"
        type="button"
        className="no-underline inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-0 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PhoneIcon aria-hidden="true" className="-ml-0.5 size-5" />
        01 76 39 00 60
           (appel gratuit)
      </a>
        
      </PlusGridItem>
    </nav>
  )
}

function MobileNavButton() {
  return (
    <>
    <div className="md:hidden flex flex-col gap-6 py-4">
            <PlusGridItem className="relative flex">
            <p className="flex items-center px-4 py-3 m-0 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]">
      Ref{" "} <b>{" "} F-6577669</b>
      </p>
      <a
      href="tel:0757831364"
          target="blank"
        type="button"
        className="no-underline inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-0 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PhoneIcon aria-hidden="true" className="-ml-0.5 size-5" />
        01 76 39 00 60
           (appel gratuit)
      </a>
      </PlusGridItem>
      </div>
    </>
  )
}

function MobileNav() {
  return (
    <DisclosurePanel className="lg:hidden">
      <div className="flex flex-col gap-6 py-4">
            <PlusGridItem className="relative flex">
        <p className="flex items-center px-4 py-3 m-0 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]">
      <span role="img">👋</span> {" "}Besoin d'aide ? 
      </p>
      <a
      href="tel:0757831364"
          target="blank"
        type="button"
        className="no-underline inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-0 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PhoneIcon aria-hidden="true" className="-ml-0.5 size-5" />
        01 76 39 00 60
           (appel gratuit)
      </a>
      </PlusGridItem>
      </div>
      <div className="absolute left-1/2 w-screen -translate-x-1/2">
        <div className="absolute inset-x-0 top-0 border-t border-black/5" />
        <div className="absolute inset-x-0 top-2 border-t border-black/5" />
      </div>
    </DisclosurePanel>
  )
}

export function NavbarOnboard({ banner }) {
  return (
    <Container>
    <Disclosure as="nav">
      <PlusGrid>
        <PlusGridRow className="relative flex justify-between items-center">
          <div className="relative flex gap-6">
            <PlusGridItem className="py-3">
              <Link to="/" title="Home">
                <img src={Logo} alt="clotere" className="h-10 sm:flex hidden"/>
                <img src={LogoMobile} alt="clotere" className="h-10 sm:hidden"/>
              </Link>
            </PlusGridItem>
            
          </div>
          <DesktopNav />
          <MobileNavButton />
        </PlusGridRow>
      </PlusGrid>
      <MobileNav />
    </Disclosure>
    </Container>
  )
}
