import React from "react";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { Helmet } from "react-helmet";

const items = [
    {
        id: 1,
        name: 'Vérification de la disponibilité des notaires',
        icon: <CheckBadgeIcon className="size-8 text-indigo-800" />
    },
    {
        id: 2,
        name: 'Proposition de profil sous 24h',
        icon: <CheckBadgeIcon className="size-8 text-indigo-800" />
    },
    {
        id: 3,
        name: 'Prise en charge de votre dossier',
        icon: <CheckBadgeIcon className="size-8 text-indigo-800" />
    },
    // More items...
]

function PageSuccessLeadMariage() {


    return (
        <>
            <div className="relative isolate  px-6 py-24 sm:py-32 lg:px-8">
            <div
                    aria-hidden="true"
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    />
                </div>
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base/7 font-semibold text-indigo-600">👍</h2>
                    <p className="mt-2 text-3xl font-semibold tracking-tight text-balance text-gray-900 ">
                        Nous avons des notaires disponibles pour votre dossier
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg font-medium text-pretty text-gray-600 sm:text-xl/8">
                    Nous les consultons et vous revenons vers vous très rapidement.
                </p>
                <div className="text-center">
                    <ul  className="mt-12 divide-y divide-gray-200 mx-auto sm:max-w-3xl text-center gap-2 flex flex-col">
                        {items.map((item) => (
                            <li key={item.id} className="flex gap-x-4 p-3 bg-slate-50 rounded-xl border-2  items-center">
                                {item.icon}
                                <div className="flex-auto">
                                    <div className="flex items-baseline justify-between gap-x-4">
                                        <p className="text-sm/6 font-semibold text-gray-900 m-0">{item.name}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="mt-10 text-center flex flex-col justify-center items-center gap-2">
                        <div class="flex flex-col justify-center gap-3 mt-5">
                            <div className="flex -space-x-2 overflow-hidden">
                                <img
                                    alt="trouver mon notaire acheteur"
                                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="notaire pour votre achat immobilier"
                                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="trouver mon notaire"
                                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="trouver un notaire"
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                            </div>

                        </div>
                        <p className="mt-4">Nos experts sont là pour vous aider, par chat ou au <a href="tel:0176390060" className="text-emerald-600 font-bold">01 76 39 00 60</a> .</p>

                    </div>

                </div>
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    />
                </div>

            </div>

        </>

    );
}

export default PageSuccessLeadMariage;