import React from "react";
import {Row,
  Col,
  ListGroup,
} from "reactstrap";
import {
  TitlePage,
  TitleSection,
} from "../../style/Layout";
import TimelineListItem from "../../components/TimelineListItem";
import Articles from "../../components/Articles";
import CardHelp from "../../components/CardHelp";
import styled from "styled-components";
import CardNotaireDashboard from '../../components/CardNotaireDashboard';
import RendezVousNotaire from "../../components/RendezVousNotaire";
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

function Dashboard(args) {
  console.log("args dahboasrd pma", args);

  return (
    <>
      <div className="container">
        {/** title */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div md="7">
              <TitlePage>Bonjour {args.user.prenom} 👋</TitlePage>
              <p>
                Voici l'avancement de votre démarche chez le notaire
              </p>
            </div>
            <ul role="list" className="grid grid-cols-1" >
                  <li  className="col-span-1 flex rounded-md shadow-xs">
                    <div className="flex w-16 shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white bg-emerald-400">
                      PMA
                    </div>
                    <div className="sm:p-0 p-6 flex flex-1 flex-col sm:flex-row items-center sm:gap-12 justify-center truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                      
                       
                        <p className="text-gray-800 m-0">
                        {args.pma.type === "seule" && <> F. seule</>}
                        {args.pma.debut === "couplehetero" && <> Couple</>}
                        {args.pma.debut === "couplehomo" && <> Couple homosexuel</>}
                        </p>
                        /
                        <p className="text-gray-800 m-0">
                        {args.pma.debut === "oui" && <> Processus de PMA en cours</>}
                        {args.pma.debut === "non" && <> Processus de PMA non débuté</>}
                        </p>
                    </div>
                  </li>
              </ul>
        </div>

          <div className="mt-3">

          {/**  Composant de prise de rdv classique avec son notaire */}
          <section>
            
            {args.rdv && args.rdv.length > 0 && (
            (() => {
              const rdvNotaire = args.rdv.find(r => r.nom === "Rendez-vous notaire");
              
              return rdvNotaire ? (

                <RendezVousNotaire rdvNotaire={rdvNotaire} id_affaire={args.pma.id} type="pma"/>
                
              ) : null;
            })()
          )}
          </section>
            

            {/**Informations sur l'attribution de l'affaire au notaire */}
            <section>
            {args.pma.statut_marketplace_notaire === "en recherche de notaire" &&
              <>
              <div className="rounded-md bg-blue-50 p-4">
            <div className="flex items-center">
              <div className="shrink-0">
                <InformationCircleIcon aria-hidden="true" className="size-6 text-blue-400" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between items-center">
                <p className="text-lg text-blue-700 m-0">Deux notaires sont disponibles pour votre dossier</p>
                  <Link to={`/app/notaires/pma/${args.pma.id}`} className="btn-main no-underline">
                    Choisissez votre notaire
                  </Link>
              </div>
            </div>
          </div>
          
                
              </>
            }

            {args.pma.statut_marketplace_notaire === "vérification avant attribution" &&
              <>
                <p className='mt-1 text-lg text-gray-500'><span role="img">👋</span> Votre dossier a bien été pris en charge par votre notaire.</p>
                <CardNotaireDashboard nom={args.pma.nom_from_notaire} prenom={args.pma.prenom_from_notaire} email={args.pma.email_from_notaire} telephone={args.pma.telephone_from_notaire} adresse={args.pma.adresse_from_notaire} cp={args.pma.code_postal_from_notaire} ville={args.pma.ville_from_notaire} photo={args.pma.photo_profil_from_notaire} />

              </>
            }
            {args.pma.statut_marketplace_notaire === "notaire trouvé" &&
              <>
                <p className='mt-1 text-lg text-gray-500'><span role="img">👋</span> Votre dossier a bien été pris en charge par votre notaire.</p>
                <CardNotaireDashboard nom={args.pma.nom_from_notaire} prenom={args.pma.prenom_from_notaire} email={args.pma.email_from_notaire} telephone={args.pma.telephone_from_notaire} adresse={args.pma.adresse_from_notaire} cp={args.pma.code_postal_from_notaire} ville={args.pma.ville_from_notaire} photo={args.pma.photo_profil_from_notaire} />

              </>
            }
            </section>

            {/** action à mener**/}
            {args.action !== undefined && args.action.length > 0 &&
              <>
              <section className="mt-5">
                <TitleSection>Vos actions à mener</TitleSection>
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="p-2">
                  <ListGroupActionAmener numbered>
                    {args.action.map((col, i) => (
                      <>
                        {args.action[i].nom === "Questionnaire état civil" &&
                          <>
                            <TimelineListItem
                              type="etatCivilPma"
                              statut={args.action[i].statut}
                              action={args.action[i]}
                            />
                          </>
                        }
                        {args.action[i].nom === "Ajout document" &&
                          <>
                          <TimelineListItem
                              type="documentPma"
                              statut={args.action[i].statut}
                            />
                          </>
                        }
                        {args.action[i].nom === "Paiement prestation" &&
                          <>
                          <TimelineListItem
                              type="paiementPma"
                              statut={args.action[i].statut}
                              prix={args.pma.prix}
                              typePma={args.pma.type}
                              lienPaiement={args.pma.stripe_lien_paiement}
                              email={args.user.email}
                            />
                          </>
                        }

                        {args.action[i].nom === "Lecture de vos obligations légales" &&
                          <>
                            <TimelineListItem
                              type="lectureObligationsLegales"
                              statut={args.action[i].statut}
                            />
                          </>
                        }

                      </>
                    ))}
                  </ListGroupActionAmener>
                  </div>
                  
                </div>
                </section>
              </>
            }

            {/** guide et ressources**/}
            <TitleSection className="mt-5">Nos guides et ressources pour vous aider à comprendre 🔥 <br /></TitleSection>
            <Articles type="pma" />
            <Row>
              <Col md="12" align="center" className="mt-4 mb-4"><a className="btn-main no-underline" color="primary" href="https://clotere.framer.website/?utm_source=clotere" target="blank">Tous nos articles</a></Col>
            </Row>


            {/** contacter son conseiller **/}
            <CardHelp email={args.user.email} />
          </div>
      </div>
    </>
  );

}

const ListGroupActionAmener = styled(ListGroup)`
  border-radius: 0;
  border: 0;
  .list-group-item {
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    &:before {
      content: counters(section, "") "";
      counter-increment: section;
      display: inline;
      border: 1px solid #ddd;
      font-weight: 500;
      border-radius: 100px;
      padding: 10px 17px;
      margin-right: 30px;
    }
    &.done {
      &:before {
        content: "✔️";
      }
    }
    h4 {
      color: #1d2b28;
    }
    color: #84847c;
  }
  @media all and (max-width: 768px) {
    .list-group-item {
      flex-direction: column;
      &:before {
        margin-bottom: 1rem;
      }
    }
  }
`;






export default Dashboard;
