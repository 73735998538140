import axios from "axios";

const AIRTABLE_BASE_ID = "appD48APNaGA4GN0B";  // Récupéré depuis Airtable API
const AIRTABLE_TABLE_NAME = "messages";
const AIRTABLE_API_KEY = "patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02"; // Récupéré sur Airtable API

const airtableUrl = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_TABLE_NAME}`;

const headers = {
  Authorization: `Bearer ${AIRTABLE_API_KEY}`,
  "Accept": "application/json",
    'content-type': "application/json"
};

// 📨 Récupérer les messages
export const fetchMessages = async () => {
  try {
    const response = await axios.get(`${airtableUrl}?sort[0][field]=timestamp&sort[0][direction]=asc`, { headers });
    return response.data.records.map((record) => ({
      id: record.id,
      sender: record.fields.sender,
      receiver: record.fields.receiver,
      content: record.fields.content,
      timestamp: record.fields.timestamp
    }));
  } catch (error) {
    console.error("Erreur lors de la récupération des messages:", error);
    return [];
  }
};

const sendToZapier = async (sender, receiver,content,affaire) => {
    const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/2lu307w"; // 👈 Specify your Zapier Webhook URL here
    try {
      const response = await fetch(zapierURL, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({sender,receiver,content,affaire})
      });
      const res = await response.json();
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };




// ✉️ Envoyer un message
export const sendMessage = async (sender, receiver, content,affaire) => {
  try {
    const newMessage = {
      fields: {
        sender,
        receiver,
        content,
        timestamp: new Date().toISOString(),
        read: false
      }
    };

    await axios.post(airtableUrl, newMessage, { headers });
    // Appeler le webhook Zapier
    sendToZapier(sender, receiver, content,affaire);

    return true;
  } catch (error) {
    console.error("Erreur lors de l'envoi du message:", error);
    return false;
  }
};