import React from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqsMariage = [
    {
        question: "Comment fonctionne notre service de contrat de mariage  ?",
        answer:
            "Notre service vous permet de rédiger et finaliser votre contrat de mariage entièrement en ligne, sans déplacement si vous le souhaitez. Vous remplissez un formulaire personnalisé, un notaire valide votre dossier et vous conseille, puis vous signez électroniquement votre contrat.",
    },
    {
        question: "Est-ce un vrai contrat de mariage validé par un notaire ?",
        answer:
            "Oui, tous nos contrats sont vérifiés et validés par un notaire certifié. Vous bénéficiez d’un encadrement juridique officiel et conforme à la législation en vigueur.",
    },
    {
        question: "Y a-t-il des frais supplémentaires après le paiement ?",
        answer:
            "Notre tarif couvre l’ensemble du processus, y compris la validation de votre contrat par un notaire. Toutefois, dans certains cas spécifiques, notamment lorsque des biens d’une valeur importante sont apportés au mariage, des frais supplémentaires peuvent être appliqués par le notaire en fonction de la législation en vigueur (ex : droits d’enregistrement, frais de publicité foncière, etc.).",
    },
    {
        question: "Puis-je obtenir un remboursement si je change d’avis ?",
        answer:
            "Selon notre politique, vous pouvez demander un remboursement tant que votre dossier n’a pas été transmis au notaire. Après cette étape, le paiement devient définitif.",
    },
    {
        question: "Comment contacter votre support en cas de problème ?",
        answer:
            "Notre équipe est disponible pour répondre à vos questions par email (contact@clotere.fr), par WhatsApp, ou directement via notre chat en ligne.",
    },
    
    
    // More questions...
]

const faqsPma = [
    {
        question: "Comment fonctionne notre service de démarche notariales dans le cadre d'une PMA ?",
        answer:
            "Notre service vous permet de constituer simplement votre dossier dans le cadre de vos démarches PMA, d'être mis en relation avec un notaire au fait de la procédure et réactif. Vous remplissez un formulaire personnalisé, un notaire valide votre dossier et vous conseille, puis vous signez vos documents de PMA.",
    },
    {
        question: "Y a-t-il des frais supplémentaires après le paiement ?",
        answer:
            "Aucun, notre tarif couvre l’ensemble du processus, y compris la validation de votre contrat par un notaire.",
    },
    {
        question: "Puis-je obtenir un remboursement si je change d’avis ?",
        answer:
            "Selon notre politique, vous pouvez demander un remboursement tant que votre dossier n’a pas été transmis au notaire. Après cette étape, le paiement devient définitif.",
    },
    {
        question: "Comment contacter votre support en cas de problème ?",
        answer:
            "Notre équipe est disponible pour répondre à vos questions par email (contact@clotere.fr), par WhatsApp, ou directement via notre chat en ligne.",
    },
]


function FaqOnboard({ type }) {
    return (
        <div className="mx-auto max-w-7xl pt-10">
            <div className="mx-auto max-w-4xl">
                <dl className="divide-y divide-gray-900/10">
                {type === "mariage" &&
                <>
                    {faqsMariage.map((faq) => (
                        <Disclosure key={faq.question} as="div" className="py-6 first:pt-0 last:pb-0">
                            <dt>
                                <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                    <span className="text-base/7 font-semibold">{faq.question}</span>
                                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="size-6 group-data-open:hidden" />
                      <MinusSmallIcon aria-hidden="true" className="size-6 group-not-data-open:hidden" />
                    </span>
                                </DisclosureButton>
                            </dt>
                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                <p className="text-base/7 text-gray-600">{faq.answer}</p>
                            </DisclosurePanel>
                        </Disclosure>
                    ))}
                </>}
                {type === "pma" &&
                <>
                    {faqsPma.map((faq) => (
                        <Disclosure key={faq.question} as="div" className="py-6 first:pt-0 last:pb-0">
                            <dt>
                                <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                    <span className="text-base/7 font-semibold">{faq.question}</span>
                                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="size-6 group-data-open:hidden" />
                      <MinusSmallIcon aria-hidden="true" className="size-6 group-not-data-open:hidden" />
                    </span>
                                </DisclosureButton>
                            </dt>
                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                <p className="text-base/7 text-gray-600">{faq.answer}</p>
                            </DisclosurePanel>
                        </Disclosure>
                    ))}
                </>}
                </dl>
            </div>
        </div>
    );
}

export default FaqOnboard;