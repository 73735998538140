import React, { useState } from "react";

function AppointmentModal() {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
        <div className="bg-indigo-100">
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
                <div>
                    <h2 className="max-w-2xl text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                        Vous souhaitez plus d'informations ?
                    </h2>
                    <div class="flex flex-col gap-3 mt-5">
                            <div className="flex -space-x-2 overflow-hidden">
                                <img
                                    alt="trouver mon notaire acheteur"
                                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="notaire pour votre achat immobilier"
                                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="trouver mon notaire"
                                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                                <img
                                    alt="trouver un notaire"
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="inline-block size-10 rounded-full ring-2 ring-white"
                                />
                            </div>

                        </div>
                    <p className='text-xl'>
                        Nos experts se feront un plaisir de répondre à toutes vos questions, n'hésitez pas à prendre rendez-vous.
                    </p>
                    
                </div>
                <button
                onClick={() => setModalOpen(true)}
                className="btn-indigo w-auto p-6"
            >
                Prendre un rendez-vous
            </button>
            </div>
        </div>
        {/* Modal */}
        {modalOpen && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg shadow-xl max-w-3xl w-full p-6 transform scale-100 transition-all">
                        <h2 className="text-lg font-bold text-gray-800 mb-4 text-center">
                            Prendre un rendez-vous
                        </h2>

                        {/* Iframe Google Calendar */}
                        <div className="relative overflow-hidden rounded-lg">
                            <iframe
                                src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2N3sMiWCWTD3GWAp9oN-FoJEdzm42kuKNeR0ZTFdXc1V4UG5DSezuPQXlFiGHvzrwOZKPcJbjL"
                                className="w-full h-[500px] border-none rounded-lg"
                                title="Google Calendar Appointments"
                            />
                        </div>

                        {/* Bouton pour fermer la modal */}
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={() => setModalOpen(false)}
                                className="btn-primary transition"
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>

    );
}

export default AppointmentModal;