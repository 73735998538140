import React, { useEffect, useState } from "react";
import { Dialog, Transition, DialogTitle, DialogPanel, DialogBackdrop } from "@headlessui/react";
import { Fragment } from "react";
import DescriptionDocument from "../../components/DescriptionDocument";
import DocumentModal from "../../components/DocumentModal"; // Import du composant
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { XCircleIcon, DocumentIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";

function Loader() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 animate-spin">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
      </svg>
    </>
  );
}
function Erreur() {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <XCircleIcon aria-hidden="true" className="size-5 text-red-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">Une erreur s'est produite veuillez recommencer</h3>
        </div>
      </div>
    </div>
  );
}


function Documents(args) {
  //upload doc
  const [storage, setStorage] = useState([]);
  const [file, setFile] = useState(null);
  const [urlFirebase, setUrlFirebase] = useState(null);
  const [loader, setLoader] = useState(false);
  const [erreurUpload, setErreurUpload] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [documentData, setDocumentData] = useState({ id_document: null, name_document: null });
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  //props ouverture visionneuse modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDocumentUrl, setCurrentDocumentUrl] = useState(""); // Stocke l'URL du document sélectionné
  // Fonction pour ouvrir la modal avec l'URL du document
  const openModal = (url) => {
    setCurrentDocumentUrl(url); // Définir l'URL du document
    setIsModalOpen(true); // Ouvrir la modal
  };


  const openDialog = (id, name) => {
    setDocumentData({ id_document: id, name_document: name });
    setOpenUpload(true);
  };

  const closeDialog = () => {
    setOpenUpload(false);
    setDocumentData({ id_document: null, name_document: null });
    setUrlFirebase(null);
    window.location.reload(true);
  };

  const openDelete = (id, name) => {
    setDocumentData({ id_document: id, name_document: name });
    setIsOpenDelete(true);
  };
  const closeDelete = () => {
    setDocumentData({ id_document: null, name_document: null });
    setIsOpenDelete(false);
    setUrlFirebase(null);
    window.location.reload(true);
  };

  //upload Doc
  const onUploadDoc = (event) => {
    console.log("onchangeupload", documentData);
    console.log(event.target.files[0].size);
    console.log(event.target.files[0].type);

    //controle 
    const fileSize = event.target.files[0].size;
    const fileMb = fileSize / 10024 ** 2;
    if (fileMb >= 2) { // 5 MB in bytes
      alert('Votre fichier est trop volumineux. Taille maximale 5MB.');
      event.target.value = ''; // clear the file input
    } else {
      const handleUpload = async () => {
        try {
          setLoader(true);
          // Upload du fichier sur firestore le temps de l'upload
          const storageRef = ref(storage, event.target.files[0].name);

          uploadBytes(storageRef, event.target.files[0])
            .then((snapshot) => {
              getDownloadURL(snapshot.ref)
                .then((url) => {
                  console.log("firebase url ", url);
                  setUrlFirebase(url);
                  setFile(event.target.files[0]);
                  setLoader(false);
                  console.log("file", event.target.files);
                })
                .catch((error) => {
                  console.log("erreur url", error);
                  setErreurUpload(true);
                  setLoader(false);
                });
            })
            .catch((error) => {
              console.log("erreur firebase", error);
              setErreurUpload(true);
              setLoader(false);
            });

        }
        catch (e) {
          //console.log("Erreur lors de l'upload de votre document.",e)
        }
      }
      handleUpload();
      console.log("file", file)

    }
  }
  //upload du doc dans airtable
  useEffect(() => {
    if (file) {
      const URLUploadDocAirtable = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document/${documentData.id_document}`;
      fetch(
        URLUploadDocAirtable,
        {
          method: "PATCH",
          headers: {
            Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
            "Accept": "application/json",
            'content-type': "application/json"
          },
          body: JSON.stringify({
            "fields": {
              "etat": "ajouté",
              "document": [{
                "url": urlFirebase,
                "filename": file.name,
              }],
            }
          })
        })
        .then((res) => res.json())
        .then((res) => console.log("res airtable", res))
        .catch((error) => console.log(error));

    }
  }, [file]);

  //DELETE DOC
  const handleDeleteDocument = (event) => {

    const urlDocDelete = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document/${documentData.id_document}`;
    fetch(
      urlDocDelete,
      {
        method: "PATCH",
        headers: {
          Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
          "Accept": "application/json",
          'content-type': "application/json"
        },
        body: JSON.stringify({
          "fields": {
            "etat": "non ajouté",
            "document": [{
              "url": "",
              "filename": "",
            }],
          }
        })
      })
      .then((res) => res.json())
      .then((res) => console.log("res airtable", res))
      .then((res) => closeDelete())
      .catch((error) => console.log(error));
    console.log("Document supprimé avec succès !");

  };

  const firebaseConfig = {
    apiKey: "AIzaSyBx-T0tuEcSlSFsPF1zyeYQo3pZG-zytz8",
    authDomain: "clotere-33ee1.firebaseapp.com",
    projectId: "clotere-33ee1",
    storageBucket: "clotere-33ee1.appspot.com",
    messagingSenderId: "488961682641",
    appId: "1:488961682641:web:b89810aa37cda07d7169f0",
  };

  var appFirebase = null;

  useEffect(() => {
    const fetchData = async () => {
      // eslint-disable-next-line
      appFirebase = initializeApp(firebaseConfig);
      setStorage(getStorage(appFirebase));
    };

    fetchData();
  }, []);

  console.log("args doc", args);

  return (
    <>

      <div className="container">
        <div>
          <p className="text-3xl">Documents</p>
        </div>

        {/**parent 1 */}
        <div className="mt-3">
          <span className="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-base font-bold text-purple-700 mb-2">
            Documents parent 1
          </span>
          <div className="overflow-hidden rounded-lg bg-white shadow p-3">

            <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
              {args.document.filter(doc => doc.nom.toLowerCase().includes("parent 1")).map(doc => (
                <li className="flex flex-col gap-3 justify-start items-start sm:flex-row sm:items-center sm:justify-between gap-x-6 py-3 text-left">
                  <div className="min-w-0">
                    <div className="flex items-center gap-x-3">
                      <DocumentIcon className="size-6 text-slate-200 inline-flex" />
                      <div className="flex gap-0 flex-col">
                        <p className="text-sm/6 font-semibold text-gray-900 m-0">{doc.nom}</p>
                        <p className="text-sm font-normal text-gray-600 m-0">{doc.description}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    {doc.etat === "ajouté" &&
                      <>
                        <a
                          type="button"
                          className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            openModal(doc.document[0].url);
                          }}
                        >
                          Voir
                        </a>
                        {/* Affichage de la modal viewer document */}
                        {isModalOpen && (
                          <DocumentModal 
                            documentUrl={currentDocumentUrl} 
                            onClose={() => setIsModalOpen(false)} 
                          />
                        )}
                        <button
                          type="button"
                          className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                          onClick={() => openDelete(doc.id, doc.nom)}
                        >
                          Supprimer
                        </button>
                      </>
                    }
                    {doc.etat === "non ajouté" &&
                      <>
                        <button
                          type="button"
                          className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                          onClick={() => openDialog(doc.id, doc.nom)}
                        >
                          + Ajouter
                        </button>
                      </>
                    }
                    {doc.etat === "anomalie" &&
                      <>
                        <div className="inline-flex"><ExclamationCircleIcon className="size-6 mr-2 text-orange-700" /><p className="m-0">Document non conforme, à modifier.</p> </div>
                        <button
                          type="button"
                          className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                          onClick={() => openDialog(doc.id, doc.nom)}
                        >
                          + Ajouter
                        </button>
                      </>
                    }


                  </div>

                </li>
              ))}
              {args.document.filter(doc => doc.nom.toLowerCase().includes("parent 1")).length === 0 && (
                <li className="text-gray-400 italic">Aucun document</li>
              )}
            </ul>
          </div>
        </div>

        {/** parent 2 */}
        <div className="mt-8">
          <span className="inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-base font-bold text-indigo-700 mb-2">
            Documents parent 2
          </span>
          <div className="overflow-hidden rounded-lg bg-white shadow p-3">

            <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
              {args.document.filter(doc => doc.nom.toLowerCase().includes("parent 2")).map(doc => (
                <li className="flex flex-col gap-3 justify-start items-start sm:flex-row sm:items-center sm:justify-between gap-x-6 py-3 text-left">
                  <div className="min-w-0">
                    <div className="flex items-center gap-x-3">
                      <DocumentIcon className="size-6 text-slate-200 inline-flex" />
                      <div className="flex gap-0 flex-col">
                        <p className="text-sm/6 font-semibold text-gray-900 m-0">{doc.nom}</p>
                        <p className="text-sm font-normal text-gray-600 m-0">{doc.description}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    {doc.etat === "ajouté" &&
                      <>
                        <a
                          type="button"
                          className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                          href={doc.document[0].url}
                          target="blank"
                        >
                          Voir
                        </a>
                        <button
                          type="button"
                          className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                          onClick={() => openDelete(doc.id, doc.nom)}
                        >
                          Supprimer
                        </button>
                      </>
                    }
                    {doc.etat === "non ajouté" &&
                      <>
                        <button
                          type="button"
                          className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                          onClick={() => openDialog(doc.id, doc.nom)}
                        >
                          + Ajouter
                        </button>
                      </>
                    }
                    {doc.etat === "anomalie" &&
                      <>
                        <div className="inline-flex"><ExclamationCircleIcon className="size-6 mr-2 text-orange-700" /><p className="m-0">Document non conforme, à modifier.</p> </div>
                        <button
                          type="button"
                          className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                          onClick={() => openDialog(doc.id, doc.nom)}
                        >
                          + Ajouter
                        </button>
                      </>
                    }


                  </div>

                </li>
              ))}
              {args.document.filter(doc => doc.nom.toLowerCase().includes("parent 1")).length === 0 && (
                <li className="text-gray-400 italic">Aucun document</li>
              )}
            </ul>
          </div>
        </div>

        {/**commun ou autre */}
        <div className="mt-8">
          <span className="inline-flex items-center rounded-md bg-orange-100 px-2 py-1 text-base font-bold text-orange-700 mb-2">
            Autre(s) document(s)
          </span>
          <div className="overflow-hidden rounded-lg bg-white shadow p-3">

            <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
              {args.document.filter(doc =>
                !doc.nom.toLowerCase().includes("parent 1") &&
                !doc.nom.toLowerCase().includes("parent 2")
              )
                .map(doc => (
                  <li className="flex flex-col gap-3 justify-start items-start sm:flex-row sm:items-center sm:justify-between gap-x-6 py-3 text-left">
                    <div className="min-w-0">
                      <div className="flex items-center gap-x-3">
                        <DocumentIcon className="size-6 text-slate-200 inline-flex" />
                        <div className="flex gap-0 flex-col">
                          <p className="text-sm/6 font-semibold text-gray-900 m-0">{doc.nom}</p>
                          <p className="text-sm font-normal text-gray-600 m-0">{doc.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                      {doc.etat === "ajouté" &&
                        <>
                          <a
                            type="button"
                            className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                            href={doc.document[0].url}
                            target="blank"
                          >
                            Voir
                          </a>
                          <button
                            type="button"
                            className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                            onClick={() => openDelete(doc.id, doc.nom)}
                          >
                            Supprimer
                          </button>
                        </>
                      }
                      {doc.etat === "non ajouté" &&
                        <>
                          <button
                            type="button"
                            className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                            onClick={() => openDialog(doc.id, doc.nom)}
                          >
                            + Ajouter
                          </button>
                        </>
                      }
                      {doc.etat === "anomalie" &&
                        <>
                          <div className="inline-flex"><ExclamationCircleIcon className="size-6 mr-2 text-orange-700" /><p className="m-0">Document non conforme, à modifier.</p> </div>
                          <button
                            type="button"
                            className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                            onClick={() => openDialog(doc.id, doc.nom)}
                          >
                            + Ajouter
                          </button>
                        </>
                      }


                    </div>

                  </li>
                ))}
              {args.document.filter(doc => doc.nom.toLowerCase().includes("parent 1")).length === 0 && (
                <li className="text-gray-400 italic">Aucun document</li>
              )}
            </ul>
          </div>
        </div>

        {/* Dialog */}
        <Dialog open={openUpload} onClose={setOpenUpload} className="relative z-10">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500/75 transition-opacity duration-500 ease-in-out data-closed:opacity-0"
          />
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <DialogPanel
                  transition
                  className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-closed:translate-x-full sm:duration-700"
                >
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <DialogTitle className="text-lg font-semibold text-gray-900">Ajoutez votre document</DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            onClick={() => setOpenUpload(false)}
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon aria-hidden="true" className="size-6" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white">
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/** content */}
                      <div className="flex flex-col gap-0 text-base mb-8">
                      <span className="text-slate-600">Document demandé</span>
                      <span className="font-bold">{documentData.name_document}</span>
                      </div>
                      
                      <label class="block my-2">
                        <span class="sr-only">Ajouter votre fichier</span>
                        <input onChange={onUploadDoc} accept="pdf" type="file" class="input-upload" />
                        {loader === true && <><Loader /></>}
                        {erreurUpload === true && <><Erreur /></>}
                        {file && <><p className="text-lg flex justify-center mb-0 items-center mt-3"><CheckCircleIcon aria-hidden="true" className="size-6 text-emerald-600" />Document ajouté</p> </>}
                      </label>
                      <DescriptionDocument doc={documentData.name_document}/>
                    </div>
                    </div>
                    <div className="flex shrink-0 justify-start px-4 py-4">
                      {file !== null && <>
                        <button
                          onClick={closeDialog}
                          className="px-4 py-2 text-white bg-slate-500 rounded hover:bg-red-600"
                        >
                          Fermer
                        </button>
                      </>}
                      
                      {file === null && <>
                        <button
                          onClick={() => setOpenUpload(false)}
                          className="px-4 py-2 text-white bg-slate-500 rounded hover:bg-red-600"
                        >
                          Fermer
                        </button>
                      </>}
                    </div>
                    
                  </div>
                </DialogPanel>
              </div>
            </div>
          </div>
        </Dialog>



        {/* Dialog delete*/}
        <Transition appear show={isOpenDelete} as={Fragment} >
          <Dialog as="div" className="relative z-10" onClose={closeDelete}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-50" />
            </Transition.Child>

            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md bg-white rounded-lg p-6 shadow-lg">
                  <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                    Êtes-vous sûre de vouloir supprimer ce document ?
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Cette action est irréversible, le document ne pourra pas être récupéré par la suite.
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      onClick={() => handleDeleteDocument()}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 sm:mt-0 sm:w-auto"
                    >
                      Supprimer le document
                    </button>
                    <button
                      type="button"
                      data-autofocus
                      onClick={() => setIsOpenDelete(false)}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Annuler
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>

    </>
  );

}


export default Documents;
