import React from "react";
import CecileChelimClotere from "../img/cecile-chelim-clotere.png";
import Logo from "../img/logo-clotere.svg";

function QuiSommesNous() {
    return (
        <section className="mt-20 mb-20">
            <div className="container">
                <div class="container mx-auto px-[15px] h-full">
                    <div class="flex flex-row justify-between items-center flex-wrap">
                        <div className="bg-white py-14 flex-1 sm:flex hidden">
                            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                <div className="mx-auto max-w-2xl lg:mx-0">
                                    <p className="text-lg font-semibold text-indigo-600">Pourquoi Clotere ?</p>
                                    <p className="mt-2 text-5xl font-semibold tracking-tight text-gray-900 ">
                                        Comme vous nous sommes passés chez le notaire.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="pt-8 sm:inline-block sm:w-full sm:px-4 flex-1">
                        <p className="text-base/7 font-semibold text-indigo-600 sm:hidden">Pourquoi Clotere ?</p>
                            <figure className="rounded-2xl bg-white ring-1 shadow-lg ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
                                <blockquote className="px-6 py-3 m-0 text-lg font-semibold tracking-tight text-gray-900 sm:p-12 sm:text-xl/8">
                                    <p className="m-0">
                                        J'ai passé beaucoup trop de temps à envoyer des documents en pièce jointe de mail et à jongler entre les appels pour trouver un créneau avec mon notaire. Il me semblait impensable de ne pas trouver une solution sur le marché qui simplifie tout ça.
                                    </p>
                                </blockquote>
                                <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
                                    <img
                                        alt=""
                                        src={CecileChelimClotere}
                                        className="size-16 flex-none rounded-full bg-gray-50"
                                    />
                                    <div className="flex-auto">
                                        <div className="font-semibold">Cécile Chelim </div>
                                        <div className="text-gray-600">Fondatrice de Clotere</div>
                                    </div>
                                    <img alt="" src={Logo} className="h-8 w-auto flex-none" />
                                </figcaption>
                            </figure>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    );
}

export default QuiSommesNous;
